import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RegistrationForm from 'components/authentication/ownersbackend/RegistrationForm';
import { useTranslation } from 'react-i18next';

const Registration = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="align-items-center mb-2">
        <Col>
          <h5 id="modalLabel">
            {t('common:capitalize', { value: t('auth:register') })}
          </h5>
        </Col>
        <Col xs="auto">
          <p className="fs--1 text-600 mb-0">
            {t('auth:haveAnAccount')}?{' '}
            <Link to="/auth/login">
              {t('common:capitalize', { value: t('auth:login') })}
            </Link>
          </p>
        </Col>
      </Row>
      <RegistrationForm hasLabel={true} />
    </>
  );
};

export default Registration;
