import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import Loader from '../../common/Loader';
import { setRegistration, settingsActions } from '../../../redux/actions';
import { connect } from 'react-redux';

const RegistrationForm = ({ hasLabel, setRegistration }) => {
  // State
  const [formData, setFormData] = useState({
    email: ''
  });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    setIsLoading(true);
    axios({
      url: '/auth/preregister',
      method: 'POST',
      data: formData
    })
      .then(function (res) {
        const result = res.data.result;
        setRegistration({
          email: formData.email,
          token: result.verifaction_token
        });
        navigate('/auth/registration-mail');
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('common:email')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t('common:email') : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
            <Trans i18nKey="auth:termsAndPrivacy">
              <Link to="#!">terms</Link>
              <Link to="#!">privacy policy</Link>
            </Trans>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={!formData.email || !formData.isAccepted}
        >
          {t('auth:register')}
        </Button>
      </Form.Group>
    </Form>
  );
};

RegistrationForm.propTypes = {
  setRegistration: PropTypes.func,
  hasLabel: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setRegistration: content => dispatch(setRegistration(content))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
