import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router-dom';
import ErrorLayout from '../../layouts/ErrorLayout';
import Error404 from '../../components/errors/Error404';
import Error500 from '../../components/errors/Error500';
import MainLayout from '../../layouts/MainLayout';
import Dashboard from '../../components/dashboards/default';
import Settings from '../../components/settings/Settings';
import { DynamicRoutes } from '../../modules';
import Activate from '../../components/activate/Activate';
import EmptyLayout from '../../layouts/EmptyLayout';
import AuthSimpleLayout from '../../layouts/AuthSimpleLayout';
import Logout from '../../components/authentication/ownersbackend/simple/Logout';
import LockScreen from '../../components/authentication/ownersbackend/simple/LockScreen';
import Analytics from '../../components/dashboards/analytics';
import Crm from '../../components/dashboards/crm';
import Saas from '../../components/dashboards/saas';
import Ecommerce from '../../components/dashboards/e-commerce';
import Launcher from '../../components/authentication/app/simple/Launcher';
import CreateOrganization from '../../modules/Organisation/components/auth/CreateOrganization';
import { connect } from 'react-redux';

const ProtectedRoutes = ({ onDynamicRoutesLoaded, organization }) => {
  const [mainDynamicRoutes, setMainDynamicRoutes] = useState(false);
  const [emptyDynamicRoutes, setEmptyDynamicRoutes] = useState(false);

  const fetchRoutes = async () => {
    const mainDynamicRoutes = await DynamicRoutes('main');
    const emptyDynamicRoutes = await DynamicRoutes('empty');
    setMainDynamicRoutes(mainDynamicRoutes);
    setEmptyDynamicRoutes(emptyDynamicRoutes);
    onDynamicRoutesLoaded(true);
  };

  useEffect(() => {
    fetchRoutes();
  }, [organization]);

  return mainDynamicRoutes ? (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path={`errors/404`} exact element={<Error404 />} />
        <Route path={`errors/500`} element={<Error500 />} />
      </Route>
      <Route element={<AuthSimpleLayout />}>
        <Route
          path={`/auth/organization/create`}
          exact
          element={<CreateOrganization />}
        />
        <Route path={`/auth/launcher`} exact element={<Launcher />} />
        <Route path={`auth/logout`} exact element={<Logout />} />
        <Route path={`auth/lock-screen`} exact element={<LockScreen />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/" exact element={<Dashboard />} />
        <Route path="dashboard/analytics" element={<Analytics />} />
        <Route path="dashboard/crm" element={<Crm />} />
        <Route path="dashboard/saas" element={<Saas />} />
        <Route path="dashboard/e-commerce" element={<Ecommerce />} />
        <Route path="/settings" exact element={<Settings />} />
        {mainDynamicRoutes.length > 0 && mainDynamicRoutes}
      </Route>
      <Route path="/activate/:activateRoute" element={<Activate />} />
      {emptyDynamicRoutes && emptyDynamicRoutes.length > 0 && (
        <Route element={<EmptyLayout />}>{emptyDynamicRoutes}</Route>
      )}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  ) : (
    false
  );
};

ProtectedRoutes.propTypes = {
  onDynamicRoutesLoaded: PropTypes.func,
  organization: PropTypes.object
};

ProtectedRoutes.defaultProps = {
  onDynamicRoutesLoaded: () => {}
};

const mapStateToProps = (state, ownProps) => {
  return {
    organization: state.organization,
    ...ownProps
  };
};

export default connect(mapStateToProps, null)(ProtectedRoutes);
