import React, { useContext, useEffect, useState } from 'react';
import is from 'is_js';

import AppContext from 'context/Context';
import PublicRoutes from '../routes/ownersbackend/PublicRoutes';
import { connect } from 'react-redux';
import ProtectedRoutes from '../routes/ownersbackend/ProtectedRoutes';
import PropTypes from 'prop-types';

const OwnersBackendLayout = props => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);
  const [auth, setAuth] = useState(props.isAuthenticated);
  const [dynamicRoutesLoaded, setDynamicRoutesLoaded] = useState(false);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  useEffect(() => {
    setAuth(props.isAuthenticated);
  }, [props.authentication]);

  return auth ? <ProtectedRoutes /> : <PublicRoutes />;
};

OwnersBackendLayout.propTypes = {
  authentication: PropTypes.object,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    authentication: state.auth,
    isAuthenticated: state.auth.isAuthenticated,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLogout: () => dispatch(setLogout()),
    setLogin: content => dispatch(setLogin(content))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnersBackendLayout);
