const manufacturer = [
  { name: 'Abarth' },
  { name: 'Alfa Romeo' },
  { name: 'Aston Martin' },
  { name: 'Audi' },
  { name: 'Bentley' },
  { name: 'BMW' },
  { name: 'Bugatti' },
  { name: 'Cadillac' },
  { name: 'Chevrolet' },
  { name: 'Chrysler' },
  { name: 'Citroën' },
  { name: 'Dacia' },
  { name: 'Daewoo' },
  { name: 'Daihatsu' },
  { name: 'Dodge' },
  { name: 'Donkervoort' },
  { name: 'DS' },
  { name: 'Ferrari' },
  { name: 'Fiat' },
  { name: 'Fisker' },
  { name: 'Ford' },
  { name: 'Honda' },
  { name: 'Hummer' },
  { name: 'Hyundai' },
  { name: 'Infiniti' },
  { name: 'Iveco' },
  { name: 'Jaguar' },
  { name: 'Jeep' },
  { name: 'Kia' },
  { name: 'KTM' },
  { name: 'Lada' },
  { name: 'Lamborghini' },
  { name: 'Lancia' },
  { name: 'Land Rover' },
  { name: 'Landwind' },
  { name: 'Lexus' },
  { name: 'Lotus' },
  { name: 'Maserati' },
  { name: 'Maybach' },
  { name: 'Mazda' },
  { name: 'McLaren' },
  { name: 'Mercedes-Benz' },
  { name: 'MG' },
  { name: 'Mini' },
  { name: 'Mitsubishi' },
  { name: 'Morgan' },
  { name: 'Nissan' },
  { name: 'Opel' },
  { name: 'Peugeot' },
  { name: 'Porsche' },
  { name: 'Renault' },
  { name: 'Rolls-Royce' },
  { name: 'Rover' },
  { name: 'Saab' },
  { name: 'Seat' },
  { name: 'Skoda' },
  { name: 'Smart' },
  { name: 'SsangYong' },
  { name: 'Subaru' },
  { name: 'Suzuki' },
  { name: 'Tesla' },
  { name: 'Toyota' },
  { name: 'Volkswagen' },
  { name: 'Volvo' }
];

export const getManufacturer = (value, type) => {
  let val = false;
  manufacturer.map(obj => {
    if (obj[type] === value) {
      val = obj;
      return obj;
    }
  });
  return val;
};

export default manufacturer;
