import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import dayjs from 'dayjs';

const numberFormatCache = new Map();
const DATE_FORMAT = 'Pp';
const DATETIME_FORMAT = 'Pp';
const FALLBACK_LANGUAGE = 'en-US';
const FALLBACK_LOCALE = 'en-US';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    ns: [
      'common',
      'navbar',
      'auth',
      'settings',
      'country',
      'contact',
      'donation',
      'prayertime'
    ],
    defaultNS: 'common',
    useSuspense: false,
    fallbackLng: FALLBACK_LANGUAGE,
    //debug: process.env.NODE_ENV === 'development',
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      format: (value, rawFormat, lng) => {
        const [type, ...additionalValues] = rawFormat
          .split(',')
          .map(v => v.trim());

        switch (type) {
          case 'capitalize':
            return value.charAt(0).toUpperCase() + value.slice(1);
          case 'date': {
            return dayjs(value).locale(lng).format('L');
          }
          case 'datetime': {
            // Specify the input format using the `dayjs(value, format)` method
            const format = 'YYYY-MM-DD HH:mm:ss';
            const localDate = dayjs(value, format).utc(true);

            // Format the local date as a string
            const localDateString = localDate.format('L LT');
            return localDateString;
            //return dayjs(value).locale(lng).format('L LT');
          }
          case 'uppercase':
            return value.toUpperCase();
          case 'price': {
            let currency = additionalValues[0];

            let numberFormat = numberFormatCache.get(lng + currency);
            if (!numberFormat) {
              numberFormat = Intl.NumberFormat(lng, {
                style: 'currency',
                currency,
                format: 'decimal',
                precision: '2'
              });
              numberFormatCache.set(lng + currency, numberFormat);
            }

            return numberFormat.format(value);
          }
          case 'pricewithoutsign': {
            let currency = additionalValues[0];
            let numberFormat = numberFormatCache.get(lng + currency);

            if (typeof value === 'object') {
              const numberOfIntegerDigits = value.numberOfIntegerDigits;
              const numberOfDecimalDigits = value.numberOfDecimalDigits;
              value = value.number;

              const adjustedDecimalDigits = Math.min(
                Math.max(2, numberOfDecimalDigits),
                6
              );

              numberFormat = new Intl.NumberFormat(lng, {
                style: 'currency',
                currency,
                currencyDisplay: 'code',
                precision: adjustedDecimalDigits,
                minimumFractionDigits: adjustedDecimalDigits,
                maximumFractionDigits: adjustedDecimalDigits
              });

              numberFormatCache.set(lng + currency, numberFormat);
            } else {
              value = value
                .toString()
                .replace(',', '.')
                .replace(/,/g, '.')
                .replace(/[.](?!\d*$)/g, '');

              if (!numberFormat) {
                numberFormat = Intl.NumberFormat(lng, {
                  style: 'currency',
                  currency,
                  currencyDisplay: 'code',
                  format: 'decimal',
                  precision: '2'
                });
                numberFormatCache.set(lng + currency, numberFormat);
              }
            }

            return numberFormat.format(value).replace(currency, '').trim();
          }
        }
      }
    }
    //saveMissing: process.env.NODE_ENV === 'development'
  });

export default i18n;
