import React from 'react';
import PropTypes from 'prop-types';
import RegistrationMailContent from 'components/authentication/ownersbackend/RegistrationMailContent';
import { connect } from 'react-redux';

const RegistrationMail = ({ registration }) => (
  <div className="text-center">
    <RegistrationMailContent email={registration.email} />
  </div>
);

RegistrationMail.propTypes = {
  registration: PropTypes.shape({
    email: PropTypes.string
  }).isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    registration: state.registration,
    ...ownProps
  };
};

export default connect(mapStateToProps, null)(RegistrationMail);
