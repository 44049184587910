import React from 'react';
import Organizations from './components/Organizations';
import Organization from './components/Organization';
import Create from './components/Create';

export default {
  name: 'Organizations',
  label: 'Organizations',
  labelDisable: true,
  children: [
    {
      name: 'Organizations',
      active: true,
      icon: 'globe',
      to: '/organization'
    }
  ],
  routes: [
    {
      path: '/organization',
      name: 'Organizations',
      component: <Organizations />,
      layout: 'main'
    },
    {
      path: '/organization/create',
      name: 'Organizations Create',
      component: <Create />,
      layout: 'main'
    },
    {
      path: '/organization/:id',
      name: 'Organizations Details',
      component: <Organization />,
      layout: 'main'
    }
  ]
};
