import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import APIResponseViewer from '../../../../helpers/APIResponseViewer';

const ActionButtons = ({ onSave }) => {
  const { t } = useTranslation();

  return (
    <Button variant="primary" className="me-2 mb-1" onClick={onSave}>
      {t('save')}
    </Button>
  );
};

ActionButtons.propTypes = {
  onSave: PropTypes.func
};

ActionButtons.defaultProps = {
  onSave: () => {}
};

export default ActionButtons;
