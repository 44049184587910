import React from 'react';
import Overview from './components/Overview';
import User from './components/User';
import Setting from './components/Setting';

export default {
  name: 'Users',
  label: 'Users',
  labelDisable: true,
  settings: [
    {
      id: 'user',
      name: 'user',
      component: <Setting />
    }
  ],
  children: [
    {
      name: 'Users',
      active: true,
      hide: true,
      icon: 'user',
      to: '/users'
    }
  ],
  routes: [
    {
      path: '/users',
      name: 'Users',
      component: <Overview />,
      layout: 'main'
    },
    {
      path: '/user/:id',
      name: 'User Details',
      component: <User />,
      layout: 'main'
    } /*,
    {
      path: '/user/profile',
      name: 'User Self Details',
      component: <User />,
      layout: 'main'
    }*/
  ]
};
