export const timezones = [
  {
    name: 'Europe/London',
    utc_offset: 0
  },
  {
    name: 'Europe/Berlin',
    utc_offset: 2
  },
  {
    name: 'Europe/Paris',
    utc_offset: 2
  },
  {
    name: 'Europe/Rome',
    utc_offset: 2
  },
  {
    name: 'Europe/Moscow',
    utc_offset: 3
  },
  {
    name: 'Europe/Istanbul',
    utc_offset: 3
  },
  {
    name: 'Europe/Athens',
    utc_offset: 3
  },
  {
    name: 'Europe/Zurich',
    utc_offset: 2
  },
  {
    name: 'America/New_York',
    utc_offset: -4
  },
  {
    name: 'America/Los_Angeles',
    utc_offset: -7
  },
  {
    name: 'America/Chicago',
    utc_offset: -5
  },
  {
    name: 'America/Toronto',
    utc_offset: -4
  },
  {
    name: 'America/Sao_Paulo',
    utc_offset: -3
  },
  {
    name: 'Asia/Tokyo',
    utc_offset: 9
  },
  {
    name: 'Asia/Dubai',
    utc_offset: 4
  },
  {
    name: 'Asia/Shanghai',
    utc_offset: 8
  },
  {
    name: 'Asia/Kolkata',
    utc_offset: 5.5
  },
  {
    name: 'Australia/Sydney',
    utc_offset: 10
  },
  {
    name: 'Australia/Melbourne',
    utc_offset: 10
  },
  {
    name: 'Pacific/Auckland',
    utc_offset: 12
  },
  {
    name: 'Africa/Cairo',
    utc_offset: 2
  },
  {
    name: 'Africa/Johannesburg',
    utc_offset: 2
  },
  {
    name: 'America/Mexico_City',
    utc_offset: -5
  },
  {
    name: 'America/Buenos_Aires',
    utc_offset: -3
  },
  {
    name: 'Asia/Singapore',
    utc_offset: 8
  },
  {
    name: 'Asia/Hong_Kong',
    utc_offset: 8
  },
  {
    name: 'Asia/Seoul',
    utc_offset: 9
  },
  {
    name: 'Asia/Riyadh',
    utc_offset: 3
  },
  {
    name: 'Asia/Jerusalem',
    utc_offset: 3
  },
  {
    name: 'Pacific/Honolulu',
    utc_offset: -10
  },
  {
    name: 'Pacific/Samoa',
    utc_offset: -11
  },
  {
    name: 'Pacific/Fiji',
    utc_offset: 12
  },
  {
    name: 'Pacific/Tahiti',
    utc_offset: -10
  },
  {
    name: 'Pacific/Chatham',
    utc_offset: 13.75
  },
  {
    name: 'Pacific/Niue',
    utc_offset: -11
  }
];
