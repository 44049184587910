import { AuthWizardContext } from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import LottiePlayer from '../../../../common/LottiePlayer';
import axios from 'axios';
import logoloader from '../../../../../assets/img/animated-icons/logo.json';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../common/Loader';

const Success = ({ reset }) => {
  const { t } = useTranslation();
  const { user, setStep, setUser } = useContext(AuthWizardContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const updateUser = () => {
    setIsLoading(true);
    axios({
      url: '/auth/register',
      method: 'PUT',
      data: { ...user, token }
    })
      .then(function (res) {
        const result = res.data.result;
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === 400) {
          navigate('/');
        }
      });
  };

  useEffect(() => {
    console.log(user);
    updateUser();
  }, [user]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <LottiePlayer
                isCentered
                play
                animationData={celebration}
                loop={true}
              />
            </div>
          </div>
          <h4 className="mb-1">Your account is all set!</h4>
          <p className="fs-0">Now you can access to your account</p>
          <Button as={Link} color="primary" className="mt-3" to={`/auth/login`}>
            {t('Return to login')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
