import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PasswordResetForm = ({ hasLabel, user, token }) => {
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const navigate = useNavigate();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    if (user) {
      const { id } = user;

      axios({
        url: `/user/${id}/resetpassword`,
        method: 'put',
        data: { ...formData, token }
      })
        .then(res => {
          navigate('/');
        })
        .catch(error => {
          if (error.response.status !== 422) navigate('/');
        });
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={
          !formData.password ||
          !formData.confirmPassword ||
          formData.password !== formData.confirmPassword
        }
      >
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  user: PropTypes.object,
  token: PropTypes.string,
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
