import React from 'react';
import { Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SwitchFormGroup = props => {
  const {
    col,
    name,
    disabled = false,
    label,
    required,
    checked,
    handleChange
  } = props;

  return (
    <Form.Group as={Col} {...col} controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Check
        type="switch"
        name={name}
        disabled={disabled}
        required={required}
        id={name}
        checked={checked}
        onChange={handleChange}
      />
    </Form.Group>
  );
};

SwitchFormGroup.propTypes = {
  col: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};

export default SwitchFormGroup;
