import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorLayout from '../../layouts/ErrorLayout';
import Error404 from '../../components/errors/Error404';
import Error500 from '../../components/errors/Error500';
import AuthSimpleLayout from '../../layouts/AuthSimpleLayout';
import SimpleLogin from '../../components/authentication/ownersbackend/simple/Login';
import SimpleForgetPassword from '../../components/authentication/ownersbackend/simple/ForgetPassword';
import SimplePasswordReset from '../../components/authentication/ownersbackend/simple/PasswordReset';
import Registration from '../../components/authentication/ownersbackend/simple/Registration';
import RegistrationMail from '../../components/authentication/ownersbackend/simple/RegistrationMail';
import RegistrationWizard from '../../components/authentication/ownersbackend/simple/RegistrationWizard';
import AuthEmptyLayout from '../../layouts/AuthEmptyLayout';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path={`errors/404`} exact element={<Error404 />} />
        <Route path={`errors/500`} element={<Error500 />} />
      </Route>

      <Route element={<AuthSimpleLayout />}>
        <Route path={`/auth/login`} element={<SimpleLogin />} />
        <Route path={`/auth/register`} element={<Registration />} />
        <Route
          path={`/auth/registration-mail`}
          element={<RegistrationMail />}
        />
        <Route
          path={`/auth/forgot-password`}
          exact
          element={<SimpleForgetPassword />}
        />
        <Route
          path={`/auth/reset-password/:token`}
          exact
          element={<SimplePasswordReset />}
        />
      </Route>

      <Route element={<AuthEmptyLayout />}>
        <Route
          path={`/auth/register/:token`}
          element={<RegistrationWizard />}
        />
      </Route>
      {/*
        <Route path="/activate/:activateRoute" element={<Activate />} />
*/}
      <Route path="*" element={<Navigate to="/auth/login" replace />} />
    </Routes>
  );
};

export default PublicRoutes;
