import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import PropTypes from 'prop-types';

const ExtensionsSelection = ({ onSelect }) => {
  const [data, setData] = useState(null);
  const [loading, setDataLoading] = useState(null);
  const [value, setValue] = useState(null);

  const fetchExtensions = async () => {
    try {
      const response = await axios.get('/ms/extension');
      if (response.data.result) {
        return response.data.result
          .filter(extension => extension.active) // Filter only active extensions
          .map(ext => ({ value: ext.id, label: ext.name }));
      }
      return [];
    } catch (error) {
      console.error('Failed to fetch extensions:', error);
      // Handle the error appropriately
      return [];
    }
  };

  const getRequest = async () => {
    setDataLoading(true);
    const extensions = await fetchExtensions();
    setData(extensions);
    setDataLoading(false);
  };

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    onSelect(value?.value);
  }, [value]);

  return (
    !loading &&
    data && (
      <Select
        closeMenuOnSelect={true}
        options={data}
        placeholder="Select..."
        classNamePrefix="react-select"
        value={value}
        onChange={value => setValue(value)}
      />
    )
  );
};

ExtensionsSelection.propTypes = {
  onSelect: PropTypes.func
};

ExtensionsSelection.defaultProps = {
  onSelect: () => {}
};
export default ExtensionsSelection;
