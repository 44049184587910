import React, { useContext, useEffect, Fragment, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes from 'routes/routes';
import { capitalize } from 'helpers/utils';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import { Modules } from '../../../modules';
import Loader from '../../common/Loader';
import Logo from '../../common/Logo';
import { useTranslation } from 'react-i18next';
import { action, setLogout, setSpinner } from '../../../redux/actions';
import { connect } from 'react-redux';

const NavbarVertical = () => {
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);
  const [mergedRoutes, setMergedRoutes] = useState(false);
  const { t } = useTranslation();

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  const loadModules = async () => {
    const modulesRoutes = await Modules();
    setMergedRoutes([...routes, ...modulesRoutes]);
  };

  useEffect(() => {
    loadModules();
  }, []);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {t(label)}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={127} variant={'nextTo'} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="navbar-vertical-content scrollbar">
          {mergedRoutes &&
          typeof mergedRoutes === 'object' &&
          Object.keys(mergedRoutes).length > 0 ? (
            <>
              <Nav className="flex-column" as="ul">
                {mergedRoutes.map(route => (
                  <Fragment key={route.label}>
                    {!route.labelDisable && (
                      <NavbarLabel label={capitalize(route.label)} />
                    )}
                    <NavbarVerticalMenu routes={route.children} />
                  </Fragment>
                ))}
              </Nav>

              <>
                {navbarPosition === 'combo' && (
                  <div className={`d-${topNavbarBreakpoint}-none`}>
                    <div className="navbar-vertical-divider">
                      <hr className="navbar-vertical-hr my-2" />
                    </div>
                    <Nav navbar>
                      <NavbarTopDropDownMenus />
                    </Nav>
                  </div>
                )}
              </>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
