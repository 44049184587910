import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import loaderV5 from 'assets/img/animated-icons/loaderV5.json';
import PropTypes from 'prop-types';
import LottiePlayer from './LottiePlayer';

const Loader = ({ width, height }) => {
  return (
    <Row className="flex-center py-5">
      <Col xs="auto">
        <LottiePlayer
          play
          loop
          isCentered
          animationData={loaderV5}
          width={width}
          height={height}
        />
      </Col>
    </Row>
  );
};

Loader.propTypes = {
  ...Spinner.propTypes,
  width: PropTypes.string,
  height: PropTypes.string
};

Loader.defaultProps = {
  animation: 'border',
  size: 'lg',
  color: 'primary',
  width: '220px',
  height: '180px'
};

export default Loader;
