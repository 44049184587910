import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AuthWizardContext } from 'context/Context';
import { useParams } from 'react-router-dom';

const AuthWizardProvider = ({ userData, children }) => {
  const { token } = useParams();

  const [user, setUser] = useState(userData ?? {});
  const [step, setStep] = useState(1);

  const value = { user, validationToken: token, setUser, step, setStep };
  return (
    <AuthWizardContext.Provider value={value}>
      {children}
    </AuthWizardContext.Provider>
  );
};

AuthWizardProvider.propTypes = {
  userData: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default AuthWizardProvider;
