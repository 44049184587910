import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  action,
  setLogin,
  setLogout,
  setSpinner
} from '../../../redux/actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Badge, Col, ProgressBar, Row } from 'react-bootstrap';
import Flex from '../../common/Flex';
import IconButton from '../../common/IconButton';
import axios from 'axios';
import Modules from '../../../modules';

const LauncherContent = ({
  authentication,
  assignedOrganizations,
  setOrganization,
  setSpinner
}) => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!authentication.isAuthenticated) navigate(`/auth/login`);
  }, [authentication]);

  if (!assignedOrganizations) return null;

  const assignOrganizationIdToSession = organization_id => {
    return axios({
      url: `/session`, // Use organization_id instead of organizationId
      method: 'PUT',
      data: { organization_id }
    });
  };

  const onClick = async organization => {
    await setSpinner('mainloader', true);
    assignOrganizationIdToSession(organization.id)
      .then(async () => {
        await setOrganization('setOrganization', organization);
        await setOrganization('setOrganizationId', organization.id);

        setTimeout(() => {
          setSpinner('mainloader', false);
          navigate('/');
        }, 1000);
      })
      .catch(() => {
        setSpinner('mainloader', false);
      });
  };

  return assignedOrganizations.map((organization, index) => {
    const { id, name } = organization;
    const color = 'success';
    const isLast = index === assignedOrganizations.length - 1;
    return (
      <Row
        key={`organization-${index}`}
        className={classNames('align-items-center py-2', {
          'border-bottom border-200 ': !isLast
        })}
      >
        <Col className="py-1">
          <Flex className="align-items-center">
            <div className="avatar avatar-xl me-3">
              <div className={`avatar-name rounded-circle bg-soft-${color}`}>
                <span className={`fs-0 text-${color.split('-')[1] || color}`}>
                  {name[0]}
                </span>
              </div>
            </div>
            <Flex className="position-relative">
              <Flex tag="h6" align="center" className="mb-0">
                {name}
              </Flex>
            </Flex>
          </Flex>
        </Col>
        <Col>
          <Row className="justify-content-end align-items-center">
            <Col xs="auto pe-0">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="arrow-right"
                transform="shrink-3"
                className="me-2"
                tooltipText={'start'}
                tooltipPosition={'top'}
                onClick={() => onClick(organization)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  });
};

LauncherContent.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    authentication: state.auth,
    assignedOrganizations: state.organization.assignedOrganizations,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSpinner: (name, value) => dispatch(setSpinner(name, value)),
    setOrganization: (type, content) => dispatch(action(type, content)),
    setLogout: () => dispatch(setLogout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LauncherContent);
