import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import LogoAnimation from '../../../components/common/LogoAnimation';
import CountUp from 'react-countup';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Logo from '../../../components/common/Logo';
import { t } from 'i18next';

const Presentation = () => {
  const { organizationId, id } = useParams();
  const [dataLoading, setDataLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [currentAmountStart, setCurrentAmountStart] = useState(0);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [currentAmountPercentage, setCurrentAmountPercentage] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [organizationLogo, setOrganizationLogo] = useState({
    dark: null,
    light: null
  });

  const getData = async () => {
    try {
      await axios({
        url: `/ms/donation/pool/presentation/${organizationId}/${id}`,
        method: 'GET'
      })
        .then(function (res) {
          if (res.data.result) {
            const data = res.data.result;
            const donationpool = data.donationpool;
            const organization = data.organization;

            setOrganizationLogo(obj => {
              return {
                ...obj,
                light: organization.organizationLogoLight,
                dark: organization.organizationLogoDark
              };
            });

            setFormData(donationpool);

            /*const result = calculatePercentage(currentAmount, data.target_amount);
              setCurrentAmountPercentage(result.toFixed(0));*/
            currentAmountStart !== donationpool.totalTransactionAmount
              ? setCurrentAmount(donationpool.totalTransactionAmount)
              : null;
            setTransactions(donationpool.donationTransactions);
          }

          setDataLoading(false);
        })
        .catch(error => {
          setDataLoading(false);
        });
    } catch (error) {
      console.error(error);
      setDataLoading(false);
    }
    return null; // Return null if something goes wrong
  };

  const getNewTransactions = async () => {
    if (transactions.length > 0) {
      axios({
        url: `/ms/donation/pool/presentation/${organizationId}/${id}/${
          transactions[transactions.length - 1].id
        }`,
        method: 'GET'
      })
        .then(function (res) {
          if (res.data.result) {
            const data = res.data.result;

            currentAmountStart !== data.amount
              ? setCurrentAmount(data.amount)
              : null;
            data.data.length > 0
              ? setTransactions(prevTransactions => [
                  ...prevTransactions,
                  data.data
                ])
              : null;
          }

          setDataLoading(false);
        })
        .catch(error => {
          setDataLoading(false);
        });
    } else getData();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getNewTransactions();
    }, 20000); // 30000 Millisekunden entsprechen 30 Sekunden

    return () => clearInterval(interval);
  }, []);

  function calculatePercentage(part, whole) {
    return (part / whole) * 100;
  }

  return (
    <>
      <Row className={'justify-content-center'}>
        <Col xs={'auto'}>
          <Logo
            width={200}
            srcLight={organizationLogo.light}
            srcDark={organizationLogo.dark}
          />
        </Col>
      </Row>
      {dataLoading ? null : (
        <>
          <Row className="justify-content-center mb-2 ">
            <Col xs={'auto'}>
              <h1>{formData.name}</h1>
            </Col>
          </Row>
          <Card className="mb-3">
            <Card.Body>
              <Row className="justify-content-center mb-2 ">
                <Col xs={'auto'}>
                  <div
                    className="d-none d-sm-block display-1 fw-medium text-700"
                    style={{ fontSize: '14rem' }}
                  >
                    <CountUp
                      className={'text-success'}
                      start={currentAmountStart}
                      end={currentAmount}
                      decimal={','}
                      decimals={2}
                      duration={3.5}
                      prefix="€"
                      separator="."
                      onEnd={args => {
                        console.log(currentAmount);
                        setCurrentAmountStart(currentAmount);
                      }}
                    />
                  </div>
                  <div className="d-block d-sm-none display-1 fw-medium text-700">
                    <CountUp
                      className={'text-success'}
                      start={1}
                      end={currentAmount}
                      decimal={','}
                      decimals={2}
                      duration={3.5}
                      prefix="€"
                      separator="."
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/* <Row className="justify-content-center mb-2 mt-5">
            <Col xs={'5'}>
              <ProgressBar
                now={currentAmountPercentage}
                label={`${currentAmountPercentage}%`}
              />
            </Col>
          </Row>*/}
          <Row className={'mt-5 gap-3'}>
            <Col xs={{ order: 12 }}>
              <Card className="mb-3 h-100">
                <Card.Body>
                  <h1 className="display-4 mb-3 fw-light text-center text-700">
                    Transaktionen
                  </h1>
                  {transactions
                    .slice()
                    .reverse()
                    .map((transaction, index) => {
                      return index <= 2 ? (
                        <Card
                          bg={'soft-success'}
                          key={`card-${index}`}
                          className={'mb-3'}
                        >
                          <Card.Body>
                            <Row className={'justify-content-between'}>
                              <Col xs={'auto'} className={'text-truncate'}>
                                <h1 className={'display-5 '}>
                                  {transaction.anonymous
                                    ? 'Anonym'
                                    : transaction.firstname +
                                      ' ' +
                                      transaction.lastname}
                                </h1>
                              </Col>
                              <Col xs={'auto'}>
                                <h1 className={'display-5 text-right '}>
                                  {t('intlPrice', { val: transaction.amount })}
                                </h1>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      ) : null;
                    })}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={{ order: 'first' }} sm={{ order: 'last' }}>
              <Card>
                <Card.Body>
                  <div className={' text-700'}>
                    <h1 className="display-4 mb-3 fw-light text-center text-700">
                      Ziel
                    </h1>
                    <div
                      className="d-none d-md-block display-1 fw-light text-center "
                      style={{ fontSize: '10rem' }}
                    >
                      <CountUp
                        start={1}
                        end={formData.target_amount}
                        decimal={','}
                        decimals={2}
                        duration={3.5}
                        prefix="€"
                        separator="."
                      />
                    </div>
                    <div className="d-block d-md-none display-1 fw-light text-center">
                      <CountUp
                        start={1}
                        end={formData.target_amount}
                        decimal={','}
                        decimals={2}
                        duration={3.5}
                        prefix="€"
                        separator="."
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className={'justify-content-center'}>
            <Col xs={'auto'}>
              <LogoAnimation
                style={{
                  width: '15%',
                  overflow: 'hidden',
                  outline: 'none'
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Presentation;
