import React from 'react';
import Overview from './components/Overview';
import User from './components/User';
import Setting from './components/Setting';

export default {
  name: 'AppUsers',
  label: 'App Users',
  labelDisable: true,
  settings: [
    {
      id: 'appuser',
      name: 'User',
      component: <Setting />
    }
  ],
  children: [
    {
      name: 'Users',
      active: true,
      hide: false,
      icon: 'user',
      to: '/appusers'
    }
  ],
  routes: [
    {
      path: '/appusers',
      name: 'Users',
      component: <Overview />,
      layout: 'main'
    },
    {
      path: '/appuser/:id',
      name: 'User Details',
      component: <User />,
      layout: 'main'
    }
  ]
};
