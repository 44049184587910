import Flex from '../../../../components/common/Flex';
import React from 'react';
import CreateOrganizationContent from './CreateOrganizationContent';

const CreateOrganization = () => {
  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-4">
        <h5>Create your organization</h5>
      </Flex>
      <CreateOrganizationContent />
    </>
  );
};

export default CreateOrganization;
