import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
const InputFormGroup = props => {
  const {
    col,
    name,
    autoComplete,
    type = 'text',
    disabled = false,
    placeholder,
    label,
    value,
    required,
    handleChange
  } = props;

  return (
    <Form.Group as={Col} {...col} controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        hasValidation
        as="textarea"
        rows={3}
        name={name}
        autoComplete={autoComplete ?? undefined}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        aria-describedby={`${name}-help`}
        value={value}
        onChange={handleChange}
      />
    </Form.Group>
  );
};

InputFormGroup.propTypes = {
  col: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};

export default InputFormGroup;
