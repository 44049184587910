import { Col, Row } from 'react-bootstrap';
import SelectLanguage from '../../../common/SelectLanguage';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const LanguageSelection = ({ setting }) => {
  if (
    typeof setting === 'object' &&
    setting &&
    Object.keys(setting).length > 0
  ) {
    // continue with the function logic
    const { t } = useTranslation();
    const {
      userSettings,
      allowedSettings: { default_value }
    } = setting;
    const [value, setValue] = useState(default_value);

    useEffect(() => {
      if (Object.keys(userSettings).length !== 0) {
        setValue(userSettings.value);
      }
    }, [userSettings]);

    return <SelectLanguage active={value} />;
  } else {
    // return null or an error message
    return null;
  }
};

LanguageSelection.propTypes = {
  setting: PropTypes.object
};

LanguageSelection.defaultProps = {
  setting: {}
};

export default LanguageSelection;
