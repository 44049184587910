import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AppContext from '../../context/Context';
import { countries } from '../../data/countries';
import { grays, isIterableArray } from '../../helpers/utils';
import { Form } from 'react-bootstrap';

const SelectLocalization = ({ active, onSelected }) => {
  const { t } = useTranslation();
  const {
    config: { isDark, localization }
  } = useContext(AppContext);

  const [value, setValue] = useState(active ?? localization);
  const [options, setOptions] = useState([]);

  const handleSelect = selectedOption => {
    onSelected(selectedOption);
    setValue(selectedOption.value);
  };

  useEffect(() => {
    if (isIterableArray(countries)) {
      const uniqueLanguages = new Set();
      const options = countries
        .filter(country => country.language && country.code2)
        .reduce((acc, country) => {
          if (
            !uniqueLanguages.has(country.language) &&
            Object.prototype.hasOwnProperty.call(country, 'supported') &&
            country.supported
          ) {
            uniqueLanguages.add(country.language);
            acc.push({
              value: country.code2,
              label: t(`country:${country.name}`)
            });
          }
          return acc;
        }, []);

      setOptions(options);
    }
  }, [t, countries]);

  const customStyles = {
    singleValue: (provided, state) => {
      const color = isDark ? grays['300'] : grays['900'];
      return { ...provided, color };
    }
  };

  return (
    <Form.Group
      className="mb-3"
      controlId="settingsForm.ControlLocalizationSelect"
    >
      <Form.Label>
        {t('capitalize', { value: t('settings:localization') })}
      </Form.Label>
      <Select
        styles={customStyles}
        closeMenuOnSelect={true}
        options={options}
        placeholder={t('Localization')}
        classNamePrefix="react-select"
        value={options.find(option => option.value === value)}
        onChange={handleSelect}
      />
    </Form.Group>
  );
};

SelectLocalization.propTypes = {
  onSelected: PropTypes.func,
  active: PropTypes.string
};

SelectLocalization.defaultProps = {
  onSelected: () => {}
};

export default SelectLocalization;
