import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import { Button, Card, Col, Collapse, Row } from 'react-bootstrap';

import { t } from 'i18next';
import IconButton from '../../../components/common/IconButton';
import APIResponseViewer from '../../../helpers/APIResponseViewer';
import Avatar from '../../../components/common/Avatar';
import LocalizationSelection from '../../../components/settings/general/components/LocalizationSelection';
import LanguageSelection from '../../../components/settings/general/components/LanguageSelection';
import SimpleFormV2 from '../../../helpers/SimpleForm/SimpleFormV2';

function Session() {
  const [formData, setFormData] = useState({});
  const [requested, setRequested] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const formOptions = [
    [
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'active',
        required: false,
        disabled: false,
        placeholder: 'active',
        label: t('active'),
        checked: formData.active == 1
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'user_id',
        elementType: 'custom',
        element: (
          <>
            <label>User id</label>
            <div>{formData?.user_id}</div>
          </>
        )
      },
      {
        col: { md: 3 },
        name: 'email',
        elementType: 'custom',
        element: (
          <>
            <label>email</label>
            <div>{formData?.email}</div>
          </>
        )
      },
      {
        col: { md: 3 },
        name: 'organization_id',
        elementType: 'input',
        type: 'text',

        placeholder: t('organization_id'),
        label: t('organization_id'),
        value: formData.organization_id
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'valid_till',
        elementType: 'custom',
        element: (
          <>
            <label>Valid till</label>
            <div>
              {t('intlDateTime', {
                val: new Date(formData.valid_till).toLocaleString()
              })}
            </div>
          </>
        )
      },
      {
        col: { md: 3 },
        name: 'valid_till',
        elementType: 'custom',
        element: (
          <>
            <label>Created at</label>
            <div>
              {t('intlDateTime', { val: new Date(formData.created_at) })}
            </div>
          </>
        )
      },
      {
        col: { md: 3 },
        name: 'valid_till',
        elementType: 'custom',
        element: (
          <>
            <label>Updated at</label>
            <div>
              {t('intlDateTime', { val: new Date(formData.updated_at) })}
            </div>
          </>
        )
      }
    ],
    [
      {
        col: { md: 12 },
        name: 'token',
        elementType: 'custom',
        element: (
          <>
            <label>Token</label>
            <span>{formData.token}</span>
          </>
        )
      }
    ]
  ];

  const getData = () => {
    setRequested(true);
    axios({
      url: `/session/${id}`,
      method: 'GET'
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const updateData = changedData => {
    setDataLoading(true);
    axios({
      url: `/session/${id}`,
      method: 'PUT',
      data: changedData
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  const deleteData = () => {
    setDataLoading(true);
    axios({
      url: `/user/${id}`,
      method: 'DELETE'
    })
      .then(function (res) {
        navigate(-1);
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <h5 className="">{t('Session')}</h5>
                </Col>
                <Col xs={'auto'}></Col>
                <Col xs={'auto'}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                    transform="shrink-3"
                    className="me-2"
                    tooltipText={'back'}
                    tooltipPosition={'left'}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <div>
                {dataLoading ? (
                  <Loader width={'100px'} />
                ) : (
                  <>
                    <SimpleFormV2
                      options={formOptions}
                      handleSubmit={updateData}
                      showDelete={true}
                      onDeleteClick={deleteData}
                    />

                    {formData && <APIResponseViewer data={formData} />}
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Session;
