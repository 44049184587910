import React from 'react';
import PropTypes from 'prop-types';
import DataGridTable from '../../../helpers/DataGridTable';
import { useTranslation } from 'react-i18next';
import SoftBadge from '../../../components/common/SoftBadge';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Button } from 'react-bootstrap';
import IconButton from '../../../components/common/IconButton';

const RoleTable = ({
  rows,
  loading,
  onAddClicked,
  onSnycClicked,
  onSelectedRow
}) => {
  const { t } = useTranslation();
  const columns = React.useMemo(() => [
    {
      field: 'name',
      headerName: t('Role'),
      type: 'string',
      flex: 1,
      minWidth: 100
    }
  ]);

  const onAddHandle = value => onAddClicked(value);
  const onSyncHandle = value => onSnycClicked(value);

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ marginBottom: 20 }}>
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
          className="me-2"
          tooltipText={'new'}
          tooltipPosition={'top'}
          onClick={onAddHandle}
        />
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="arrows-rotate"
          transform="shrink-3"
          className="me-2"
          tooltipText={'refresh'}
          tooltipPosition={'top'}
          onClick={onSyncHandle}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 450, width: '100%' }}>
      <DataGridTable
        columns={columns}
        rows={rows}
        onRowClick={onSelectedRow}
        autoPageSize
        disableColumnSelector
        addComponents={{
          toolbar: CustomToolbar
        }}
      />
    </div>
  );
};

RoleTable.propTypes = {
  rows: PropTypes.array,
  loading: PropTypes.bool,
  onAddClicked: PropTypes.func,
  onSnycClicked: PropTypes.func,
  onSelectedRow: PropTypes.func
};

RoleTable.defaultTypes = {
  rows: {},
  onSelectedRow: () => {},
  onSnycClicked: () => {},
  onAddClicked: () => {}
};

export default RoleTable;
