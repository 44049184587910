import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logoLight from 'assets/img/logos/kesify.svg';
import logoDark from 'assets/img/logos/kesify-dark.svg';
import logoNextToLight from 'assets/img/logos/kesify-nextto-light.svg';
import logoNextToDark from 'assets/img/logos/kesify-nextto-dark.svg';
import logoIcon from 'assets/img/logos/kesify-icon.svg';
import logoNameLight from 'assets/img/logos/kesify-name-light.svg';
import logoNameDark from 'assets/img/logos/kesify-name-dark.svg';
import AppContext from '../../context/Context';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Image } from 'react-bootstrap';

const Logo = ({
  darkmode,
  variant,
  at,
  width,
  widthSm,
  className,
  textClass,
  organization,
  srcLight,
  srcDark,
  useAppLogo,
  ...rest
}) => {
  let {
    config: { isDark }
  } = useContext(AppContext);

  if (darkmode) isDark = darkmode;

  let [source, setSource] = useState();

  useEffect(() => {
    if (srcLight && !isDark) {
      setSource(srcLight);
    } else if (srcDark && isDark) {
      setSource(srcDark);
    } else {
      if (!useAppLogo && organization?.logo_light_url && !isDark) {
        setSource(organization.logo_light_url);
      } else if (!useAppLogo && organization?.logo_dark_url && isDark) {
        setSource(organization.logo_dark_url);
      } else {
        if (variant === 'full') setSource(isDark ? logoLight : logoDark);
        if (variant === 'nextTo')
          setSource(isDark ? logoNextToLight : logoNextToDark);
        if (variant === 'icon') setSource(logoIcon);
        if (variant === 'name')
          setSource(isDark ? logoNameLight : logoNameDark);
      }
    }
  }, [organization, isDark]);

  useEffect(() => {
    if (srcLight && !isDark) {
      setSource(srcLight);
    } else if (srcDark && isDark) {
      setSource(srcDark);
    }
  }, [srcLight, srcDark]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {organization?.name ? `${organization?.name} | kesify` : 'kesify'}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <Link
        to="/"
        className={classNames(
          'text-decoration-none',
          { 'navbar-brand text-left': at === 'navbar-vertical' },
          { 'navbar-brand text-left': at === 'navbar-top' }
        )}
      >
        <div
          className={classNames(
            'd-flex',
            {
              'align-items-center py-3': at === 'navbar-vertical',
              'align-items-center': at === 'navbar-top',
              'flex-center fw-bolder fs-5 mb-4': at === 'auth'
            },
            className
          )}
        >
          <Image
            className="me-2 d-none d-sm-block img-fluid"
            src={source}
            alt="Logo"
            width={width}
          />
          <Image
            className="me-2 d-block d-sm-none img-fluid"
            src={source}
            alt="Logo"
            width={widthSm}
          />
        </div>
      </Link>
    </HelmetProvider>
  );
};

Logo.propTypes = {
  darkmode: PropTypes.bool,
  useAppLogo: PropTypes.bool,
  variant: PropTypes.oneOf(['full', 'icon', 'name', 'nextTo']),
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  widthSm: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string,
  srcLight: PropTypes.string,
  srcDark: PropTypes.string,
  organization: PropTypes.object
};

Logo.defaultProps = {
  useAppLogo: false,
  at: 'auth',
  width: 58,
  widthSm: 100,
  variant: 'icon'
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    organization: state.organization.organization
  };
};

export default connect(mapStateToProps, null)(Logo);
