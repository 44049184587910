import React, { Fragment, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import IconButton from '../../../components/common/IconButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import SimpleFormV2 from '../../../helpers/SimpleForm/SimpleFormV2';
import axios from 'axios';

const Create = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(false);

  const formData = React.useMemo(() => ({
    active: true,
    is_free: true,
    name: null,
    description: null,
    microservice_name: null
  }));

  const formOptions = [
    [
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'is_free',
        required: false,
        disabled: false,
        placeholder: 'is_free',
        label: t('isFree'),
        checked: formData.is_free
      },
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'active',
        required: false,
        disabled: false,
        placeholder: 'active',
        label: t('active'),
        checked: formData.active
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'name',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Name'),
        label: t('Name')
      },
      {
        col: { md: 3 },
        name: 'microservice_name',
        elementType: 'input',
        type: 'text',
        required: false,
        placeholder: t('microservice_name'),
        label: t('microservice_name')
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'description',
        elementType: 'textarea',
        placeholder: t('description'),
        label: t('description')
      }
    ]
  ];

  const createData = data => {
    setDataLoading(true);
    axios({
      url: `/ms/extension`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function (res) {
        if (res.data.result) {
          //setFormData(res.data.result);
        }
        navigate(-1);
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <h5 className="">{t('Extension')}</h5>
                </Col>
                <Col xs={'auto'}></Col>
                <Col xs={'auto'}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                    transform="shrink-3"
                    className="me-2"
                    tooltipText={'back'}
                    tooltipPosition={'left'}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <div>
                {dataLoading ? (
                  <Loader width={'100px'} />
                ) : (
                  <>
                    {
                      <SimpleFormV2
                        mode={'create'}
                        formData={formData}
                        options={formOptions}
                        handleSubmit={createData}
                        showDelete={false}
                      />
                    }
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Create;
