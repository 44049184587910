import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Collapse, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccordionItem = ({ title, description, open }) => {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <Card className="shadow-none border-bottom rounded-0">
      <Accordion.Item eventKey={title} className="border-0">
        <Card.Header className="p-0" id={`accordionHeading${title}`}>
          <Accordion.Button className="btn btn-link text-decoration-none d-block w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none">
            <FontAwesomeIcon
              icon="caret-right"
              className="accordion-icon me-3 "
              transform="shrink-2"
            />
            <span className="fw-medium font-sans-serif text-900">{title}</span>
          </Accordion.Button>
        </Card.Header>
        <Accordion.Body className="p-0">
          <Card.Body className="pt-2">
            <div className="ps-3 mb-0">{description}</div>
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    </Card>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.any,
  open: PropTypes.bool
};

AccordionItem.defaultProps = { open: false };

export default AccordionItem;
