import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Loader from '../../../components/common/Loader';
import OverviewTable from './OverviewTable';
import { useNavigate } from 'react-router-dom';

const Overview = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [modal, setModal] = useState(false);
  const [requested, setRequested] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  let [data, setData] = useState([]);
  let [mode, setMode] = useState('create');
  let [row, setRow] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRefresh = () => {
    setDataLoading(true);
    getRequest();
  };

  const getRequest = () => {
    setRequested(true);
    axios({
      url: '/microservice',
      method: 'GET'
    })
      .then(function (res) {
        if (res.data.result) {
          setData(res.data.result);
        } else if (!res.data.result) {
          //setSub(false);
        }
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    if (!requested) getRequest();
  });

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row className="justify-content-between align-items-center">
                <Col md>
                  <Row className={'align-items-center '}>
                    <Col className={'col-auto'}>
                      <h5 className="">{t('Microservices')}</h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <OverviewTable
                rows={data}
                loading={dataLoading}
                onAddClicked={() => navigate(`/microservice/create`)}
                onSnycClicked={handleRefresh}
                onRowEditClicked={row => {
                  setRow(row);
                  navigate(`/microservice/${row.id}`);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Overview;
