import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from '../components/common/SoftBadge';
import PropTypes from 'prop-types';

const SimpleBadge = ({ value, options }) => {
  const option = options[value];

  if (!option) {
    return null;
  }

  const { className, label, icon } = option;

  return (
    <SoftBadge pill bg={className} className="d-block ">
      {label}
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          transform="shrink-2"
          className={label ? 'ms-1' : 'KL'}
        />
      )}
    </SoftBadge>
  );
};

const entryPropTypes = PropTypes.shape({
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string
});

const propTypesObject = PropTypes.objectOf(entryPropTypes);

SimpleBadge.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: propTypesObject.isRequired
};

export default SimpleBadge;
