import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../../../../wizard/WizardInput';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { setRegistration } from '../../../../../redux/actions';
import { connect } from 'react-redux';
import { AuthWizardContext } from '../../../../../context/Context';

const AccountForm = ({ register, errors, watch }) => {
  const { user } = useContext(AuthWizardContext);

  return (
    <>
      <Row className="g-2 mb-3">
        <WizardInput
          type="password"
          errors={errors}
          label="Password*"
          name="password"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            autoComplete: 'new-password',
            ...register('password', {
              required: 'You must specify a password',
              pattern: {
                value: /([a-zA-Z]).{7,}/,
                message:
                  'Must contain at least one uppercase or lowercase letter, and at least 8 characters.'
              },
              minLength: {
                value: 2,
                message: 'Password must have at least 2 characters'
              }
            })
          }}
        />
        <WizardInput
          type="password"
          errors={errors}
          label="Confirm Password*"
          name="confirmPassword"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            autoComplete: 'new-password',
            ...register('confirmPassword', {
              required: 'Confirm Password field is required',
              validate: value =>
                value === watch('password') || 'The password do not match'
            })
          }}
        />
      </Row>
    </>
  );
};

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default AccountForm;
