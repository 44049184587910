import React from 'react';
import { Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import localeHelper from '../locale';
import PropTypes from 'prop-types';

const DateFormGroup = props => {
  const {
    col,
    placeholder,
    name,
    disabled = false,
    label,
    required,
    value,
    handleChange
  } = props;

  const selectedDate = value ? new Date(value) : null;

  return (
    <Form.Group as={Col} {...col} controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <div>
        <DatePicker
          name={name}
          selected={selectedDate}
          onChange={handleChange}
          className="form-control w-100"
          placeholderText={placeholder}
          disabled={disabled}
          required={required}
          locale={localeHelper.locale}
          dateFormat={localeHelper.date2}
          {...props.datepickerProps}
        />
      </div>
    </Form.Group>
  );
};

DateFormGroup.propTypes = {
  col: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};

export default DateFormGroup;
