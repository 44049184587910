import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import FalconCloseButton from '../../components/common/FalconCloseButton';
import LottiePlayer from '../../components/common/LottiePlayer';
import animationData from '../../components/wizard/lottie/warning-light.json';
import { t } from 'i18next';
import Flex from '../../components/common/Flex';
import DateFormGroup from './DateFormGroup';
import SelectFormGroup from './SelectFormGroup';
import SwitchFormGroup from './SwitchFormGroup';
import InputFormGroup from './InputFormGroup';
import UploadForm from './UploadForm';
import axios from 'axios';

const SimpleForm = ({
  options,
  readOnly,
  allDisabled,
  handleSubmit,
  showDelete,
  onDeleteClick
}) => {
  const [formData, setFormData] = useState({});
  const [changedData, setChangedData] = useState({});
  const [validated, setValidated] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleChange = event => {
    const { name, value, checked, type } = event.target;
    const updatedFormData = { ...changedData };

    // Update the formData object with the new input value
    updatedFormData[name] =
      type === 'checkbox' || type === 'radio' ? checked : value;

    setChangedData(updatedFormData);
    setFormData(prevFormData => ({ ...prevFormData, ...updatedFormData }));
  };

  const handleChangeDate = event => {
    const { name, value } = event;
    const updatedFormData = { ...changedData };

    // Update the formData object with the new input value
    updatedFormData[name] = dayjs(value).format('YYYY-MM-DD');

    setChangedData(updatedFormData);
    setFormData(prevFormData => ({ ...prevFormData, ...updatedFormData }));
  };

  const handleChangeUpload = files => {
    files.map((file, index) => {
      console.log(file);
    });
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    // Log the formData object to see all the form data
    setValidated(true);

    if (event.currentTarget.checkValidity()) {
      handleSubmit(changedData);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
        <Flex direction="row-reverse">
          <Row className="mb-3">
            <Col>
              {showDelete && (
                <Button
                  variant="danger"
                  className="me-2"
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
              <Button
                variant="falcon-success"
                type="submit"
                disabled={Object.keys(changedData).length <= 0}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Flex>
        {options.map((section, index) => (
          <Row className="mb-3" key={`formRow-${index}`}>
            {section.map((field, fieldIndex) => {
              const { name, elementType } = field;
              let value =
                elementType === 'switch' ? field.checked : field.value;
              if (Object.prototype.hasOwnProperty.call(changedData, name)) {
                value = changedData[name];
              }

              if (elementType === 'input') {
                return (
                  <InputFormGroup
                    {...field}
                    value={value}
                    handleChange={handleChange}
                    key={`formElement-${fieldIndex}`}
                  />
                );
              }

              if (elementType === 'switch') {
                return (
                  <SwitchFormGroup
                    {...field}
                    checked={value}
                    handleChange={handleChange}
                    key={`formElement-${fieldIndex}`}
                  />
                );
              }

              if (elementType === 'select') {
                return (
                  <SelectFormGroup
                    {...field}
                    value={value}
                    handleChange={handleChange}
                    key={`formElement-${fieldIndex}`}
                  />
                );
              }

              if (elementType === 'date') {
                return (
                  <DateFormGroup
                    {...field}
                    value={value}
                    handleChange={value =>
                      handleChangeDate({ name: field.name, value })
                    }
                    key={`formElement-${fieldIndex}`}
                  />
                );
              }

              if (elementType === 'upload') {
                return (
                  <UploadForm
                    {...field}
                    value={value}
                    handleChange={field?.handleChange}
                    key={`formElement-${fieldIndex}`}
                  />
                );
              }

              if (elementType === 'custom') {
                return (
                  <Form.Group
                    as={Col}
                    {...field.col}
                    className={'h-100'}
                    controlId={name}
                    key={`formElement-${fieldIndex}`}
                  >
                    {field.element}
                  </Form.Group>
                );
              }

              return null;
            })}
          </Row>
        ))}
      </Form>
      <DeleteModal
        modal={deleteModal}
        setModal={setDeleteModal}
        onClick={onDeleteClick}
      />
    </>
  );
};

SimpleForm.propTypes = {
  readOnly: PropTypes.bool,
  allDisabled: PropTypes.bool,
  showDelete: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  handleSubmit: PropTypes.func,
  onDeleteClick: PropTypes.func
};

SimpleForm.defaultProps = {
  readOnly: false,
  allDisabled: false,
  showDelete: false,
  options: null,
  handleSubmit: () => {},
  onDeleteClick: () => {}
};

function DeleteModal({ modal, setModal, onClick }) {
  return (
    <Modal show={modal} centered dialogClassName="wizard-modal">
      <Modal.Body className="p-4">
        <FalconCloseButton
          size="sm"
          className="position-absolute top-0 end-0 me-2 mt-2"
          onClick={() => setModal(!modal)}
        />
        <Flex justifyContent="center" alignItems="center">
          <LottiePlayer
            animationData={animationData}
            loop={true}
            style={{ width: '100px' }}
          />
          <p className="mb-0 flex-1">{t('Are you sure about deleting?')}</p>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Button
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setModal(!modal);
            }}
          >
            {t('abort')}
          </Button>
          <Button
            variant="danger"
            className="me-2"
            onClick={() => {
              setModal(!modal);
              onClick(true);
            }}
          >
            {t('delete')}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

DeleteModal.propTypes = {
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  onClick: PropTypes.func
};

function getRandomString(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

export default SimpleForm;
