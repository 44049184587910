import React from 'react';
import Detail from './components/Detail';
import Overview from './components/Overview';
import Create from './components/Create';

export default {
  name: 'Microservices',
  label: 'Microservices',
  labelDisable: true,
  children: [
    {
      name: 'Microservices',
      active: true,
      icon: 'sitemap',
      to: '/microservices'
    }
  ],
  routes: [
    {
      path: '/microservices',
      name: 'Organizations',
      component: <Overview />,
      layout: 'main'
    },
    {
      path: '/microservice/create',
      name: 'Microservice create',
      component: <Create />,
      layout: 'main'
    },
    {
      path: '/microservice/:id',
      name: 'Microservice Details',
      component: <Detail />,
      layout: 'main'
    }
  ]
};
