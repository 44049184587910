const locale = {
  locale: 'en',
  locale2: 'en-US',
  date: 'YYYY-MM-DD',
  date2: 'yyyy-MM-dd',
  datetime: 'YYYY-MM-DD HH:mm',
  datetime2: 'yyyy-MM-dd HH:mm'
};

export default locale;
