import React from 'react';
import logoloader from '../../assets/img/animated-icons/logo.json';
import { Box } from '@mui/material';
import LogoAnimation from '../common/LogoAnimation';
import { Col, Row, Button, Alert } from 'react-bootstrap';
import { t } from 'i18next';
import IconAlert from '../common/IconAlert';

const ComingSoon = () => {
  return (
    <Box mt={6}>
      <Row className={'justify-content-center mb-2'}>
        <Col xs={'auto'}>
          <Alert key={'info'} variant={'info'}>
            <p className="mb-0 fw-bold">Contact via E-Mail</p>
            <p className="mb-0">info@kesify.com</p>
          </Alert>
        </Col>
      </Row>
      <LogoAnimation
        style={{
          width: '30%',
          overflow: 'hidden',
          outline: 'none'
        }}
      />

      <Row className={'justify-content-center'}>
        <Col xs={'auto'}>
          <Button
            href={process.env.REACT_APP_APP_URL}
            variant="falcon-default"
            className="me-2 mb-1"
          >
            {t('capitalize', { value: t('auth:login') })}
          </Button>
        </Col>
      </Row>
    </Box>
  );
};

export default ComingSoon;
