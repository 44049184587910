import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import logoloader from '../../assets/img/animated-icons/logo.json';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import LottiePlayer from './LottiePlayer';

const LogoAnimation = ({ loop, style }) => {
  return (
    <Link to="/" className={classNames('text-decoration-none')}>
      <LottiePlayer
        isCentered
        play
        loop={loop}
        animationData={logoloader}
        style={style}
      />
    </Link>
  );
};

LogoAnimation.propTypes = {
  ...Spinner.propTypes,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object
};

LogoAnimation.defaultProps = {
  loop: false,
  animation: 'border',
  size: 'lg',
  color: 'primary',
  width: '30%',
  height: 'auto',
  style: {
    width: '30%',
    overflow: 'hidden',
    margin: '0px auto',
    outline: 'none'
  }
};

export default LogoAnimation;
