import { Card, Col, Row } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SelectLocalization from '../../../common/SelectLocalization';
import { setChangedSettings } from '../../../../redux/actions';
import { connect } from 'react-redux';
import AppContext from '../../../../context/Context';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import classNames from 'classnames';

const LocalizationSelection = ({ setting, setChangedSettings, requests }) => {
  if (setting && Object.keys(setting).length > 0) {
    const { t, i18n } = useTranslation();
    const { userSettings } = setting;
    const default_value = setting?.default_value || null;
    const [value, setValue] = useState(userSettings?.value ?? default_value);
    const { setConfig } = useContext(AppContext);
    dayjs.extend(isSameOrAfter);

    useEffect(() => {
      if (
        requests?.url === '/settings' &&
        requests?.method === 'put' &&
        dayjs(requests?.time).isSameOrAfter(dayjs().subtract(1, 'second')) && //to check that when the last request is the same it will not do same task
        value
      ) {
        i18n.changeLanguage(value);
        setConfig('localization', value);
      }
    }, [value]);

    const onSelected = selectedValue => {
      setChangedSettings({ [setting.id]: selectedValue.value });
      setValue(selectedValue.value);
    };

    return (
      <Row className={'align-middle text-nowrap'}>
        <Col lg={2}>
          <SelectLocalization active={value} onSelected={onSelected} />
        </Col>
        <Col xs={12} md={'auto'}>
          <Card className={'text-bg-info'}>
            <Card.Body>
              <Card.Title as="div">
                {t('capitalize', { value: t('example') })}
              </Card.Title>
              <Card.Text>
                <div>{t('intlPrice', { val: 99999999.99 })}</div>
                <div>{t('intlDateTime', { val: new Date() })}</div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

LocalizationSelection.propTypes = {
  setting: PropTypes.object,
  requests: PropTypes.object,
  setChangedSettings: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    requests: state.requests,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setChangedSettings: content => dispatch(setChangedSettings(content))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalizationSelection);
