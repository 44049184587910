import allReducers from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import axios from 'axios';

const customMiddleWare = store => next => action => {
  try {
    //console.log('Middleware triggered:', action);
    if (
      action.type === 'persist/REHYDRATE' &&
      action.payload?.auth &&
      action.payload?.auth?.isAuthenticated
    ) {
      axios.defaults.headers.common[
        'Authorization'
      ] = `${action.payload.auth.tokenType} ${action.payload.auth.token}`;

      //@toDO: launcher
      axios.defaults.headers.common[
        'organizationId'
      ] = `41e03707-5f09-4e6d-b6eb-14c88a83a0b9`;
    }

    const result = next(action);
    return result;
  } catch (error) {
    console.error('Middleware error:', error);
    throw error;
  }
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['spinner']
};

const persistedReducer = persistReducer(persistConfig, allReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [customMiddleWare]
});

const persistor = persistStore(store);

export { store, persistor };
