import React, { Fragment, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { t } from 'i18next';
import IconButton from '../../../components/common/IconButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import APIResponseViewer from '../../../helpers/APIResponseViewer';
import SimpleFormV2 from '../../../helpers/SimpleForm/SimpleFormV2';
import axios from 'axios';

const Create = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(false);

  const formData = React.useMemo(() => ({
    active: true,
    name: null,
    host: null,
    port: null,
    path: '/api',
    database_name: null,
    database_host: 'mysql',
    database_port: '3306',
    database_user: null,
    database_password: null
  }));

  const formOptions = [
    [
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'active',
        required: false,
        disabled: false,
        placeholder: 'active',
        label: t('active'),
        checked: formData.active
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'name',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Name'),
        label: t('Name')
      },
      {
        col: { md: 3 },
        name: 'host',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Host'),
        label: t('Host')
      },
      {
        col: { md: 3 },
        name: 'port',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Port'),
        label: t('Port')
      },
      {
        col: { md: 3 },
        name: 'path',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Path'),
        label: t('Path'),
        value: formData.path
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'database_name',
        elementType: 'input',
        type: 'text',
        placeholder: t('Database'),
        label: t('Database')
      },
      {
        col: { md: 3 },
        name: 'database_host',
        elementType: 'input',
        type: 'text',
        placeholder: t('Database Host'),
        label: t('Database Host'),
        value: formData.database_host
      },
      {
        col: { md: 3 },
        name: 'database_port',
        elementType: 'input',
        type: 'text',
        placeholder: t('Database Port'),
        label: t('Database Port'),
        value: formData.database_port
      },
      {
        col: { md: 3 },
        name: 'database_user',
        elementType: 'input',
        type: 'text',
        placeholder: t('Database User'),
        label: t('Database User')
      },
      {
        col: { md: 3 },
        name: 'database_password',
        elementType: 'input',
        type: 'password',
        autoComplete: 'new-password',
        placeholder: t('Database Password'),
        label: t('Database Password')
      }
    ]
  ];

  const createData = data => {
    setDataLoading(true);
    axios({
      url: `/microservice`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function (res) {
        if (res.data.result) {
          //setFormData(res.data.result);
        }
        navigate(-1);
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <h5 className="">{t('Create')}</h5>
                </Col>
                <Col xs={'auto'}></Col>
                <Col xs={'auto'}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                    transform="shrink-3"
                    className="me-2"
                    tooltipText={'back'}
                    tooltipPosition={'left'}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <div>
                {dataLoading ? (
                  <Loader width={'100px'} />
                ) : (
                  <>
                    {
                      <SimpleFormV2
                        mode={'create'}
                        formData={formData}
                        options={formOptions}
                        handleSubmit={createData}
                        showDelete={false}
                      />
                    }
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Create;
