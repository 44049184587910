import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import Loader from '../../../../components/common/Loader';
import APIResponseViewer from '../../../../helpers/APIResponseViewer';
import PropTypes from 'prop-types';

const DetailUserModal = ({
  show,
  setModalVisible,
  userId,
  organizationId,
  onSuccess
}) => {
  const isDisabled = false;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (userId) getRequest();
  }, [userId]);

  const getRequest = () => {
    setLoading(true);
    axios({
      url: `/user/${userId}`,
      method: 'GET'
    })
      .then(function (res) {
        setLoading(false);
        setUser(res.data.result);
        //onSuccess(true);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  const postRequest = () => {
    if (userId) {
      setLoading(true);
      axios({
        url: `/ms/organization/assignUser/${organizationId}`,
        method: 'PUT',
        data: { user_id: userId }
      })
        .then(function (res) {
          setLoading(false);
          setModal(!modal);
          onSuccess(true);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  };

  const deleteRequest = () => {
    if (userId) {
      setLoading(true);
      axios({
        url: `/ms/organization/removeUser/${organizationId}`,
        method: 'DELETE',
        data: { user_id: userId }
      })
        .then(function (res) {
          setLoading(false);
          setModalVisible(!show);
          onSuccess(true);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('Edit user')}
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => setModalVisible(!show)}
        />
      </Modal.Header>
      <Modal.Body className="p-4">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Button variant={'danger'} onClick={deleteRequest}>
              {t('remove')}
            </Button>
            <APIResponseViewer data={user} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'falcon-default'}
          onClick={() => {
            setModalVisible(!show);
          }}
        >
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DetailUserModal.propTypes = {
  show: PropTypes.bool,
  setModalVisible: PropTypes.func,
  userId: PropTypes.string,
  organizationId: PropTypes.string
};

DetailUserModal.defaultProps = {
  show: false,
  setModalVisible: () => {}
};

export default DetailUserModal;
