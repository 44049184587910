import React from 'react';
import ConfirmMailContent from 'components/authentication/ownersbackend/ConfirmMailContent';
import { setRegistration } from '../../../../redux/actions';
import { connect } from 'react-redux';
import RegistrationForm from '../RegistrationForm';
import PropTypes from 'prop-types';

const ConfirmMail = ({ registration }) => (
  <div className="text-center">
    <ConfirmMailContent email={registration.email} />
  </div>
);

ConfirmMail.propTypes = {
  registration: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    registration: state.registration,
    ...ownProps
  };
};

export default connect(mapStateToProps, null)(ConfirmMail);
