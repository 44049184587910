import {Card, Col, Image, Row} from "react-bootstrap";
import React, {useContext} from "react";
import PropTypes from "prop-types";
import AppContext from "../../../../../context/Context";

const FullImage = ({page}) => {
    let {
        config: { isDark }
    } = useContext(AppContext);

    return (
        <Row className={'justify-content-center mb-3'}>
            <Col xs={12}>
                <Card
                    className=" d-flex justify-content-center align-items-center "
                    style={{ height: '80vh', overflow: 'hidden' }}
                >
                    <Card.Body
                        className="d-flex justify-content-center align-items-center "
                        style={{ maxHeight: '80vh' }}
                    >
                        {page.img_light && !isDark && (
                            <Image
                                fluid
                                src={page.img_light}
                                className="w-100 p-3"
                                style={{ maxHeight: '80vh', objectFit: 'cover' }}
                            />
                        )}
                        {page.img_dark && isDark && (
                            <Image
                                fluid
                                src={page.img_dark}
                                className="w-100 p-3"
                                style={{ maxHeight: '80vh', objectFit: 'cover' }}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

FullImage.propTypes = {
    page: PropTypes.object.isRequired,
};

FullImage.defaultTypes = {
    page: {},
};

export default FullImage;
