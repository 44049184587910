import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../../../../wizard/WizardInput';
import FalconDropzone from 'components/common/FalconDropzone';
import avatarImg from 'assets/img/team/avatar.png';
import { isIterableArray } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { AuthWizardContext } from 'context/Context';
import Flex from 'components/common/Flex';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import localeHelper from '../../../../../helpers/locale';
import { Link } from 'react-router-dom';
import axios from 'axios';

const PersonalForm = ({ register, errors, setValue, setUser }) => {
  const { user, validationToken } = useContext(AuthWizardContext);

  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg }
  ]);

  const handleProfilePicture = file => {
    return axios({
      url: `/user/${user.id}/preregisterprofilepicture`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: { file: file.file, validationToken }
    });
  };

  return (
    <>
      <Row className="mb-3">
        <Col md="auto">
          <Avatar
            size="4xl"
            src={
              isIterableArray(avatar) ? avatar[0]?.base64 || avatar[0]?.src : ''
            }
          />
        </Col>
        <Col md>
          <FalconDropzone
            files={avatar}
            onChange={files => {
              setAvatar(files);
              setValue('profile_picture', files[0]);
              handleProfilePicture(files[0]);
              //setUser({ ...user, profile_picture: files[0] });
            }}
            multiple={false}
            accept="image/*"
            placeholder={
              <>
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">
                    Upload your profile picture
                  </p>
                </Flex>
                <p className="mb-0 w-75 mx-auto text-400">
                  Upload a 300x300 jpg image with a maximum size of 400KB
                </p>
              </>
            }
          />
        </Col>
      </Row>

      <Row className={'g-2 mb-3'}>
        <WizardInput
          label="First name"
          name="firstname"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            autoComplete: 'given-name',
            ...register('firstname', {
              required: 'You must type in your first name',
              minLength: {
                value: 2,
                message: 'First name must have at least 2 characters'
              }
            })
          }}
        />
        <WizardInput
          label="Last name"
          name="lastname"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            autoComplete: 'family-name',
            ...register('lastname', {
              required: 'You must type in your last name',
              minLength: {
                value: 2,
                message: 'Last name must have at least 2 characters'
              }
            })
          }}
        />
      </Row>

      <WizardInput
        type="select"
        label="Gender"
        name="gender"
        placeholder="Select your gender..."
        errors={errors}
        options={['male', 'female']}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('gender')
        }}
      />

      <WizardInput
        type="number"
        label="Phone"
        name="phone"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('phone')
        }}
      />

      <WizardInput
        type="date"
        label="Date of Birth"
        name="birthday"
        errors={errors}
        setValue={(name, value) => {
          setValue(name, dayjs(value).format('YYYY-MM-DD'));
        }}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          placeholder: 'Date of Birth',
          ...register('birthday', {
            required: 'You must type in your date of birth'
          })
        }}
        datepickerProps={{
          maxDate: new Date(),
          showYearDropdown: true,
          showMonthDropdown: true
        }}
      />

      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            I accept the <Link to="#!"> terms</Link> and{' '}
            <Link to="#!"> privacy policy</Link>
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: 'You need to agree the terms and privacy policy.'
          })
        }}
      />
    </>
  );
};

PersonalForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  setUser: PropTypes.func
};

export default PersonalForm;
