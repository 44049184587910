import SelectTimezone from '../../../../helpers/SelectTimezone';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import AppContext from '../../../../context/Context';
import { setChangedSettings } from '../../../../redux/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const TimezoneSelection = ({ setting, setChangedSettings }) => {
  if (
    typeof setting === 'object' &&
    setting &&
    Object.keys(setting).length > 0
  ) {
    const { t } = useTranslation();
    const { userSettings, default_value } = setting;
    const { setConfig } = useContext(AppContext);

    const active =
      userSettings && userSettings?.value ? userSettings?.value : default_value;

    const handleSelect = data => {
      setChangedSettings({ [setting.id]: data.value });
    };

    return (
      <Form.Group
        className="mb-3"
        controlId="settingsForm.ControlTimezoneSelect"
      >
        <Form.Label>
          {t('capitalize', { value: t('settings:timezone') })}
        </Form.Label>
        <SelectTimezone active={active} onSelected={handleSelect} />
      </Form.Group>
    );
  }

  return null;
};

TimezoneSelection.propTypes = {
  setting: PropTypes.shape({
    allowedSettings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        caption: PropTypes.string.isRequired,
        item_value: PropTypes.string.isRequired
      })
    ).isRequired,
    userSettings: PropTypes.shape({
      value: PropTypes.string.isRequired
    }),
    default_value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  requests: PropTypes.shape({
    url: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired
  }),
  setChangedSettings: PropTypes.func.isRequired
};

TimezoneSelection.defaultProps = {
  setting: null,
  requests: {}
};

const mapStateToProps = (state, ownProps) => {
  return {
    requests: state.requests,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setChangedSettings: content => dispatch(setChangedSettings(content))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneSelection);
