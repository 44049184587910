export const setAuthenticated = content => ({
  type: 'setAuthenticated',
  payload: content
});

export const getAuth = () => ({
  type: 'getAuth'
});

export const setLogin = content => ({
  type: 'setLogin',
  payload: content
});

export const setLogout = () => ({
  type: 'setLogout'
});

export const setSubdomain = content => ({
  type: 'setSubdomain',
  payload: content
});

export const setRegistration = content => ({
  type: 'setRegistration',
  payload: content
});

export const setChangedSettings = content => ({
  type: 'setChangedSettings',
  payload: content
});

export const setSpinner = (name, value) => ({
  type: 'setSpinner',
  payload: { name, value }
});

export const action = (type, content) => ({
  type: type,
  payload: content
});

export const settingsActions = content => ({
  type: content.type,
  payload: content.payload
});

export const requestsReducerActions = content => ({
  type: content.type,
  payload: content.payload
});
