import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Error500 = () => {
  const { t } = useTranslation();

  return (
    <Card className="text-center h-100">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">500</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {t(`Whoops, something went wrong!`)}
        </p>
        <hr />
        <p>
          {t(
            `Try refreshing the page, or going back and attempting the action again. If this problem persists,`
          )}
          <a href="mailto:info@kesify.com" className="ms-1">
            {t(`contact us`)}
          </a>
          .
        </p>
      </Card.Body>
    </Card>
  );
};

export default Error500;
