let initialState = {
  mainloader: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setSpinner': {
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value
      };
    }
    default:
      return state;
  }
};

export default authReducer;
