import React from 'react';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/ownersbackend/LoginForm';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();

  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>{t('common:capitalize', { value: t('auth:login') })}</h5>
        <p className="fs--1 text-600 mb-0">
          {t('common:or')}{' '}
          <Link to="/auth/register">{t('auth:createAccount')}</Link>
        </p>
      </Flex>
      <LoginForm />
    </>
  );
};

export default Login;
