import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Loader from '../../common/Loader';
import { setRegistration, settingsActions } from '../../../redux/actions';
import { connect } from 'react-redux';
import WizardForm from '../../wizard/Wizard';
import WizardLayout from '../../wizard/WizardLayout';
import AuthWizardProvider from '../../wizard/AuthWizardProvider';
import RegistrationWizardLayout from './simple/RegistrationWizard/RegistrationWizardLayout';

const RegistrationWizardForm = ({ user, setRegistration }) => {
  // State
  const [formData, setFormData] = useState({
    email: ''
  });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    setIsLoading(true);
    axios({
      url: '/auth/register',
      method: 'POST',
      data: formData
    }).then(function (res) {
      const result = res.data.result;
      setRegistration({
        email: formData.email,
        token: result.verifaction_token
      });
      navigate('/auth/registration-mail');
      setIsLoading(false);
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <AuthWizardProvider userData={user}>
      <RegistrationWizardLayout
        variant={'pills'}
        validation={true}
        progressBar={true}
      />
    </AuthWizardProvider>
  );
};

RegistrationWizardForm.propTypes = {
  setRegistration: PropTypes.func,
  user: PropTypes.object
};

RegistrationWizardForm.defaultProps = {
  user: {}
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setRegistration: content => dispatch(setRegistration(content))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationWizardForm);
