import React, { useEffect, useState } from 'react';
import PasswordResetForm from 'components/authentication/ownersbackend/PasswordResetForm';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { validateToken } from '../../../../helpers/standardRequests';
import Loader from '../../../common/Loader';

const PasswordReset = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    validateToken(token)
      .then(function (res) {
        const result = res.data.result;
        result?.user ? setUser(result?.user) : false;
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        navigate('/');
      });
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="text-center">
      <h5>{t('Reset new password')}</h5>
      <PasswordResetForm user={user} token={token} />
    </div>
  );
};
export default PasswordReset;
