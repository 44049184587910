import React, { useEffect } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TinymceEditor from '../../components/common/TinymceEditor';
import { t } from 'i18next';
const TinymceFormGroup = props => {
  const {
    col,
    name,
    disabled = false,
    placeholder,
    label,
    value,
    toolbar,
    required,
    handleChange
  } = props;

  return (
    <Form.Group as={Col} {...col} controlId={name}>
      {label && <Form.Label>{t(label)}</Form.Label>}
      <TinymceEditor
        value={value}
        handleChange={handleChange}
        toolbar={toolbar ?? null}
      />
    </Form.Group>
  );
};

TinymceFormGroup.propTypes = {
  col: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};

export default TinymceFormGroup;
