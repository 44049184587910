import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AppContext from '../../context/Context';
import { countries } from '../../data/countries';
import { grays, isIterableArray } from '../../helpers/utils';

const SelectLanguage = ({ active, onSelected }) => {
  const { t, i18n } = useTranslation();
  const {
    config: { isDark, language }
  } = useContext(AppContext);

  const [value, setValue] = useState(active ?? language);
  const [options, setOptions] = useState([]);

  const handleSelect = selectedOption => {
    setValue(selectedOption.value);
    i18n.changeLanguage(selectedOption.value);
    onSelected(selectedOption.value);
  };

  useEffect(() => {
    if (isIterableArray(countries)) {
      const uniqueLanguages = new Set();
      const options = countries
        .filter(country => country.language && country.code2)
        .reduce((acc, country) => {
          if (
            !uniqueLanguages.has(country.language) &&
            Object.prototype.hasOwnProperty.call(country, 'supported') &&
            country.supported
          ) {
            uniqueLanguages.add(country.language);
            acc.push({
              value: country.code2,
              label: country.language
            });
          }
          return acc;
        }, []);

      setOptions(options);
    }
  }, [t, countries]);

  const customStyles = {
    singleValue: (provided, state) => {
      const color = isDark ? grays['300'] : grays['900'];
      return { ...provided, color };
    }
  };

  return (
    <Select
      styles={customStyles}
      closeMenuOnSelect={true}
      options={options}
      placeholder={t('Language')}
      classNamePrefix="react-select"
      value={options.find(option => option.value === value)}
      onChange={handleSelect}
    />
  );
};

SelectLanguage.propTypes = {
  onSelected: PropTypes.func,
  active: PropTypes.object
};

SelectLanguage.defaultProps = {
  onSelected: () => {}
};

export default SelectLanguage;
