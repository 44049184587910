import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Loader from '../../common/Loader';
import { Box } from '@mui/material';
import ActionButtons from './components/ActionButtons';
import APIResponseViewer from '../../../helpers/APIResponseViewer';
import LocalizationSelection from './components/LocalizationSelection';
import ThemeSelection from './components/ThemeSelection';
import { connect } from 'react-redux';
import { settingsActions } from '../../../redux/actions';
import PropTypes from 'prop-types';
import SelectTimezone from '../../../helpers/SelectTimezone';
import TimezoneSelection from './components/TimezoneSelection';

const General = ({ changedSettings, settingsActions }) => {
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState(null);

  const getSettingObject = key => {
    return data?.find(item => item.key === key);
  };

  const getSettings = () => {
    axios({
      url: `/settings`,
      method: 'GET'
    })
      .then(function (res) {
        if (res.data.result) {
          setData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };
  const setSettings = () => {
    setDataLoading(true);
    axios({
      url: `/settings`,
      method: 'PUT',
      data: changedSettings
    })
      .then(function (res) {
        if (res.data.result) {
          setData(res.data.result);
        }
        //settingsActions({ type: 'changedSettingsSaved', payload: true });

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  return dataLoading ? (
    <Loader width={'100px'} />
  ) : (
    <>
      <Box mb={3}>
        <Row>
          <Col></Col>
          <Col xs={'auto'}>
            <ActionButtons onSave={setSettings} />
          </Col>
        </Row>
      </Box>
      <Box mb={2}>
        <Row>
          <Col md={'3'}>
            <ThemeSelection setting={getSettingObject('theme_dark')} />
          </Col>
        </Row>
      </Box>
      <Row>
        {/*<Col xs={'3'}>
          <LanguageSelection setting={getSettingObject('language')} />
        </Col>*/}
        <Col md={'12'}>
          <LocalizationSelection setting={getSettingObject('localization')} />
        </Col>
      </Row>
      <Row>
        <Col md={'3'}>
          <TimezoneSelection setting={getSettingObject('timezone')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <APIResponseViewer data={data} />
        </Col>
      </Row>
    </>
  );
};

General.propTypes = {
  changedSettings: PropTypes.object,
  settingsActions: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    changedSettings: state.settings.changedSettings,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    settingsActions: content => dispatch(settingsActions(content))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(General);
