import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import { Outlet } from 'react-router-dom';
import LogoAnimation from '../components/common/LogoAnimation';

const AuthEmptyLayout = () => (
  <Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={10} className="col-xxl-5">
        <LogoAnimation
          style={{
            width: '40%',
            overflow: 'hidden',
            outline: 'none'
          }}
        />
        <Outlet />
      </Col>
    </Row>
  </Section>
);

export default AuthEmptyLayout;
