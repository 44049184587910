import React, { Fragment, useState } from 'react';
import SimpleFormV2 from '../../../helpers/SimpleForm/SimpleFormV2';
import { t } from 'i18next';
import { Card, Col, Row } from 'react-bootstrap';
import axios from 'axios';

const Create = () => {
  const [dataLoading, setDataLoading] = useState(false);
  const formData = {
    locale: 'de-DE',
    language: 'de-DE'
  };

  const formOptions = [
    [
      {
        col: { md: 3 },
        name: 'name',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Name'),
        label: t('Name')
      },
      {
        col: { md: 3 },
        name: 'email',
        elementType: 'input',
        type: 'email',
        required: false,
        placeholder: t('email'),
        label: t('email')
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'locale',
        elementType: 'select',
        disabled: false,
        required: false,
        placeholder: 'locale',
        label: t('locale'),
        value: formData.locale,
        options: [
          { value: 'de-DE', label: 'Germany' },
          { value: 'tr-TR', label: 'Türkiye' }
        ]
      },
      {
        col: { md: 3 },
        name: 'language',
        elementType: 'select',
        disabled: false,
        required: false,
        placeholder: 'language',
        label: t('language'),
        value: formData.language,
        options: [
          { value: 'de-DE', label: 'german' },
          { value: 'tr-TR', label: 'turkish' }
        ]
      }
    ]
  ];

  const handleSubmit = data => {
    setDataLoading(true);
    axios({
      url: `/ms/organization`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    })
      .then(function (res) {
        if (res.data.result) {
          //setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row className="justify-content-between align-items-center">
                <Col md>
                  <Row className={'align-items-center '}>
                    <Col className={'col-auto'}>
                      <h5 className="">{t('Create')}</h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <SimpleFormV2
                options={formOptions}
                mode={'create'}
                handleSubmit={handleSubmit}
                formData={formData}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Create;
