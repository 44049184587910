import React from 'react';
import Setting from './components/Setting';

export default {
  name: 'Organization',
  label: 'Organization',
  labelDisable: true,
  settings: [
    {
      id: 'organization',
      name: 'myOrganization',
      component: <Setting />
    }
  ],
  children: []
};
