import React, { useEffect, useState } from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import Loader from '../../../../components/common/Loader';
import SelectUser from '../../../User/components/SelectUser';
import PropTypes from 'prop-types';
import axios from 'axios';

const AddUserModal = ({ show, setModalVisible, organizationId, onSuccess }) => {
  const isDisabled = false;
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  const postRequest = () => {
    if (userId) {
      setLoading(true);
      axios({
        url: `/ms/organization/assignUser/${organizationId}`,
        method: 'PUT',
        data: { user_id: userId }
      })
        .then(function (res) {
          setLoading(false);
          setModal(!modal);
          onSuccess(true);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    console.log('users');
  }, []);

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('Add user')}
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => setModalVisible(!show)}
        />
      </Modal.Header>
      <Modal.Body className="p-4">
        {loading ? (
          <Loader />
        ) : (
          <SelectUser onSelect={value => setUserId(value)} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'falcon-default'}
          onClick={() => {
            setModalVisible(!show);
          }}
        >
          {t('close')}
        </Button>
        <Button
          variant={'falcon-success'}
          onClick={postRequest}
          disabled={isDisabled}
        >
          {t('create')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddUserModal.propTypes = {
  show: PropTypes.bool,
  setModalVisible: PropTypes.func,
  organizationId: PropTypes.string
};

AddUserModal.defaultProps = {
  show: false,
  setModalVisible: () => {}
};

export default React.memo(AddUserModal);
