import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../../../../wizard/WizardInput';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { setRegistration } from '../../../../../redux/actions';
import { connect } from 'react-redux';
import { AuthWizardContext } from '../../../../../context/Context';
import { useTranslation } from 'react-i18next';

const AccountForm = ({ register, errors, watch }) => {
  const { user } = useContext(AuthWizardContext);
  const { t } = useTranslation();

  return (
    <>
      <Row className="g-2 mb-3">
        <WizardInput
          type="password"
          errors={errors}
          label={t('common:capitalize', { value: t('auth:password') })}
          name="password"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            autoComplete: 'new-password',
            ...register('password', {
              required: t('common:isRequired'),
              pattern: {
                value: /([a-zA-Z]).{7,}/,
                message: t('auth:passwordValidationMessage')
              },
              minLength: {
                value: 2,
                message: t('auth:passwordMinLengthMessage')
              }
            })
          }}
        />
        <WizardInput
          type="password"
          errors={errors}
          label={t('common:capitalize', { value: t('auth:confirmPassword') })}
          name="confirmPassword"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            autoComplete: 'new-password',
            ...register('confirmPassword', {
              required: t('common:isRequired'),
              validate: value =>
                value === watch('password') || t('auth:passwordNotMatchMessage')
            })
          }}
        />
      </Row>
    </>
  );
};

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default AccountForm;
