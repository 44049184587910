import React, { useEffect, useState } from 'react';
import { AuthContext } from '../context/Context';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MainLoader from '../components/common/MainLoader';
import { action, setLogin, setLogout } from '../redux/actions';

const AuthProvider = ({
  mainloader,
  children,
  authentication,
  setLogin,
  setLogout,
  setOrganization
}) => {
  const [auth, setAuth] = useState({ ...authentication });
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setAuth(authentication);
  }, [authentication]);

  const login = (formData, callbackUrl = '/') => {
    setProcessing(true);
    return axios({
      url: '/auth/login',
      method: 'POST',
      data: formData
    })
      .then(async function (res) {
        if (res.data.result.access_token) {
          let options = { path: '/' };
          if (formData.remember) {
            let date = new Date();
            let expiresAt = 60 * 24;
            date.setTime(date.getTime() + expiresAt * 60 * 1000);
            options.expires = date;
          }

          await setLogin({
            isAuthenticated: true,
            tokenType: res.data.result.token_type,
            token: res.data.result.access_token,
            email: formData.email,
            user: res.data.result.user,
            cookie_options: options
          });

          if (res.data.result.organization) {
            await setOrganization(
              'setOrganization',
              res.data.result.organization
            );
          }

          if (
            res.data.result.assignedOrganizations &&
            res.data.result.assignedOrganizations.length > 1
          ) {
            await setOrganization(
              'setAssignedOrganizations',
              res.data.result.assignedOrganizations
            );

            callbackUrl = '/auth/launcher';
          } else if (
            res.data.result.assignedOrganizations &&
            res.data.result.assignedOrganizations.length === 0
          ) {
            callbackUrl = '/auth/organization/create';
          }

          axios.defaults.headers.common[
            'Authorization'
          ] = `${res.data.result.token_type} ${res.data.result.access_token}`;

          //@toDO: launcher
          axios.defaults.headers.common[
            'app-organizationId'
          ] = `41e03707-5f09-4e6d-b6eb-14c88a83a0b9`;

          navigate(callbackUrl);
          setProcessing(false);
        }
      })
      .catch(error => {
        console.error(error);
        setProcessing(false);
      });
  };

  const logout = (callbackUrl = '/auth/login') => {
    setProcessing(true);
    return axios({
      url: '/auth/logout',
      method: 'GET'
    })
      .then(() => {
        setLogout();
        setOrganization('reset');
        setTimeout(() => setProcessing(false), 1300);
      })
      .catch(() => {
        window.location.href = callbackUrl;
      });
  };

  let value = { auth, login, logout };

  useEffect(() => {
    if (processing !== mainloader) setProcessing(mainloader);
  }, [mainloader]);

  return (
    <AuthContext.Provider value={value}>
      {processing ? <MainLoader /> : children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  mainloader: PropTypes.bool,
  children: PropTypes.node.isRequired,
  authentication: PropTypes.object,
  setOrganization: PropTypes.func,
  setLogin: PropTypes.func,
  setLogout: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    authentication: state.auth,
    mainloader: state.spinner.mainloader,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setOrganization: (type, content) => dispatch(action(type, content)),
    setLogin: content => dispatch(setLogin(content)),
    setLogout: () => {
      dispatch(setLogout());
      dispatch({ type: 'resetOrganization' });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
