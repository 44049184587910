let InitalState = {};

const settingsReducer = (state = InitalState, action) => {
  switch (action.type) {
    case 'setChangedSettings':
      return {
        ...state,
        changedSettings: { ...state.changedSettings, ...action.payload }
      };
    case 'changedSettingsSaved': {
      return {
        ...state
      };
    }
    default:
      return state;
  }
};

export default settingsReducer;
