import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import FalconCloseButton from '../../components/common/FalconCloseButton';
import LottiePlayer from '../../components/common/LottiePlayer';
import animationData from '../../components/wizard/lottie/warning-light.json';
import { t } from 'i18next';
import Flex from '../../components/common/Flex';
import DateFormGroup from './DateFormGroup';
import SelectFormGroup from './SelectFormGroup';
import SwitchFormGroup from './SwitchFormGroup';
import InputFormGroup from './InputFormGroup';
import UploadForm from './UploadForm';
import SimpleTable from './SimpleTable';
import CurrencyInput from '../../components/common/CurrencyInput';
import TinymceFormGroup from './TinymceFormGroup';
import TextareaFormGroup from './TextareaFormGroup';

const SimpleFormV2 = ({
  formData,
  options,
  mode,
  allDisabled,
  handleSubmit,
  submitButtonName,
  showDelete,
  onDeleteClick,
  onChange,
  hideSubmitButton
}) => {
  let [fData, setFormData] = useState(formData ?? {});
  const [changedData, setChangedData] = useState({});
  const [validated, setValidated] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const submitBtnName = mode === 'create' ? 'create' : 'save';
  const handleChange = event => {
    const { name, value, checked, type } = event.target;
    const updatedFormData = { ...changedData };

    // Update the formData object with the new input value
    updatedFormData[name] =
      type === 'checkbox' || type === 'radio' ? checked : value;

    setChangedData(updatedFormData);
    onChange(updatedFormData);
    setFormData(prevFormData => ({ ...prevFormData, ...updatedFormData }));
  };

  const handleArrayChange = (event, fieldName, object, objectIndex) => {
    const { name, value, checked, type } = event.target;
    const updatedFormData = { ...changedData };

    if (!updatedFormData[fieldName]) updatedFormData[fieldName] = [];

    const foundObject = updatedFormData[fieldName].find(
      item => item.id === object.id
    );

    if (!foundObject) {
      let data = { ...object };
      data[name] = type === 'checkbox' || type === 'radio' ? checked : value;
      updatedFormData[fieldName][objectIndex] = data;
    } else {
      const foundObjectIndex = updatedFormData[fieldName].findIndex(
        item => item.id === object.id
      );
      updatedFormData[fieldName][foundObjectIndex][name] =
        type === 'checkbox' || type === 'radio' ? checked : value;
    }

    setChangedData(updatedFormData);
    onChange(updatedFormData);
    setFormData(prevFormData => ({ ...prevFormData, ...updatedFormData }));
  };

  const handleChangeDate = event => {
    const { name, value } = event;
    const updatedFormData = { ...changedData };

    // Update the formData object with the new input value
    updatedFormData[name] = value ? dayjs(value).format('YYYY-MM-DD') : null;

    setChangedData(updatedFormData);
    onChange(updatedFormData);
    setFormData(prevFormData => ({ ...prevFormData, ...updatedFormData }));
  };

  const handleChangeCurrency = event => {
    const { name, value } = event;
    const updatedFormData = { ...changedData };

    // Update the formData object with the new input value
    updatedFormData[name] = value;

    setChangedData(updatedFormData);
    onChange(updatedFormData);
    setFormData(prevFormData => ({ ...prevFormData, ...updatedFormData }));
  };

  const handleChangeUpload = files => {
    files.map((file, index) => {
      console.log(file);
    });
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    // Log the formData object to see all the form data
    setValidated(true);

    if (form.checkValidity()) {
      if (mode === 'create') handleSubmit(fData);
      if (mode === 'edit') handleSubmit(changedData);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
        <Flex direction="row-reverse">
          <Row className="mb-3">
            <Col>
              {showDelete && (
                <Button
                  variant="danger"
                  className="me-2"
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
              {!hideSubmitButton && (
                <Button
                  variant="falcon-success"
                  type="submit"
                  /*disabled={
                    mode === 'edit'
                      ? Object.keys(changedData).length <= 0
                      : false
                  }*/
                >
                  {t(submitButtonName ?? submitBtnName)}
                </Button>
              )}
            </Col>
          </Row>
        </Flex>
        {options.map((section, index) => {
          const { hideSection } = section;
          return (
            !hideSection && (
              <Row className="mb-3 gap-1" key={`formRow-${index}`}>
                {section.map((field, fieldIndex) => {
                  const { name, elementType, hideElement } = field;
                  let value =
                    elementType === 'switch' ? field.checked : field.value;
                  if (
                    Object.prototype.hasOwnProperty.call(changedData, name) &&
                    elementType !== 'array'
                  ) {
                    value = changedData[name];
                  } else if (
                    Object.prototype.hasOwnProperty.call(changedData, name) &&
                    elementType === 'array'
                  ) {
                    value.map((item, valueIndex) => {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          changedData[name],
                          valueIndex
                        )
                      ) {
                        value[valueIndex] = changedData[name][valueIndex];
                      }
                    });
                  }

                  if (!hideElement) {
                    if (elementType === 'input') {
                      return (
                        <InputFormGroup
                          {...field}
                          value={value}
                          handleChange={handleChange}
                          key={`formElement-${index}-${fieldIndex}`}
                        />
                      );
                    }

                    if (elementType === 'textarea') {
                      return (
                        <TextareaFormGroup
                          {...field}
                          value={value}
                          handleChange={handleChange}
                          key={`formElement-${index}-${fieldIndex}`}
                        />
                      );
                    }

                    if (elementType === 'switch') {
                      return (
                        <SwitchFormGroup
                          {...field}
                          checked={value}
                          handleChange={handleChange}
                          key={`formElement-${index}-${fieldIndex}`}
                        />
                      );
                    }

                    if (elementType === 'tinymce') {
                      return (
                        <TinymceFormGroup
                          {...field}
                          key={`formElement-TinymceEditor-${index}-${fieldIndex}`}
                          value={field.value}
                          handleChange={value =>
                            handleChangeCurrency({ name: field.name, value })
                          }
                        />
                      );
                    }

                    if (elementType === 'select') {
                      return (
                        <SelectFormGroup
                          {...field}
                          value={value}
                          handleChange={handleChange}
                          key={`formElement-${index}-${fieldIndex}`}
                        />
                      );
                    }

                    if (elementType === 'date') {
                      return (
                        <DateFormGroup
                          {...field}
                          value={value}
                          handleChange={value =>
                            handleChangeDate({ name: field.name, value })
                          }
                          key={`formElement-${index}-${fieldIndex}`}
                        />
                      );
                    }

                    if (elementType === 'upload') {
                      return (
                        <UploadForm
                          {...field}
                          value={value}
                          handleChange={field.handleChange}
                          key={`formElement-${index}-${fieldIndex}`}
                        />
                      );
                    }

                    if (elementType === 'custom') {
                      return (
                        <Form.Group
                          as={Col}
                          {...field.col}
                          className={'h-100'}
                          controlId={name}
                          key={`formElement-${index}-${fieldIndex}`}
                        >
                          {field.element}
                        </Form.Group>
                      );
                    }

                    if (elementType === 'currency') {
                      return (
                        <Form.Group
                          as={Col}
                          {...field.col}
                          className={'h-100'}
                          controlId={name}
                          key={`formElement-${index}-${fieldIndex}`}
                        >
                          <CurrencyInput
                            {...field}
                            hasLabel
                            onChange={(formatted, typedIn, simpleNumber) =>
                              handleChangeCurrency({
                                name: field.name,
                                value: simpleNumber.number
                              })
                            }
                          />
                        </Form.Group>
                      );
                    }

                    if (elementType === 'array') {
                      return (
                        <>
                          <h4>{field.label}</h4>
                          {value.map((object, objectIndex) => {
                            return (
                              <RenderForm
                                key={`formRender-${index}-${fieldIndex}-${objectIndex}`}
                                formIndex={objectIndex}
                                options={field.objectOptions}
                                changedData={changedData}
                                object={object}
                                handleChange={event =>
                                  handleArrayChange(
                                    event,
                                    name,
                                    object,
                                    objectIndex
                                  )
                                }
                                handleChangeDate={handleChangeDate}
                                handleChangeUpload={handleChangeUpload}
                              />
                            );
                          })}
                        </>
                      );
                    }

                    if (elementType === 'table') {
                      return (
                        <div key={`formTable-${index}-${fieldIndex}`}>
                          <h4>{field.label}</h4>
                          <SimpleTable
                            key={`formTable-${index}-${fieldIndex}`}
                            columns={field?.columns}
                            addAction={field?.addAction}
                            detailAction={field?.detailAction}
                            onAddHandle={field?.onAddClicked}
                            onSyncHandle={field?.onSyncClicked}
                            checkboxSelection={field?.checkbox}
                            rows={field?.rows ?? []}
                          />
                        </div>
                      );
                    }
                  }

                  return null;
                })}
              </Row>
            )
          );
        })}
      </Form>
      <DeleteModal
        modal={deleteModal}
        setModal={setDeleteModal}
        onClick={onDeleteClick}
      />
    </>
  );
};

SimpleFormV2.propTypes = {
  formData: PropTypes.object,
  mode: PropTypes.oneOf(['edit', 'create', 'readOnly']),
  allDisabled: PropTypes.bool,
  showDelete: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  handleSubmit: PropTypes.func,
  submitButtonName: PropTypes.string,
  onDeleteClick: PropTypes.func,
  onChange: PropTypes.func
};

SimpleFormV2.defaultProps = {
  mode: 'edit',
  allDisabled: false,
  showDelete: false,
  hideSubmitButton: false,
  options: null,
  handleSubmit: () => {},
  onDeleteClick: () => {},
  onChange: () => {}
};

const RenderForm = ({
  formIndex,
  options,
  object,
  changedData,
  handleChange,
  handleChangeDate
}) => {
  formIndex = formIndex ?? 0;
  return (
    <>
      {options.map((section, optionIndex) => (
        <Row className="mb-3" key={`formRow-${formIndex}-${optionIndex}`}>
          {section.map((field, fieldIndex) => {
            const { name, elementType } = field;
            if (object) {
              if (elementType === 'switch') {
                field.checked = object[name] === 1;
              } else field.value = object[name];
            }
            let value = elementType === 'switch' ? field.checked : field.value;
            if (
              Object.prototype.hasOwnProperty.call(changedData, name) &&
              elementType !== 'array'
            ) {
              value = changedData[name];
            } else if (
              Object.prototype.hasOwnProperty.call(changedData, name) &&
              elementType === 'array'
            ) {
              value = [...value, ...changedData[name]];
            }

            if (elementType === 'input') {
              return (
                <InputFormGroup
                  {...field}
                  value={value}
                  handleChange={handleChange}
                  key={`formElement-${formIndex}-${optionIndex}-${fieldIndex}`}
                />
              );
            }

            if (elementType === 'switch') {
              return (
                <SwitchFormGroup
                  {...field}
                  checked={value}
                  handleChange={handleChange}
                  key={`formElement-${formIndex}-${optionIndex}-${fieldIndex}`}
                />
              );
            }

            if (elementType === 'select') {
              return (
                <SelectFormGroup
                  {...field}
                  value={value}
                  handleChange={handleChange}
                  key={`formElement-${formIndex}-${optionIndex}-${fieldIndex}`}
                />
              );
            }

            if (elementType === 'date') {
              return (
                <DateFormGroup
                  {...field}
                  value={value}
                  handleChange={value =>
                    handleChangeDate({ name: field.name, value })
                  }
                  key={`formElement-${formIndex}-${optionIndex}-${fieldIndex}`}
                />
              );
            }

            if (elementType === 'upload') {
              return (
                <UploadForm
                  {...field}
                  value={value}
                  handleChange={field.onChange}
                  key={`formElement-${formIndex}-${optionIndex}-${fieldIndex}`}
                />
              );
            }

            if (elementType === 'custom') {
              return (
                <Form.Group
                  as={Col}
                  {...field.col}
                  className={'h-100'}
                  controlId={name}
                  key={`formElement-${formIndex}-${optionIndex}-${fieldIndex}`}
                >
                  {field.element(field)}
                </Form.Group>
              );
            }
            return null;
          })}
        </Row>
      ))}
    </>
  );
};

function DeleteModal({ modal, setModal, onClick }) {
  return (
    <Modal show={modal} centered dialogClassName="wizard-modal">
      <Modal.Body className="p-4">
        <FalconCloseButton
          size="sm"
          className="position-absolute top-0 end-0 me-2 mt-2"
          onClick={() => setModal(!modal)}
        />
        <Flex justifyContent="center" alignItems="center">
          <LottiePlayer
            animationData={animationData}
            loop={true}
            style={{ width: '100px' }}
          />
          <p className="mb-0 flex-1">{t('Are you sure about deleting?')}</p>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Button
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setModal(!modal);
            }}
          >
            {t('abort')}
          </Button>
          <Button
            variant="danger"
            className="me-2"
            onClick={() => {
              setModal(!modal);
              onClick(true);
            }}
          >
            {t('delete')}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

DeleteModal.propTypes = {
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  onClick: PropTypes.func
};

export default SimpleFormV2;
