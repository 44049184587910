import React from 'react';
import { Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SelectFormGroup = props => {
  const {
    col,
    name,
    disabled = false,
    placeholder,
    label,
    value,
    options,
    required,
    handleChange
  } = props;

  return (
    <Form.Group as={Col} {...col} controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        aria-label={name}
        placeholder={placeholder}
        name={name}
        defaultValue={value}
        disabled={disabled}
        required={required}
        onChange={handleChange}
      >
        {options.map((option, optionIndex) => (
          <option
            value={option.value}
            key={`formElement-select-options-${optionIndex}`}
          >
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

SelectFormGroup.propTypes = {
  col: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
      ])
    })
  ),
  required: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};

export default SelectFormGroup;
