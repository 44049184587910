import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Loader from '../../../components/common/Loader';
import UsersTable from './UsersTable';
import { useNavigate } from 'react-router-dom';

const Overview = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [modal, setModal] = useState(false);
  const [requested, setRequested] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  let [data, setData] = useState([]);
  let [mode, setMode] = useState('create');
  let [row, setRow] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRefresh = () => {
    setDataLoading(true);
    getContacts();
  };

  const getContacts = () => {
    setRequested(true);
    axios({
      url: '/ms/user',
      method: 'GET'
    })
      .then(function (res) {
        if (res.data.result) {
          setData(res.data.result);
        } else if (!res.data.result) {
          //setSub(false);
        }
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    if (!requested) getContacts();
  });

  return (
    <UsersTable
      rows={data}
      loading={dataLoading}
      onAddClicked={() => setModal(true)}
      onSnycClicked={handleRefresh}
      onRowEditClicked={row => {
        setRow(row);
        navigate(`/user/${row.user_id}`);
      }}
    />
  );
};

export default Overview;
