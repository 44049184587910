import DataGridTable from '../DataGridTable';
import React, { useEffect, useState } from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import IconButton from '../../components/common/IconButton';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import Loader from '../../components/common/Loader';
import SelectUser from '../../modules/User/components/SelectUser';

const SimpleTable = ({
  rows,
  columns,
  addAction,
  detailAction,
  checkboxSelection,
  onAddHandle,
  onSyncHandle
}) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [addActionModal, setAddActionModal] = useState(false);
  const [detailActionModal, setDetailActionModal] = useState(false);
  const navigate = useNavigate();
  const onAdd = event => {
    onAddHandle(event);
    if (addAction && addAction.type === 'href') {
      navigate(addAction.href);
    }
    if (addAction && addAction.type === 'modal') {
      setAddActionModal(!addActionModal);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ marginBottom: 20 }}>
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
          className="me-2"
          onClick={onAdd}
        />
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="arrows-rotate"
          transform="shrink-3"
          className="me-2"
          onClick={onSyncHandle}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {}, []);

  return (
    <>
      <DataGridTable
        columns={columns}
        rows={rows}
        loading={tableLoading}
        checkboxSelection={checkboxSelection}
        onRowClick={(data, event) => console.log(data)}
        selectedRowCount={(data, event) => console.log(data)}
        pageSizeOptions={[5, 10, 25]}
        addComponents={{
          toolbar: CustomToolbar
        }}
      />

      {detailAction && detailAction.type === 'modal' && (
        <TableModal
          body={detailAction.body}
          footer={detailAction.footer}
          show={detailActionModal}
          setShow={setDetailActionModal}
        />
      )}
    </>
  );
};

SimpleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  checkboxSelection: PropTypes.bool,
  onAddHandle: PropTypes.func,
  onSyncHandle: PropTypes.func,
  addlAction: PropTypes.object,
  detailAction: PropTypes.object
};

SimpleTable.propTypes = {
  columns: [],
  rows: [],
  checkboxSelection: true,
  onAddHandle: () => {},
  onSyncHandle: () => {}
};

const TableModal = ({ body, footer, show, setShow }) => {
  return (
    <Modal show={show} centered dialogClassName="wizard-modal">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{t('')}</Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => setShow(!show)}
        />
      </Modal.Header>
      <Modal.Body className="p-4">{body}</Modal.Body>
      <Modal.Footer>
        <>
          <Button variant={'falcon-default'} onClick={() => setShow(!show)}>
            {t('close')}
          </Button>
          {footer ?? null}
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default SimpleTable;
