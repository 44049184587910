import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import { Button, Card, CloseButton, Col, Modal, Row } from 'react-bootstrap';

import i18next, { t } from 'i18next';
import IconButton from '../../../components/common/IconButton';
import APIResponseViewer from '../../../helpers/APIResponseViewer';
import SimpleFormV2 from '../../../helpers/SimpleForm/SimpleFormV2';

import SimpleBadge from '../../../helpers/SimpleBadge';
import DetailUserModal from './modals/DetailUserModal';
import AddUserModal from './modals/AddUserModal';
import AddAddressModal from './modals/AddAddressModal';
import SelectUser from '../../User/components/SelectUser';
import Avatar from '../../../components/common/Avatar';
import avatarImg from '../../../assets/img/team/avatar.png';
import AddExtensionModal from './modals/AddExtensionModal';
import { getModulesRequest } from '../../index';
import DetailExtensionModal from './modals/DetailExtensionModal';

function Organization() {
  const [formData, setFormData] = useState({});
  const [requested, setRequested] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [extensionId, setExtensionId] = useState(null);
  const [addUserModal, setAddUserModal] = useState(false);
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [detailExtensionModal, setDetailExtensionModal] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [addExtensionModal, setAddExtensionModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [modules, setModules] = useState([]);

  const [avatarLight, setAvatarLight] = useState(
    formData.logo_light_url ?? avatarImg
  );
  const [avatarDark, setAvatarDark] = useState(
    formData.logo_dark_url ?? avatarImg
  );

  const formOptions = [
    [
      {
        col: { md: 3 },
        name: 'name',
        elementType: 'input',
        type: 'text',
        disabled: false,
        placeholder: 'Name',
        label: t('Name'),
        value: formData.name
      },
      {
        col: { md: 3 },
        name: 'database',
        elementType: 'input',
        type: 'text',
        disabled: true,
        placeholder: 'Database',
        label: t('Database'),
        value: formData.database
      },
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'active',
        required: false,
        disabled: false,
        placeholder: 'active',
        label: t('active'),
        checked: formData.active == 1
      },
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'public',
        required: false,
        disabled: false,
        placeholder: 'public',
        label: t('public'),
        checked: formData.public == 1
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'email',
        elementType: 'input',
        type: 'text',
        placeholder: 'E-Mail',
        label: t('E-Mail'),
        value: formData.email
      },
      {
        col: { md: 3 },
        name: 'phonenumber',
        elementType: 'input',
        type: 'text',
        placeholder: t('Phonenumber'),
        label: t('Phonenumber'),
        value: formData.phonenumber
      },
      {
        col: { md: 3 },
        name: 'fax',
        elementType: 'input',
        type: 'text',
        placeholder: t('Fax'),
        label: t('Fax'),
        value: formData.fax
      }
    ],
    [
      {
        col: { md: 4 },
        name: 'logo',
        elementType: 'upload',
        accept: 'image/*',
        disabled: false,
        required: false,
        label: t('light logo'),
        handleChange: files => {
          files.map((file, index) => {
            setAvatarLight(file.base64);
            return axios({
              url: `/ms/organization/upload/organizationlogolight`,
              method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              data: { file: file.file }
            });
          });
        }
      },
      {
        col: { md: 4 },
        name: 'logo',
        elementType: 'custom',
        element: <Avatar src={avatarLight} rounded="0" size="4xl" />
      }
    ],
    [
      {
        col: { md: 4 },
        name: 'logo',
        elementType: 'upload',
        accept: 'image/*',
        disabled: false,
        required: false,
        label: t('dark logo'),
        handleChange: files => {
          files.map((file, index) => {
            setAvatarDark(file.base64);
            return axios({
              url: `/ms/organization/upload/organizationlogodark`,
              method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              data: { file: file.file }
            });
          });
        }
      },
      {
        col: { md: 4 },
        name: 'logo',
        elementType: 'custom',
        element: <Avatar src={avatarDark} rounded="0" size="4xl" />
      }
    ],
    [
      {
        col: { md: 12 },
        name: 'addresses',
        elementType: 'table',
        label: t('Addresses'),
        checkbox: false,
        onAddClicked: () => setAddAddressModal(true),
        onSyncClicked: () => getData(),
        rows: formData.addresses,
        columns: [
          {
            field: 'street',
            headerName: t('Street'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'house_number',
            headerName: t('Housenumber'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'zip',
            headerName: t('zip'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'city',
            headerName: t('City'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'state',
            headerName: t('State'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'country',
            headerName: t('Country'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'is_standard',
            headerName: t('Standart'),
            flex: 0.1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => {
              const { is_standard } = params.row;
              return (
                <SimpleBadge
                  value={is_standard}
                  options={{
                    0: {
                      className: 'danger',
                      icon: 'xmark'
                    },
                    1: {
                      className: 'success',
                      icon: 'check'
                    }
                  }}
                />
              );
            }
          },
          {
            field: ' ',
            headerName: ' ',
            flex: 0.1,
            minWidth: 100,
            type: 'string',
            align: 'center',
            headerAlign: 'center',
            renderCell: params => {
              return (
                <>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-right"
                    transform="shrink-3"
                    className="me-2"
                    onClick={() => {
                      //onRowEditClicked(params.row);
                    }}
                  />
                </>
              );
            }
          }
        ]
      }
    ],
    [
      {
        col: { md: 12 },
        name: 'users',
        elementType: 'table',
        label: t('Users'),
        checkbox: false,
        onAddClicked: () => {
          !addUserModal ? setAddUserModal(!addUserModal) : null;
        },
        onSyncClicked: () => getData(),
        rows: formData?.users,
        columns: [
          {
            field: 'firstname',
            headerName: t('Firstname'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'lastname',
            headerName: t('Lastname'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'email',
            headerName: t('E-Mail'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'created_at',
            headerName: t('created_at'),
            type: 'date',
            flex: 1,
            minWidth: 100,
            valueFormatter: params => {
              return i18next.t('intlDateTime', { val: new Date(params.value) });
            }
          },
          {
            field: 'detail',
            headerName: ' ',
            flex: 0.1,
            minWidth: 100,
            type: 'string',
            align: 'center',
            headerAlign: 'center',
            renderCell: params => {
              return (
                <>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-right"
                    transform="shrink-3"
                    className="me-2"
                    onClick={row => {
                      setUserId(params.row.user_id);
                      setDetailUserModal(true);
                      //onRowEditClicked(params.row);
                    }}
                  />
                </>
              );
            }
          }
        ]
      }
    ],
    [
      {
        col: { md: 12 },
        name: 'extensions',
        elementType: 'table',
        label: t('extensions'),
        checkbox: false,
        onAddClicked: () => {
          !addExtensionModal ? setAddExtensionModal(!addExtensionModal) : null;
        },
        onSyncClicked: () => getData(),
        rows: modules,
        columns: [
          {
            field: 'name',
            headerName: t('name'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'installed_at',
            headerName: t('installed_at'),
            type: 'date',
            flex: 1,
            minWidth: 100,
            valueFormatter: params => {
              return i18next.t('intlDateTime', { val: new Date(params.value) });
            }
          },
          {
            field: 'detail',
            headerName: ' ',
            flex: 0.1,
            minWidth: 100,
            type: 'string',
            align: 'center',
            headerAlign: 'center',
            renderCell: params => {
              return (
                <>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-right"
                    transform="shrink-3"
                    className="me-2"
                    onClick={row => {
                      setExtensionId(params.row.extension_id);
                      setDetailExtensionModal(true);
                      //onRowEditClicked(params.row);
                    }}
                  />
                </>
              );
            }
          }
        ]
      }
    ]
    /*[
      {
        col: { md: 12 },
        name: 'locale',
        elementType: 'custom',
        element: <LocalizationSelection setting={formData.locale} />
      },
      {
        col: { md: 6 },
        name: 'language',
        elementType: 'custom',
        element: <LanguageSelection setting={formData.language} />
      }
    ]*/
  ];

  const getData = () => {
    setDataLoading(true);
    setRequested(true);
    axios({
      url: `/ms/organization/${id}`,
      method: 'GET'
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
          res.data.result?.logo_light_url
            ? setAvatarLight(res.data.result?.logo_light_url)
            : null;
          res.data.result?.logo_dark_url
            ? setAvatarDark(res.data.result?.logo_dark_url)
            : null;
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  const getModules = async () => {
    const modules = await getModulesRequest(id);
    if (modules.length > 0) setModules(modules);
  };

  useEffect(() => {
    getData();
    getModules();
  }, []);

  const updateData = changedData => {
    setDataLoading(true);
    axios({
      url: `/ms/organization/${id}`,
      method: 'PUT',
      data: changedData
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  const deleteData = () => {
    setDataLoading(true);
    axios({
      url: `/ms/organization/${id}`,
      method: 'DELETE'
    })
      .then(function (res) {
        navigate(-1);
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <h5>{t('Organization')}</h5>
                </Col>
                <Col xs={'auto'}></Col>
                <Col xs={'auto'}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                    transform="shrink-3"
                    className="me-2"
                    tooltipText={'back'}
                    tooltipPosition={'left'}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <div>
                {dataLoading ? (
                  <Loader width={'100px'} />
                ) : (
                  <>
                    <SimpleFormV2
                      options={formOptions}
                      handleSubmit={updateData}
                      showDelete={true}
                      onDeleteClick={deleteData}
                    />
                    <AddUserModal
                      show={addUserModal}
                      organizationId={id}
                      onSuccess={getData}
                      setModalVisible={setAddUserModal}
                    />
                    <DetailUserModal
                      show={detailUserModal}
                      userId={userId}
                      organizationId={id}
                      onSuccess={getData}
                      setModalVisible={setDetailUserModal}
                    />
                    <AddExtensionModal
                      show={addExtensionModal}
                      organizationId={id}
                      onSuccess={getData}
                      setModalVisible={setAddExtensionModal}
                    />
                    <DetailExtensionModal
                      show={detailExtensionModal}
                      extensionId={extensionId}
                      organizationId={id}
                      onSuccess={getData}
                      setModalVisible={setDetailExtensionModal}
                    />
                    <AddAddressModal
                      show={addAddressModal}
                      organizationId={id}
                      onSuccess={getData}
                      setModalVisible={setAddAddressModal}
                    />
                    {formData && <APIResponseViewer data={formData} />}
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Organization;
