import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const IconButton = ({
  icon,
  iconAlign = 'left',
  iconClassName,
  transform,
  children,
  tooltipText,
  tooltipPosition,
  ...rest
}) => {
  const { t } = useTranslation();

  return tooltipText ? (
    <OverlayTrigger
      key={tooltipText}
      overlay={
        <Tooltip id={'tooltip-iconbutton-' + tooltipText}>
          {t(tooltipText)}
        </Tooltip>
      }
      placement={tooltipPosition}
    >
      <Button {...rest}>
        {iconAlign === 'right' && children}
        <FontAwesomeIcon
          icon={icon}
          className={classNames(iconClassName, {
            'me-1': children && iconAlign === 'left',
            'ms-1': children && iconAlign === 'right'
          })}
          transform={transform}
        />
        {iconAlign === 'left' || iconAlign === 'middle' ? children : false}
      </Button>
    </OverlayTrigger>
  ) : (
    <Button {...rest}>
      {iconAlign === 'right' && children}
      <FontAwesomeIcon
        icon={icon}
        className={classNames(iconClassName, {
          'me-1': children && iconAlign === 'left',
          'ms-1': children && iconAlign === 'right'
        })}
        transform={transform}
      />
      {iconAlign === 'left' || iconAlign === 'middle' ? children : false}
    </Button>
  );
};

IconButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.any,
  iconAlign: PropTypes.oneOf(['left', 'right', 'middle']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltipPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left'])
};

IconButton.defaultProps = {
  tooltipPosition: 'bottom',
  tooltipText: false
};

export default IconButton;
