import React, { useLayoutEffect, useState } from 'react';
import { action, setLogout, setSpinner } from '../../../../redux/actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import Avatar from '../../../../components/common/Avatar';
import { isIterableArray } from '../../../../helpers/utils';
import FalconDropzone from '../../../../components/common/FalconDropzone';
import Flex from '../../../../components/common/Flex';
import cloudUpload from '../../../../assets/img/icons/cloud-upload.svg';
import avatarImg from '../../../../assets/img/team/avatar.png';

const CreateOrganizationContent = ({
  authentication,
  assignedOrganizations,
  setOrganization,
  setSpinner
}) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const [avatar, setAvatar] = useState();

  useLayoutEffect(() => {
    if (!authentication.isAuthenticated) navigate(`/auth/login`);
  }, [authentication]);

  const createOrganizationRequest = data => {
    return axios({
      url: `/ms/organization`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    });
  };

  const assignOrganizationIdToSession = organization_id => {
    console.log(organization_id);
    return axios({
      url: `/session`, // Use organization_id instead of organizationId
      method: 'PUT',
      data: { organization_id }
    });
  };

  const onSubmit = data => {
    setSpinner('mainloader', true);
    createOrganizationRequest(data)
      .then(response => {
        const data = response.data;
        const organization_id = data.result.id;
        assignOrganizationIdToSession(organization_id)
          .then(async () => {
            await setOrganization('setOrganizationId', organization_id);
            setTimeout(() => {
              setSpinner('mainloader', false);
              navigate('/');
            }, 1000);
          })
          .catch(error => {
            console.log(error);

            setSpinner('mainloader', false);
          });
      })
      .catch(error => {
        console.log(error);
        setSpinner('mainloader', false);
      });
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-1 justify-content-center">
        <Col md="auto">
          {isIterableArray(avatar) ? (
            <Avatar
              size="4xl"
              rounded="0"
              src={
                isIterableArray(avatar)
                  ? avatar[0]?.base64 || avatar[0]?.src
                  : ''
              }
            />
          ) : (
            <Avatar size="4xl" rounded="0" name="LOGO" isExact />
          )}
        </Col>
      </Row>
      <Row className="mb-3 ">
        <Col xs={12}>
          <FalconDropzone
            files={avatar}
            onChange={files => {
              setAvatar(files);
              //setValue('profile_picture', files[0]);
              //handleProfilePicture(files[0]);
              //setUser({ ...user, profile_picture: files[0] });
            }}
            multiple={false}
            accept="image/*"
            placeholder={
              <>
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">
                    Upload your organization's logo
                  </p>
                </Flex>
              </>
            }
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          xs="12"
          className="mb-3"
          controlId="organization_name"
        >
          <Form.Label>Name of organization</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="Enter name of your organization"
            isInvalid={!!errors.name}
            {...register('name', {
              required: 'Name field is required',
              pattern: {
                value: /^[a-zA-Z0-9'!`][a-zA-Z0-9'!` ]{2,}$/,
                message:
                  "Input must be at least 3 characters long and can include alphanumeric characters, ', !, and `."
              }
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name && errors.name.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Button type="submit" className={'w-100'}>
        {t('create')}
      </Button>
    </Form>
  );
};

CreateOrganizationContent.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    authentication: state.auth,
    assignedOrganizations: state.organization.assignedOrganizations,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSpinner: (name, value) => dispatch(setSpinner(name, value)),
    setOrganization: (type, content) => dispatch(action(type, content)),
    setLogout: () => dispatch(setLogout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrganizationContent);
