import React from 'react';
import PropTypes from 'prop-types';
import DataGridTable from '../../../helpers/DataGridTable';
import { useTranslation } from 'react-i18next';
import SoftBadge from '../../../components/common/SoftBadge';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridToolbarContainer } from '@mui/x-data-grid';
import IconButton from '../../../components/common/IconButton';
import i18next from 'i18next';
import i18n from 'i18next';

const OrgnizationsTable = ({
  rows,
  onAddClicked,
  onSnycClicked,
  onRowEditClicked,
  loading
}) => {
  const { t } = useTranslation();
  const columns = React.useMemo(() => [
    {
      field: 'name',
      headerName: t('Name'),
      type: 'string',
      flex: 1,
      minWidth: 100
    },
    {
      field: 'database',
      headerName: t('database'),
      type: 'string',
      flex: 1,
      minWidth: 100
    },
    {
      field: 'email',
      headerName: t('Email address'),
      type: 'email',
      flex: 1,
      minWidth: 150
    },

    {
      field: 'updated_at',
      headerName: t('updated_at'),
      type: 'date',
      flex: 1,
      minWidth: 100,
      valueFormatter: params => {
        return i18next.t('intlDateTime', { val: new Date(params.value) });
      }
    },
    {
      field: 'created_at',
      headerName: t('created_at'),
      type: 'date',
      flex: 1,
      minWidth: 100,
      valueFormatter: params => {
        return i18next.t('intlDateTime', { val: new Date(params.value) });
      }
    },
    {
      field: 'active',
      headerName: t('active'),
      flex: 0.1,
      minWidth: 100,
      type: 'singleSelect',
      align: 'center',
      headerAlign: 'center',
      valueOptions: [t('active'), t('inactive')],
      renderCell: params => {
        const { active } = params.row;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: active === 1,
              danger: active === 0
            })}
            className="d-block "
          >
            {active === 1 && t('active')}
            {active === 0 && t('inactive')}
            <FontAwesomeIcon
              icon={classNames({
                check: active === 1,
                ban: active === 0
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      field: 'public',
      headerName: t('public'),
      flex: 0.1,
      minWidth: 100,
      type: 'singleSelect',
      align: 'center',
      headerAlign: 'center',
      valueOptions: [t('public'), t('private')],
      renderCell: params => {
        const isPublic = params.row.public;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: isPublic === 1,
              danger: isPublic === 0
            })}
            className="d-block "
          >
            {isPublic === 1 && t('public')}
            {isPublic === 0 && t('private')}
            <FontAwesomeIcon
              icon={classNames({
                check: isPublic === 1,
                ban: isPublic === 0
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      field: ' ',
      headerName: ' ',
      flex: 0.1,
      minWidth: 100,
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: params => {
        return (
          <>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="arrow-right"
              transform="shrink-3"
              className="me-2"
              tooltipText={'edit'}
              tooltipPosition={'left'}
              onClick={() => {
                onRowEditClicked(params.row);
              }}
            />
          </>
        );
      }
    }
  ]);

  const onAddHandle = value => onAddClicked(value);
  const onSyncHandle = value => onSnycClicked(value);

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ marginBottom: 20 }}>
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
          className="me-2"
          tooltipText={'new'}
          tooltipPosition={'top'}
          onClick={onAddHandle}
        />
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="arrows-rotate"
          transform="shrink-3"
          className="me-2"
          tooltipText={'refresh'}
          tooltipPosition={'top'}
          onClick={onSyncHandle}
        />
      </GridToolbarContainer>
    );
  }

  const handleSelectionModelChange = ids => {
    const selectedIDs = ids;
    const selectedRowsData = selectedIDs.map(id =>
      rows.find(row => row.id === id)
    );
    console.log(selectedIDs);
  };

  return (
    <div style={{ height: 738, width: '100%' }}>
      <DataGridTable
        columns={columns}
        rows={rows}
        loading={loading}
        checkboxSelection={true}
        onRowClick={row => console.log(row)}
        selectedRowCount={(data, event) => console.log(data)}
        onSelectionModelChange={handleSelectionModelChange}
        addComponents={{
          toolbar: CustomToolbar
        }}
      />
    </div>
  );
};

OrgnizationsTable.propTypes = {
  rows: PropTypes.array,
  loading: PropTypes.bool,
  onAddClicked: PropTypes.func,
  onSnycClicked: PropTypes.func,
  onRowEditClicked: PropTypes.func
};

OrgnizationsTable.defaultTypes = {
  rows: {},
  onAddClicked: () => {},
  onSnycClicked: () => {},
  onRowEditClicked: () => {}
};

export default OrgnizationsTable;
