import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { getGrays } from '../../helpers/utils';
import logoloader from '../../assets/img/animated-icons/logo.json';
import LottiePlayer from './LottiePlayer';

const MainLoader = props => {


  const isDark = localStorage.getItem('isDark') === 'true';
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        backgroundColor: isDark
          ? getGrays(isDark)['100']
          : getGrays(isDark)['200'],
        opacity: props.opacity,
        zIndex: 1060,
        position: 'absolute',
        overflow: 'hidden'
      }}
    >
      <Row className="flex-center py-5 h-100 overflow-hidden">
        <Col xs="auto">
          <LottiePlayer
            play
            loop
            animationData={logoloader}
            isCentered
            style={{
              width: '30%',
              overflow: 'hidden',
              outline: 'none'
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

MainLoader.propTypes = { ...Spinner.propTypes };

MainLoader.defaultProps = {
  animation: 'border',
  size: 'lg',
  color: 'primary',
  opactiy: '1'
};

export default MainLoader;
