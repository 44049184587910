import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';

function LottiePlayer(props) {
  const { isCentered, style, width, height, ...rest } = props;
  const updatedStyle = {
    ...style,
    ...(isCentered && { margin: '0px auto' }),
    ...(width && { width }),
    ...(height && { height })
  };

  return <Lottie style={updatedStyle} {...rest} />;
}

LottiePlayer.propTypes = {
  isCentered: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object
};

LottiePlayer.defaultProps = {
  isCentered: false,
  style: {}
};

export default LottiePlayer;
