import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { setChangedSettings } from '../../../../redux/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../../context/Context';

const ThemeSelection = ({ setting, requests, setChangedSettings }) => {
  if (
    typeof setting === 'object' &&
    setting &&
    Object.keys(setting).length > 0
  ) {
    const { t } = useTranslation();
    const { allowedSettings, userSettings, default_value } = setting;
    const { setConfig } = useContext(AppContext);

    const val =
      userSettings && userSettings.value !== null
        ? userSettings.value
        : default_value;

    const [value, setValue] = useState(val);

    const onChange = event => {
      setValue(event.target.value);
      setChangedSettings({ [setting.id]: event.target.value });
    };

    useEffect(() => {
      if (requests?.url === '/settings' && requests?.method === 'put') {
        setConfig('isDark', value === '1');
      }
    }, [requests]);

    return (
      <Form.Group className="mb-3" controlId="settingsForm.ControlThemeSelect">
        <Form.Label>
          {t('capitalize', { value: t('settings:theme') })}
        </Form.Label>
        <Form.Select aria-label="Theme" value={value} onChange={onChange}>
          {allowedSettings.map(allowedSetting => (
            <option key={allowedSetting.id} value={allowedSetting.item_value}>
              {t(`settings:${allowedSetting.caption}`)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    );
  }

  return null;
};

ThemeSelection.propTypes = {
  setting: PropTypes.shape({
    allowedSettings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        caption: PropTypes.string.isRequired,
        item_value: PropTypes.string.isRequired
      })
    ).isRequired,
    userSettings: PropTypes.shape({
      value: PropTypes.string.isRequired
    }),
    default_value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  requests: PropTypes.shape({
    url: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired
  }),
  setChangedSettings: PropTypes.func.isRequired
};

ThemeSelection.defaultProps = {
  setting: null,
  requests: {}
};

const mapStateToProps = (state, ownProps) => {
  return {
    requests: state.requests,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setChangedSettings: content => dispatch(setChangedSettings(content))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSelection);
