import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Error404 = () => {
  const { t } = useTranslation();
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">404</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {t(`The page you're looking for is not found.`)}
        </p>
        <hr />
        <p>
          {t(
            `Make sure the address is correct and that the page hasn't moved. If you think this is a mistake,`
          )}
          <a href="mailto:info@kesify.com" className="ms-1">
            {t(`contact us`)}
          </a>
          .
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          {t(`Take me home`)}
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Error404;
