import manufacturer, {
  getManufacturer
} from '../../Cardealer/data/manufacturer';
import SearchSelection from '../../../helpers/SearchSelection';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';

const SelectUser = ({ onSelect }) => {
  const [data, setData] = useState(null);
  const [loading, setDataLoading] = useState(null);
  const [value, setValue] = useState(null);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      if (response.data.result) {
        return response.data.result.map(user => ({
          value: user.id,
          label: user.email
        }));
      }
      return [];
    } catch (error) {
      console.error('Failed to fetch users:', error);
      // Handle the error appropriately
      return [];
    }
  };
  const getRequest = async () => {
    setDataLoading(true);
    const users = await fetchUsers();
    setData(users);
    setDataLoading(false);
  };

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    onSelect(value?.value);
  }, [value]);

  return (
    !loading &&
    data && (
      <Select
        closeMenuOnSelect={true}
        options={data}
        placeholder="Select..."
        classNamePrefix="react-select"
        value={value}
        onChange={value => setValue(value)}
      />
    )
  );
};

SelectUser.propType = {
  onSelect: PropTypes.func
};

SelectUser.defaultProps = {
  onSelect: () => {}
};
export default SelectUser;
