import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import AppContext from '../../../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PrayerTimeCard = ({ timeName, time, active, icon }) => (
  <Col xs={5} md={3} lg={2} xxl>
    <Card bg={active ? 'soft-success' : null} className="h-100">
      <Card.Body>
        <h1 className="display-4 mb-3 fw-medium text-center text-700 d-none d-xxl-block">
          {icon && <FontAwesomeIcon icon={icon} />} {timeName}
        </h1>
        <h4 className="mb-3 fw-medium text-center text-700 d-none d-sm-block d-xxl-none">
          {icon && <FontAwesomeIcon icon={icon} />} {timeName}
        </h4>
        <span className="mb-3 fw-medium text-center text-700 d-block d-sm-none">
          {icon && <FontAwesomeIcon icon={icon} />} {timeName}
        </span>
        <h1
          className="text-center fw-light d-none d-xxl-block"
          style={{ fontSize: '5rem' }}
        >
          {time}
        </h1>
        <h3 className="text-center fw-medium d-none d-sm-block  d-xxl-none">
          {time}
        </h3>
        <h5 className="text-center fw-medium d-block d-sm-none">{time}</h5>
      </Card.Body>
    </Card>
  </Col>
);

const PrayerTimes = ({ times }) => {
  const [activePrayer, setActivePrayer] = useState('');
  const { setConfig } = useContext(AppContext);

  const parsePrayerTimeToMinutes = useMemo(
    () => time => {
      if (time) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      }
      return null;
    },
    []
  );

  const checkActivePrayer = useCallback(() => {
    const now = new Date();
    const currentTime = now.getHours() * 60 + now.getMinutes();
    let activePrayerName = '';

    for (const prayer of times) {
      if (!prayer.visible) continue;
      if (prayer.ignore) continue;

      const prayerTime = parsePrayerTimeToMinutes(prayer.time);
      const nextPrayerTime = parsePrayerTimeToMinutes(prayer?.nextPrayerTime);
      const activeTill = parsePrayerTimeToMinutes(prayer?.activeTill);

      let notActiveFlag = false;
      if (activeTill && currentTime >= activeTill) {
        notActiveFlag = true;
      }

      if (!notActiveFlag && currentTime >= prayerTime) {
        activePrayerName = prayer.name;
      }
    }

    setActivePrayer(activePrayerName);
  }, [times, parsePrayerTimeToMinutes]);

  useEffect(() => {
    if (times.length > 0) {
      const timerId = setInterval(checkActivePrayer, 60000);
      checkActivePrayer();
      return () => clearInterval(timerId);
    }
  }, [times, checkActivePrayer]);

  useEffect(() => {
    const isLight = ['Sunrise', 'Dhuhr', 'Asr'].includes(activePrayer);
    setConfig('isDark', !isLight);
  }, [activePrayer]);

  return (
    <Row className="justify-content-between gap-2 row-gap-sm-1 gap-lg-0">
      {times.map(
        ({ name, time, icon, visible, label }) =>
          visible && (
            <PrayerTimeCard
              key={`prayer-time-${name}`}
              timeName={label}
              time={time}
              active={activePrayer === name}
            />
          )
      )}
    </Row>
  );
};

PrayerTimes.propTypes = {
  times: PropTypes.array.isRequired
};

export default PrayerTimes;
