import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  Button,
  Col,
  Modal,
  Row,
  CloseButton,
  InputGroup
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import avatarImg from '../../../assets/img/team/avatar.png';
import { isIterableArray } from '../../../helpers/utils';
import AppContext from '../../../context/Context';
import SelectCountry from '../../../helpers/SelectCountry';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import Loader from '../../../components/common/Loader';
import { getCountry } from '../../../data/countries';
import localeHelper from '../../../helpers/locale';

const ModalContactForm = ({
  isOpen,
  onClose,
  onCreated,
  hasLabel,
  mode,
  data
}) => {
  const { t, i18n } = useTranslation();
  let [modal, setModal] = useState(false);
  let [firstname, setFirstname] = useState(
    mode === 'edit' ? data.firstname : ''
  );
  let [lastname, setLastname] = useState(mode === 'edit' ? data.lastname : '');
  let [streetAndHousenumber, setStreetAndHousenumber] = useState(
    mode === 'edit' ? data.street_housenumber : ''
  );
  let [postalCode, setPostalCode] = useState(
    mode === 'edit' ? data.postal_code : ''
  );
  let [city, setCity] = useState(mode === 'edit' ? data.city : '');
  let [country, setCountry] = useState(() => {
    if (mode === 'edit' && data.country) {
      let country = getCountry(data.country, 'name');
      return { value: country.name, label: t(country.name) };
    } else {
      let country = getCountry(i18n.language, 'code2');
      return { value: country.name, label: t(country.name) };
    }
  });
  let [phonenumber, setPhonenumber] = useState(
    mode === 'edit' ? data.phonenumber : ''
  );
  let [birthday, setBirthday] = useState(
    mode === 'edit' && data.birthday
      ? dayjs(data.birthday).format('YYYY-MM-DD')
      : ''
  );
  let [email, setEmail] = useState(mode === 'edit' ? data.email : '');
  let [fax, setFax] = useState(mode === 'edit' ? data.fax : '');
  let [active, setActive] = useState(mode === 'edit' ? data.active : 1);
  let [isDisabled, setIsDisabled] = useState(true);
  const [avatar, setAvatar] = useState([{ src: avatarImg }]);
  let [selectedFile, setSelectedFile] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    config: { isDark }
  } = useContext(AppContext);

  useEffect(() => {
    setModal(isOpen);
    setIsDisabled(!firstname || !lastname);
  }, [firstname, isOpen, lastname]);

  const onCreatedHandle = value => onCreated(value);

  const create = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('street_housenumber', streetAndHousenumber);
    formData.append('postal_code', postalCode);
    formData.append('city', city);
    formData.append('country', country.value);
    formData.append('phonenumber', phonenumber);
    formData.append('fax', fax);
    formData.append('email', email);
    formData.append('birthday', birthday);
    formData.append('active', active);

    axios({
      url: '/contact',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    }).then(function (res) {
      if (res.data.response) {
        onCreatedHandle(true);
        handleClose(false);
      }
    });
  };

  const edit = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('street_housenumber', streetAndHousenumber);
    formData.append('postal_code', postalCode);
    formData.append('city', city);
    formData.append('country', country.value);
    formData.append('phonenumber', phonenumber);
    formData.append('fax', fax);
    formData.append('email', email);
    formData.append('birthday', birthday);
    formData.append('active', active);

    axios({
      url: '/contact/' + data.id + '?_method=PUT',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(function (res) {
      if (res.data.response) {
        onCreatedHandle(true);
        handleClose(false);
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsDisabled(true);
    setLoader(true);
    mode === 'edit' ? edit() : create();
  };

  const handleProfilePicture = event => {
    let file = event.target.files[0];
    setSelectedFile(file);
    const stringFiles = [];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      stringFiles.push({
        //id: uuid(),
        base64: reader.result,
        size: file.size,
        path: file.path,
        type: file.type
      });
      setAvatar(stringFiles);
    };
  };

  const handleClose = () => {
    setModal(false);
    onClose(false);
    setLoader(false);
  };

  return (
    <Modal
      show={modal}
      onHide={handleClose}
      size={mode === 'edit' ? 'md' : 'md'}
      backdrop="static"
      centered={true}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t(mode === 'edit' ? 'Edit contact' : 'Create contact')}
          </Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          {loader ? (
            <Loader />
          ) : (
            <>
              <Row className={'mb-3 justify-content-center'}>
                <Col xs={'auto'}>
                  <div className="avatar avatar-5xl  shadow-sm img-thumbnail rounded-circle">
                    <div className="h-100 w-100 rounded-circle overflow-hidden position-relative">
                      <img
                        src={
                          isIterableArray(avatar)
                            ? avatar[0].base64 || avatar[0].src
                            : ''
                        }
                        width="200"
                        alt=""
                      />
                      <Form.Control
                        className="d-none"
                        id="profile-image"
                        type="file"
                        accept={'.png,.jpg,.jpeg'}
                        multiple={false}
                        onChange={handleProfilePicture}
                      />
                      <Form.Label
                        className="mb-0 overlay-icon d-flex flex-center"
                        htmlFor="profile-image"
                      >
                        <span className="bg-holder overlay overlay-0" />
                        <span
                          className={
                            'z-index-1 text-center fs--1 ' +
                            (isDark ? 'text-dark' : 'text-light')
                          }
                        >
                          <FontAwesomeIcon icon="upload" />
                        </span>
                      </Form.Label>
                    </div>
                  </div>
                </Col>
              </Row>
              {mode === 'edit' ? (
                <Row className={'justify-content-left'}>
                  <Col xs={'auto'}>
                    <Form.Check
                      type="switch"
                      id="active"
                      name="active"
                      label={t('active')}
                      defaultChecked={active}
                      onChange={({ target }) => {
                        setActive(active ? 0 : 1);
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                false
              )}
              <Row className="mb-3">
                <Col xs={6}>
                  <Form.Group>
                    {hasLabel && <Form.Label>{t('Firstname')}</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? t('Firstname') : ''}
                      value={firstname}
                      name="firstname"
                      onChange={({ target }) => setFirstname(target.value)}
                      type="text"
                      autoComplete={'given-name'}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group>
                    {hasLabel && <Form.Label>{t('Lastname')}</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? t('Lastname') : ''}
                      value={lastname}
                      name="lastname"
                      onChange={({ target }) => setLastname(target.value)}
                      type="text"
                      autoComplete={'family-name'}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    {hasLabel && (
                      <Form.Label>{t('Street and Housenumber')}</Form.Label>
                    )}
                    <Form.Control
                      placeholder={!hasLabel ? t('Street and Housenumber') : ''}
                      value={streetAndHousenumber}
                      name="streetAndHousenumber"
                      onChange={({ target }) =>
                        setStreetAndHousenumber(target.value)
                      }
                      type="text"
                      autoComplete={'street-address'}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={4}>
                  <Form.Group>
                    {hasLabel && <Form.Label>{t('ZIP')}</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? t('Postal code') : ''}
                      value={postalCode}
                      name="postalCode"
                      onChange={({ target }) => setPostalCode(target.value)}
                      type="text"
                      pattern={'[0-9]{5}'}
                      inputMode={'numeric'}
                      autoComplete={'postal-code'}
                    />
                  </Form.Group>
                </Col>
                <Col xs={8}>
                  <Form.Group>
                    {hasLabel && <Form.Label>{t('City')}</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? t('City') : ''}
                      value={city}
                      name="city"
                      onChange={({ target }) => setCity(target.value)}
                      type="text"
                      autoComplete={'address-level2'}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12}>
                  <SelectCountry
                    onSelected={row => setCountry(row)}
                    active={country}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    {hasLabel && <Form.Label>{t('Phonenumber')}</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? t('Phonenumber') : ''}
                      value={phonenumber}
                      name="phonenumber"
                      onChange={({ target }) => setPhonenumber(target.value)}
                      type="text"
                      autoComplete={'tel'}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    {hasLabel && <Form.Label>{t('Fax')}</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? t('Fax') : ''}
                      value={fax}
                      name="fax"
                      onChange={({ target }) => setFax(target.value)}
                      type="text"
                      autoComplete={'fax'}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    {hasLabel && <Form.Label>{t('Email address')}</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? t('Email address') : ''}
                      value={email}
                      name="email"
                      onChange={({ target }) => setEmail(target.value)}
                      type="email"
                      autoComplete={'email'}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    {hasLabel && <Form.Label>{t('Birthday')}</Form.Label>}
                    <DatePicker
                      selected={birthday ? new Date(birthday) : birthday}
                      onChange={date => {
                        let fdate = dayjs(new Date(date)).format('YYYY-MM-DD');
                        setBirthday(fdate);
                      }}
                      className="form-control"
                      placeholderText={t('Birthday')}
                      locale={localeHelper.locale}
                      dateFormat={localeHelper.date2}
                      maxDate={new Date()}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'falcon-default'} onClick={handleClose}>
            {t('close')}
          </Button>
          <Button
            type="submit"
            variant={'falcon-success'}
            disabled={isDisabled}
          >
            {mode === 'edit' ? t('save') : t('create')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ModalContactForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  hasLabel: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit']),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

ModalContactForm.defaultProps = {
  hasLabel: false,
  onClose: () => {},
  onCreated: () => {},
  mode: 'create'
};

export default ModalContactForm;
