import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RegistrationWizardForm from '../RegistrationWizardForm';
import axios from 'axios';
import Loader from '../../../common/Loader';
import { validateToken } from '../../../../helpers/standardRequests';

const RegistrationWizard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    validateToken(token)
      .then(function (res) {
        const result = res.data.result;
        result?.user ? setUser(result?.user) : false;
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        navigate('/');
      });
  }, []);

  return isLoading ? <Loader /> : <RegistrationWizardForm user={user} />;
};

export default RegistrationWizard;
