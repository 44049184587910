export const pagesVariants = {
  prayerTimes: 'prayerTimes',
  appLogo: 'appLogo',
  fullImage: 'fullImage',
  imageLeftTextRight: 'imageLeftTextRight',
  imageRightTextLeft: 'imageRightTextLeft',
  imageTopTextBottom: 'imageTopTextBottom',
  onlyText: 'onlyText'
};

export default [
  { value: 'prayerTimes', label: 'Prayer Times Display' },
  { value: 'appLogo', label: 'Application Logo Display' },
  { value: 'fullImage', label: 'Full Image Display' },
  { value: 'imageLeftTextRight', label: 'Image Left with Text Right' },
  { value: 'imageRightTextLeft', label: 'Image Right with Text Left' },
  { value: 'imageTopTextBottom', label: 'Image Top with Text Bottom' },
  { value: 'onlyText', label: 'Text Only Display' }
];
