const InitalState = {
  organizationId: null,
  organization: null,
  assignedOrganizations: null,
  createOrganization: null
};

const organizationReducer = (state = InitalState, action) => {
  switch (action.type) {
    case 'setOrganization':
      return {
        ...state,
        organization: action.payload,
        organizationId: action.payload.id
      };
    case 'setOrganizationId':
      return {
        ...state,
        organizationId: action.payload
      };
    case 'setAssignedOrganizations':
      return {
        ...state,
        assignedOrganizations: action.payload
      };
    case 'resetOrganization':
      return InitalState;
    default:
      return state;
  }
};

export default organizationReducer;
