import axios from 'axios';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { store } from './redux/store';
import { requestsReducerActions, setLogout } from './redux/actions';

const initAxios = () => {
  const { t } = i18next;

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  /*axios.defaults.headers.common[
        'Authorization'
        ] = `${auth.tokenType}${auth.token}`;*/
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  axios.interceptors.response.use(
    function (response) {
      store.dispatch(
        requestsReducerActions({
          type: 'setRequest',
          payload: {
            fullUrl: response.config.baseURL + response.config.url,
            url: response.config.url,
            baseUrl: response.config.baseURL,
            method: response.config.method,
            status: response.status,
            response: response.data
          }
        })
      );

      if (response.data.alerts) {
        toast[response.data.alerts.type](t(response.data.alerts.message), {
          theme: 'colored'
        });
      }
      return response;
    },
    function (error) {
      store.dispatch(
        requestsReducerActions({
          type: 'setRequest',
          payload: {
            url: error.config.baseURL + error.config.url,
            baseUrl: error.config.baseURL,
            method: error.config.method,
            status: error.response.status,
            error: error.response
          }
        })
      );

      /*if (error.toJSON().message === 'Network Error')
        return toast.error(t('Check your internet connection'));*/
      if (error.response) {
        if (error?.response?.data?.alerts) {
          toast[error.response.data.alerts.type](
            t(error.response.data.alerts.message),
            {
              theme: 'colored'
            }
          );
        }

        if (error.response.status === 401) {
          store.dispatch(setLogout());
          store.dispatch({ type: 'resetOrganization' });
          /* if (!window.location.href.includes('/auth/login'))
            window.location.href = '/auth/login';*/
        }
        if (error.response.status === 403) {
          toast.error(t('You have not the permission to do this action'), {
            theme: 'colored'
          });
        }
        if (error.response.status === 404) {
          if (!navigator.onLine)
            return toast.error(t('Check your internet connection'), {
              theme: 'colored'
            });
          toast.error(t('Something went wrong'), {
            theme: 'colored'
          });
        }
        if (error.response.status === 500) {
          toast.error(t('Something went wrong'), {
            theme: 'colored'
          });
        }
      } else if (error.request) {
        toast.error(t('Something went wrong'), {
          theme: 'colored'
        });
        // client never received a response, or request never left
      } else {
        toast.error(t('Something went wrong'), {
          theme: 'colored'
        });
        // anything else
      }

      return Promise.reject(error);
    }
  );
};

export default initAxios();
