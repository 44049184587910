import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import {
  Card,
  Col,
  Row,
  Form,
  InputGroup,
  Button,
  Collapse
} from 'react-bootstrap';

import { t } from 'i18next';
import IconButton from '../../../components/common/IconButton';
import APIResponseViewer from '../../../helpers/APIResponseViewer';
import SimpleForm from '../../../helpers/SimpleForm/SimpleForm';
import dayjs from 'dayjs';
import Avatar from '../../../components/common/Avatar';
import avatarImg from '../../../assets/img/team/avatar.png';

function User() {
  const [formData, setFormData] = useState({});
  const [requested, setRequested] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [avatar, setAvatar] = useState(
    formData.profile_picture_url ?? avatarImg
  );

  const formOptions = [
    [
      {
        col: { md: 4 },
        name: 'email',
        elementType: 'input',
        type: 'text',
        disabled: true,
        placeholder: 'Email',
        label: t('Email'),
        value: formData.email
      },
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'active',
        required: false,
        disabled: false,
        placeholder: 'active',
        label: t('active'),
        checked: formData.active == 1
      }
    ],
    [
      {
        col: { md: 4 },
        name: 'firstname',
        elementType: 'input',
        type: 'text',
        disabled: false,
        required: true,
        placeholder: 'First name',
        label: t('First name'),
        value: formData.firstname
      },
      {
        col: { md: 4 },
        name: 'lastname',
        elementType: 'input',
        type: 'text',
        disabled: false,
        required: true,
        placeholder: 'Last name',
        label: t('Last name'),
        value: formData.lastname
      }
    ],
    [
      {
        col: { md: 4 },
        name: 'gender',
        elementType: 'select',
        disabled: false,
        required: false,
        placeholder: 'gender',
        label: t('gender'),
        value: formData.gender,
        options: [
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
          { value: 'unknown', label: 'Unknown' }
        ]
      },
      {
        col: { md: 4 },
        name: 'birthday',
        elementType: 'date',
        disabled: false,
        required: false,
        placeholder: t('birthday'),
        label: t('birthday'),
        datepickerProps: { maxDate: new Date() },
        value: formData.birthday
          ? dayjs(formData.birthday).format('YYYY-MM-DD')
          : null
      }
    ],
    [
      {
        col: { md: 4 },
        name: 'profile_picture',
        elementType: 'upload',
        accept: 'image/*',
        disabled: false,
        required: false,
        label: t('Profile picture'),
        handleChange: files => {
          files.map((file, index) => {
            setAvatar(file.base64);
            return axios({
              url: `/user/${formData.id}/profilepicture`,
              method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              data: { file: file.file }
            });
          });
        }
      },
      {
        col: { md: 4 },
        name: 'profile_picture_avatar',
        elementType: 'custom',
        element: <Avatar src={avatar} rounded="0" size="4xl" />
      }
    ]
  ];

  const passwordFormOptions = [
    [
      {
        col: { md: 4 },
        name: 'password',
        autoComplete: 'new-password',
        elementType: 'input',
        type: 'password',
        required: true,
        placeholder: 'Password',
        label: t('password')
      },
      {
        col: { md: 4 },
        name: 'confirmPassword',
        autoComplete: 'new-password',
        elementType: 'input',
        type: 'password',
        required: true,
        placeholder: 'Password again',
        label: t('password again')
      }
    ]
  ];

  const getData = () => {
    setDataLoading(true);
    axios({
      url: `/user/${id}`,
      method: 'GET'
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
        if (error.response.status === 404) {
          //navigate('/settings');
        }
      });
  };

  const updateData = changedData => {
    setDataLoading(true);
    axios({
      url: `/user/${id}`,
      method: 'PUT',
      data: changedData
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  const updatePassword = changedData => {
    setDataLoading(true);
    axios({
      url: `/user/${id}/changepasswordwc`,
      method: 'PUT',
      data: changedData
    })
      .then(function (res) {
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  const deleteData = () => {
    setDataLoading(true);
    axios({
      url: `/user/${id}`,
      method: 'DELETE'
    })
      .then(function (res) {
        navigate('/contacts');
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (formData.profile_picture_url)
      setAvatar(formData.profile_picture_url ?? avatarImg);
  }, [formData]);

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <h5 className="">{t('User')}</h5>
                </Col>
                <Col xs={'auto'}></Col>
                <Col xs={'auto'}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                    transform="shrink-3"
                    className="me-2"
                    tooltipText={'back'}
                    tooltipPosition={'left'}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <div>
                {dataLoading ? (
                  <Loader width={'100px'} />
                ) : (
                  <>
                    <SimpleForm
                      options={formOptions}
                      handleSubmit={updateData}
                      showDelete={true}
                      onDeleteClick={deleteData}
                    />

                    <Row>
                      <Col>
                        <Button
                          onClick={() =>
                            setChangePasswordOpen(!changePasswordOpen)
                          }
                          aria-controls="example-collapse-text"
                          aria-expanded={changePasswordOpen}
                          variant="falcon-primary"
                          className="mb-3"
                        >
                          Change Password
                        </Button>
                        <Collapse in={changePasswordOpen}>
                          <div className="border rounded p-3">
                            <SimpleForm
                              options={passwordFormOptions}
                              handleSubmit={updatePassword}
                            />
                          </div>
                        </Collapse>
                      </Col>
                    </Row>
                    {formData && <APIResponseViewer data={formData} />}
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default User;
