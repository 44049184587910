import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { Card, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Acl from './acl/Acl';
import { Modules } from '../../modules';
import General from './general/General';
import { Box } from '@mui/material';

const Settings = () => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState([
    { id: 'general', name: 'general', component: <General />, active: true }
    /*{ id: 'acl', name: 'Roles and Permissions', component: <Acl /> }*/
  ]);

  useLayoutEffect(() => {
    async function fetchModules() {
      const modules = await Modules();
      const moduleSettings = modules
        .filter(module => 'settings' in module && module.settings.length > 0)
        .flatMap(module => module.settings);

      setSettings(settings => {
        // Create a Set of existing settings for fast lookup
        const existingSettings = new Set(settings.map(setting => setting.id));

        // Only add new settings that are not already in the array
        const newSettings = moduleSettings.filter(
          setting => !existingSettings.has(setting.id)
        );

        // Concatenate the new settings with the existing array
        return settings.concat(newSettings);
      });
    }
    fetchModules();
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row className="justify-content-between align-items-center">
                <Col md>
                  <Row className={'align-items-center '}>
                    <Col className={'col-auto'}>
                      <h5>
                        {t('capitalize', { value: t('setting', { count: 2 }) })}
                      </h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
                {settings.map(setting => {
                  return (
                    <Tab
                      key={setting.id}
                      eventKey={setting.id}
                      title={t('capitalize', {
                        value: t(
                          `${setting?.translationNameSpace ?? 'settings'}:${
                            setting.name
                          }`
                        )
                      })}
                      className={'border-bottom  p-2 '}
                    >
                      <Box m={2}>{setting.component}</Box>
                    </Tab>
                  );
                })}
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Settings;
