let registration = localStorage.getItem('registration');
registration =
  registration !== null && registration !== 'false'
    ? JSON.parse(registration)
    : false;

const InitalState = {
  email: registration?.email ?? null,
  firstname: registration?.firstname ?? null,
  lastname: registration?.lastname ?? null,
  token: registration?.token ?? null
};

const registrationReducer = (state = InitalState, action) => {
  switch (action.type) {
    case 'setRegistration':
      localStorage.setItem('registration', JSON.stringify(action.payload));

      return {
        ...state,
        email: action.payload.email,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        token: action.payload.token
      };
    default:
      return state;
  }
};

// Listen for changes in localStorage from other tabs
window.addEventListener('storage', event => {
  if (event.key === 'registration') {
    registration = JSON.parse(event.newValue);
  }
});

export default registrationReducer;
