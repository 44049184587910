import React, { useEffect, useState } from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import Loader from '../../../../components/common/Loader';
import SelectUser from '../../../User/components/SelectUser';
import PropTypes from 'prop-types';
import axios from 'axios';
import ExtensionsSelection from '../../../OB_Microservice/components/ExtensionsSelection';

const AddExtensionModal = ({
  show,
  setModalVisible,
  organizationId,
  onSuccess
}) => {
  const isDisabled = false;
  const [extensionId, setExtensionId] = useState(null);
  const [loading, setLoading] = useState(false);

  const postRequest = extension_id => {
    if (extension_id) {
      setLoading(true);
      axios({
        url: `/ms/extension/organization`,
        method: 'post',
        data: { organization_id: organizationId, extension_id: extensionId }
      })
        .then(function (res) {
          setLoading(false);
          setModalVisible(!show);
          onSuccess(true);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('Add extension')}
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => setModalVisible(!show)}
        />
      </Modal.Header>
      <Modal.Body className="p-4">
        {loading ? (
          <Loader />
        ) : (
          <ExtensionsSelection onSelect={setExtensionId} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'falcon-default'}
          onClick={() => {
            setModalVisible(!show);
          }}
        >
          {t('close')}
        </Button>
        <Button
          variant={'falcon-success'}
          onClick={postRequest}
          disabled={isDisabled}
        >
          {t('add')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddExtensionModal.propTypes = {
  show: PropTypes.bool,
  setModalVisible: PropTypes.func,
  organizationId: PropTypes.string
};

AddExtensionModal.defaultProps = {
  show: false,
  setModalVisible: () => {}
};

export default React.memo(AddExtensionModal);
