import React, { useContext, useLayoutEffect } from 'react';
import LogoutContent from 'components/authentication/ownersbackend/LogoutContent';
import { AuthContext } from '../../../../context/Context';

const Logout = () => {
  const { auth, logout } = useContext(AuthContext);
  useLayoutEffect(() => {
    if (auth.isAuthenticated) logout();
  }, []);

  return (
    <div className="text-center">
      <LogoutContent />
    </div>
  );
};

export default Logout;
