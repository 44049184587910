import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { t } from 'i18next';
import IconButton from '../../../components/common/IconButton';
import APIResponseViewer from '../../../helpers/APIResponseViewer';
import SimpleFormV2 from '../../../helpers/SimpleForm/SimpleFormV2';
import SimpleBadge from '../../../helpers/SimpleBadge';
import ExtensionsSelection from '../../OB_Microservice/components/ExtensionsSelection';

function Detail() {
  const [formData, setFormData] = useState({});
  //const [requested, setRequested] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const formOptions = [
    [
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'is_free',
        required: false,
        disabled: false,
        placeholder: 'is_free',
        label: t('isFree'),
        checked: formData.is_free
      },
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'active',
        required: false,
        disabled: false,
        placeholder: 'active',
        label: t('active'),
        checked: formData.active
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'name',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Name'),
        label: t('Name'),
        value: formData.name
      },
      {
        col: { md: 3 },
        name: 'microservice_name',
        elementType: 'input',
        type: 'text',
        required: false,
        placeholder: t('microservice_name'),
        label: t('microservice_name'),
        value: formData.microservice_name
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'description',
        elementType: 'textarea',
        placeholder: t('description'),
        label: t('description'),
        value: formData.description
      }
    ]
  ];

  const getData = () => {
    //setRequested(true);
    axios({
      url: `/ms/extension/${id}`,
      method: 'GET'
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const updateData = changedData => {
    setDataLoading(true);
    axios({
      url: `/ms/extension/${id}`,
      method: 'PUT',
      data: changedData
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  const deleteData = () => {
    setDataLoading(true);
    axios({
      url: `/ms/extension/${id}`,
      method: 'DELETE'
    })
      .then(function (res) {
        navigate(-1);
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <h5 className="">{t('Extension')}</h5>
                </Col>
                <Col xs={'auto'}></Col>
                <Col xs={'auto'}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                    transform="shrink-3"
                    className="me-2"
                    tooltipText={'back'}
                    tooltipPosition={'left'}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <div>
                {dataLoading ? (
                  <Loader width={'100px'} />
                ) : (
                  <>
                    <SimpleFormV2
                      options={formOptions}
                      handleSubmit={updateData}
                      showDelete={true}
                      onDeleteClick={deleteData}
                    />
                    {formData && <APIResponseViewer data={formData} />}
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Detail;
