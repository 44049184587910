import { Link } from 'react-router-dom';
import Flex from '../../../common/Flex';
import React from 'react';
import LauncherContent from '../LauncherContent';

const Launcher = () => {
  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Launcher</h5>
      </Flex>
      <LauncherContent />
    </>
  );
};

export default Launcher;
