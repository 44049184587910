import dayjs from 'dayjs';

let InitalState = {
  url: null,
  status: null,
  response: null,
  error: null
};

const requestsReducer = (state = InitalState, action) => {
  switch (action.type) {
    case 'setRequest': {
      return {
        ...state,
        url: action.payload.url,
        fullUrl: action.payload.fullUrl,
        baseUrl: action.payload.baseUrl,
        method: action.payload.method,
        status: action.payload.status,
        response: action.payload?.response,
        error: action.payload?.error,
        time: dayjs()
      };
    }
    default:
      return state;
  }
};

export default requestsReducer;
