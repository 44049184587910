import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import MainLoader from '../common/MainLoader';
import { toast } from 'react-toastify';

import { AuthContext } from '../../context/Context';

const Activate = () => {
  const { activateRoute } = useParams();
  const { auth } = useContext(AuthContext);
  const [requested, setRequested] = useState(false);
  let navigate = useNavigate();

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get('ctoken') ? params.get('ctoken') : false;

  let path = auth.isAuthenticated ? '/' : '/auth/login';

  const request = () => {
    setRequested(true);
    return axios({
      url: process.env.REACT_APP_API_URL + '/activate/' + activateRoute,
      method: 'POST',
      data: { token: token }
    }).then(res => {
      if (res.data.response) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }

      navigate(path);
    });
  };

  useEffect(() => {
    if (!requested && token) request();
    else {
      navigate(path);
    }
  });

  return (
    <>
      <MainLoader />
    </>
  );
};

export default Activate;
