import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
  GridOverlay,
  useGridApiRef,
  deDE,
  GridToolbarContainer
} from '@mui/x-data-grid';
import AppContext from '../context/Context';
import astronautlight from '../assets/img/animated-icons/astronaut-light.json';
import { useTranslation } from 'react-i18next';
import { grays, themeColors } from './utils';
import { Placeholder } from 'react-bootstrap';
import { Box, LinearProgress } from '@mui/material';
import LottiePlayer from '../components/common/LottiePlayer';
import IconButton from '../components/common/IconButton';

const DataGridTable = ({
  columns,
  rows,
  loading,
  localeText,
  addSx,
  addComponents,
  autoPageSize,
  onRowClick,
  onSelectionModelChange,
  ...props
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(10);

  const sx = {
    color: isDark ? grays['300'] : grays['900'],
    '& .MuiTablePagination-selectLabel': {
      color: isDark ? grays['300'] : grays['900'],
      marginBottom: 0
    },
    '& .MuiInputBase-colorPrimary': {
      color: isDark ? grays['300'] : grays['900']
    },
    '& .MuiSvgIcon-root': {
      color: isDark ? grays['300'] : grays['900']
    },
    '& .MuiTablePagination-displayedRows': {
      color: isDark ? grays['300'] : grays['900'],
      marginBottom: 0
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: isDark ? themeColors.dark : grays['200']
    },
    '& .MuiDataGrid-cell': {
      borderBottom: `1px solid ${isDark ? grays['900'] : grays['300']}`
    },
    ...addSx
  };

  function CustomLoadingOverlay() {
    return (
      <GridOverlay
        sx={{
          background: isDark ? '#121e2d' : '#fff',
          position: 'relative',
          width: '100%',
          height: '100%',
          zIndex: 100
        }}
      >
        <div>
          <Placeholder animation="glow">
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
            <Box mt={2} mb={2}>
              <Placeholder xs={12} size={'lg'} />
            </Box>
          </Placeholder>
        </div>
      </GridOverlay>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay
        sx={{
          background: isDark ? '#121e2d' : '#fff',
          position: 'relative',
          width: '100%',
          height: '100%',
          zIndex: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <LottiePlayer
            play
            loop
            animationData={astronautlight}
            speed={1.5}
            style={{ width: '180px', height: '120px' }}
          />
          <div className={'mt-2 text-center'}>{t('No data found')}</div>
        </div>
      </GridOverlay>
    );
  }

  const slots = {
    loadingOverlay: LinearProgress,
    noRowsOverlay: CustomNoRowsOverlay,
    ...addComponents
  };

  localeText =
    'components' in deDE
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {};
  return (
    <DataGrid
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      loading={loading}
      localeText={localeText}
      autoPageSize={autoPageSize}
      scrollbarSize={20}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 20, 50, 100]}
      pagination
      style={{ border: 0 }}
      sx={sx}
      slots={slots}
      onRowClick={onRowClick}
      onSelectionModelChange={onSelectionModelChange}
      {...props}
    />
  );
};

DataGridTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  loading: PropTypes.bool,
  localeText: PropTypes.object,
  addSx: PropTypes.object,
  addComponents: PropTypes.object,
  autoPageSize: PropTypes.bool,
  onRowClick: PropTypes.func,
  onSelectionModelChange: PropTypes.func
};

DataGridTable.defaultTypes = {
  columns: {},
  rows: {},
  addSx: {},
  autoPageSize: false,
  localeText: deDE.components.MuiDataGrid.defaultProps.localeText,
  onRowClick: () => {},
  onSelectionModelChange: () => {}
};

export default DataGridTable;
