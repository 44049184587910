import React from 'react';
import PrayerTimePresentation from '../PrayerTime/pages/Presentation';
import Presentation from '../Donation/components/Presentation';

export default {
  name: 'PublicRoutes',
  label: 'PublicRoutes',
  labelDisable: true,
  children: [],
  routes: [
    {
      path: '/donationpool/presentation/:organizationId/:id',
      name: 'Donation Details',
      component: <Presentation />,
      public: true,
      layout: 'empty'
    },
    {
      path: '/prayertime/view/:organizationId/:id',
      name: 'Extension Details',
      component: <PrayerTimePresentation />,
      public: true,
      layout: 'empty'
    }
  ]
};
