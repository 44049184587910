import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import OwnersBackendLayout from './layouts/OwnersBackendLayout';
import AuthProvider from './providers/AuthProvider';
import './axios';
import MainLoader from './components/common/MainLoader';
import AppContext from './context/Context';
import { CloseButton } from './components/common/Toast';
import { toast, ToastContainer } from 'react-toastify';
import is from 'is_js';
import Layout from './layouts/Layout';
import axios from 'axios';
import LandingLayout from './layouts/LandingLayout';
import AppLayout from './layouts/AppLayout';

const App = () => {
  const pageLoaded = true;
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition, layout }
  } = useContext(AppContext);

  useEffect(() => {
    axios.defaults.headers.common['App-Layout'] = layout;
  }, [layout]);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthProvider fallback={<span>auth loading</span>}>
        {pageLoaded ? (
          <>
            {layout === 'app' ? <AppLayout /> : null}
            {layout === 'landing' ? <LandingLayout /> : null}
          </>
        ) : (
          <MainLoader />
        )}
      </AuthProvider>
      <ToastContainer
        autoClose={5000}
        newestOnTop
        closeButton={CloseButton}
        theme={'dark'}
        limit={3}
        pauseOnHover
        position={toast.POSITION.TOP_CENTER}
      />
    </Router>
  );
};

export default App;
