import { Col, Row} from "react-bootstrap";
import React, {useContext} from "react";
import PropTypes from "prop-types";
import AppContext from "../../../../../context/Context";
import Clock from "../Clock";
import Logo from "../../../../../components/common/Logo";
import PrayerTimes from "../PrayerTimes";

const PrayerTimesPage = ({prayerTimes,onNewDay}) => {
    let {
        config: { isDark }
    } = useContext(AppContext);

    return (
        <>
            <div className={'mb-4'}>
                <Clock
                    onNewDay={onNewDay}
                />
            </div>
            <div className={'mb-5'}>
                <PrayerTimes times={prayerTimes} />
            </div>
            <div className={'mb-3'}>
                <Row className={'justify-content-center'}>
                    <Col md={2}>
                        <Logo
                            useAppLogo={true}
                            width={200}
                            variant={'nextTo'}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

PrayerTimesPage.propTypes = {
    page: PropTypes.object,
    prayerTimes: PropTypes.array.isRequired,
    onNewDay: PropTypes.func,
};

PrayerTimesPage.defaultTypes = {
    page: {},
    prayerTimes: [],
    onNewDay: {},
};

export default PrayerTimesPage;
