import React, { useState } from 'react';
import { Alert, Button, Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LocalizationSelection from '../components/settings/general/components/LocalizationSelection';
import { Box } from '@mui/material';

const APIResponseViewer = ({ data }) => {
  const [open, setOpen] = useState(false);

  function renderObject(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return <>(No response)</>;
    }

    return (
      <>
        {Object.keys(obj).map(key => (
          <div key={key}>
            {typeof obj[key] === 'object' ? (
              <div>
                <h4>{key}:</h4>
                <div style={{ marginLeft: '10px' }}>
                  {renderObject(obj[key])}
                </div>
              </div>
            ) : (
              <>
                <strong>{key}: </strong>
                {obj[key].toString()}
              </>
            )}
          </div>
        ))}
      </>
    );
  }

  return (
    process.env.NODE_ENV === 'development' && (
      <Box mt={5} mb={5}>
        <Alert variant={'danger'}>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            variant="falcon-primary"
            className="mb-3"
          >
            Show API response
          </Button>
          <Collapse in={open}>
            <div className="border rounded">
              <div className="p-x1">{renderObject(data)}</div>
            </div>
          </Collapse>
        </Alert>
      </Box>
    )
  );
};

APIResponseViewer.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

APIResponseViewer.defaultProps = {
  data: {}
};

export default APIResponseViewer;
