import React from 'react';
import PageHeader from '../../common/PageHeader';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import Weather from './Weather';
import { weather } from 'data/dashboard/default';
const Dashboard = ({ user }) => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <PageHeader
            title={t('helloFirstName', { value: user.firstname })}
            description=""
            className="mb-3"
          ></PageHeader>
        </Col>
      </Row>
      {/*<Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <Weather data={weather} />
        </Col>
      </Row>*/}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    user: state.auth.user
  };
};

export default connect(mapStateToProps, null)(Dashboard);
