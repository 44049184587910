import { getCountry } from './data/countries';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import i18next from 'i18next';
import localeHelper from './helpers/locale';
import { registerLocale } from 'react-datepicker';

export const version = '1.1.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  localization: null,
  language: null,
  layout: null
};

export function getCountryObject(code = false) {
  const i18n = i18next;
  let value;

  if (code) {
    value =
      code.indexOf('-') === -1
        ? getCountry(code.toUpperCase(), 'code')
        : getCountry(code, 'code2');
  } else if (i18n.language) {
    value =
      i18n.language.indexOf('-') === -1
        ? getCountry(i18n.language.toUpperCase(), 'code')
        : getCountry(i18n.language, 'code2');
  } else {
    value = getCountry('en-US', 'code2');
  }

  return value;
}

export function setLocaleConfigs() {
  const i18n = i18next;

  if (i18n.language) {
    const value = getCountryObject();

    if (i18n.language.indexOf('-') === -1) {
      if (value && 'code2' in value) {
        i18n.changeLanguage(value.code2);
      }
    }

    //dayjs locale
    import(`dayjs/locale/de`).then(() => {
      dayjs.extend(utc);
      dayjs.extend(localizedFormat);
      dayjs.extend(localeData);
      dayjs.locale(value.code.toLowerCase());
      let date = dayjs().localeData().longDateFormat('L');
      let datetime = `${dayjs().localeData().longDateFormat('L')} ${dayjs()
        .localeData()
        .longDateFormat('LT')}`;
      localeHelper.date = date;
      localeHelper.date2 = date.replace('DD', 'dd').replace('YYYY', 'yyyy');
      localeHelper.datetime = datetime;
      localeHelper.datetime2 = datetime
        .replace('DD', 'dd')
        .replace('YYYY', 'yyyy');
      localeHelper.locale = value.code.toLowerCase();
      localeHelper.locale2 = value.code2;
    });

    try {
      import(`date-fns/locale/${value.code.toLowerCase()}`).then(locale => {
        registerLocale(value.code.toLowerCase(), locale.default);
      });
    } catch (e) {
      console.error(`Unable to import module`);
    }

    return { locale: value, language: value };
  }
  return { locale: 'en-EN', language: 'en-EN' };
}

export function checkIsApp() {
  const requestedUrl = `${window.location.protocol}//${window.location.hostname}`;
  const app = [
    process.env.REACT_APP_APP_URL,
    process.env.REACT_APP_APP_URL.toString().replace(':3000', '')
  ].includes(requestedUrl);

  return Boolean(app);
}

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
