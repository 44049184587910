import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { t } from 'i18next';
import IconButton from '../../../components/common/IconButton';
import APIResponseViewer from '../../../helpers/APIResponseViewer';
import SimpleFormV2 from '../../../helpers/SimpleForm/SimpleFormV2';
import SimpleBadge from '../../../helpers/SimpleBadge';

function Detail() {
  const [formData, setFormData] = useState({});
  //const [requested, setRequested] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const formOptions = [
    [
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'active',
        required: false,
        disabled: false,
        placeholder: 'active',
        label: t('active'),
        checked: formData.active
      },
      {
        col: { md: 'auto' },
        elementType: 'custom',
        name: 'active',
        element: (
          <SimpleBadge
            value={formData['running']}
            options={{
              false: {
                className: 'danger',
                label: 'offline',
                icon: 'power-off'
              },
              true: {
                className: 'success',
                label: 'running',
                icon: 'globe'
              },
              0: {
                className: 'danger',
                label: 'offline',
                icon: 'power-off'
              },
              1: {
                className: 'success',
                label: 'running',
                icon: 'globe'
              }
            }}
          />
        )
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'name',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Name'),
        label: t('Name'),
        value: formData.name
      },
      {
        col: { md: 3 },
        name: 'host',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Host'),
        label: t('Host'),
        value: formData.host
      },
      {
        col: { md: 3 },
        name: 'port',
        elementType: 'input',
        type: 'text',
        required: false,
        placeholder: t('Port'),
        label: t('Port'),
        value: formData.port
      },
      {
        col: { md: 3 },
        name: 'path',
        elementType: 'input',
        type: 'text',
        required: true,
        placeholder: t('Path'),
        label: t('Path'),
        value: formData.path
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'database_name',
        elementType: 'input',
        type: 'text',
        placeholder: t('Database'),
        label: t('Database'),
        value: formData.database_name
      },
      {
        col: { md: 3 },
        name: 'database_host',
        elementType: 'input',
        type: 'text',
        placeholder: t('Database Host'),
        label: t('Database Host'),
        value: formData.database_host
      },
      {
        col: { md: 3 },
        name: 'database_port',
        elementType: 'input',
        type: 'text',
        placeholder: t('Database Port'),
        label: t('Database Port'),
        value: formData.database_port
      },
      {
        col: { md: 3 },
        name: 'database_user',
        elementType: 'input',
        type: 'text',
        placeholder: t('Database User'),
        label: t('Database User'),
        value: formData.database_user
      },
      {
        col: { md: 3 },
        name: 'database_password',
        elementType: 'input',
        type: 'password',
        autoComplete: 'new-password',
        placeholder: t('Database Password'),
        label: t('Database Password')
      }
    ],
    [
      {
        col: { md: 'auto' },
        name: 'migrate',
        elementType: 'custom',
        element: (
          <Button
            variant="falcon-info"
            onClick={() => {
              axios.put(`/ms/${formData.name}/migrate`);
            }}
          >
            migrate
          </Button>
        )
      },
      {
        col: { md: 'auto' },
        name: 'seed',
        elementType: 'custom',
        element: (
          <Button
            variant="falcon-warning"
            onClick={() => {
              axios.put(`/ms/${formData.name}/seed`);
            }}
          >
            seed
          </Button>
        )
      }
    ]
  ];

  const getData = () => {
    //setRequested(true);
    axios({
      url: `/microservice/${id}`,
      method: 'GET'
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const updateData = changedData => {
    setDataLoading(true);
    axios({
      url: `/microservice/${id}`,
      method: 'PUT',
      data: changedData
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  const deleteData = () => {
    setDataLoading(true);
    axios({
      url: `/microservice/${id}`,
      method: 'DELETE'
    })
      .then(function (res) {
        navigate(-1);
        setDataLoading(false);
      })
      .catch(error => {
        setDataLoading(false);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <h5 className="">{t('Microservice')}</h5>
                </Col>
                <Col xs={'auto'}></Col>
                <Col xs={'auto'}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                    transform="shrink-3"
                    className="me-2"
                    tooltipText={'back'}
                    tooltipPosition={'left'}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <div>
                {dataLoading ? (
                  <Loader width={'100px'} />
                ) : (
                  <>
                    <SimpleFormV2
                      options={formOptions}
                      handleSubmit={updateData}
                      showDelete={true}
                      onDeleteClick={deleteData}
                    />
                    {formData && <APIResponseViewer data={formData} />}
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Detail;
