import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { grays, isIterableArray } from './utils';
import { countries } from '../data/countries';
import AppContext from '../context/Context';

const SearchSelection = ({
  items,
  value,
  placeholder,
  assignment,
  onSelect
}) => {
  const { t, i18n } = useTranslation();
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [obj, setValue] = useState(value);
  const [options, setOptions] = useState([]);
  let optionsArray = [];
  const handleSelect = value => {
    setValue(value);
    onSelect(value);
  };

  useEffect(() => {
    setValue(
      value
        ? {
            value: value[assignment.value],
            label: t(value[assignment.label])
          }
        : value
    );
  }, [value]);

  useEffect(() => {
    isIterableArray(items) &&
      items.map((item, index) => {
        optionsArray.push({
          value: item[assignment.value],
          label: t(item[assignment.label])
        });
      });
    setOptions(optionsArray);
  }, []);

  const customStyles = {
    singleValue: (provided, state) => {
      const color = isDark ? grays['300'] : grays['900'];
      return { ...provided, color };
    }
  };

  return (
    <Select
      styles={customStyles}
      closeMenuOnSelect={true}
      options={options}
      placeholder={t(placeholder)}
      classNamePrefix="react-select"
      value={obj}
      onChange={handleSelect}
    />
  );
};

SearchSelection.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  assignment: PropTypes.exact({
    value: PropTypes.string,
    label: PropTypes.string
  })
};

SearchSelection.defaultProps = {
  items: [],
  value: false,
  assignment: {
    value: 'id',
    label: 'name'
  },
  placeholder: '',
  onSelect: () => {}
};

export default SearchSelection;
