import Flex from '../../components/common/Flex';
import cloudUpload from '../../assets/img/icons/cloud-upload.svg';
import FalconDropzone from '../../components/common/FalconDropzone';
import React, { useState } from 'react';
import avatarImg from '../../assets/img/team/avatar.png';
import { Col, Form, ProgressBar } from 'react-bootstrap';

const UploadForm = props => {
  const {
    col,
    name,
    multiple = false,
    label,
    required,
    accept,
    uploadProgress,
    handleChange
  } = props;

  return (
    <Form.Group as={Col} {...col} className={'h-100'} controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      {uploadProgress ? (
        <ProgressBar now={uploadProgress} />
      ) : (
        <FalconDropzone
          onChange={handleChange}
          multiple={multiple}
          accept={accept}
          placeholder={
            <>
              <Flex justifyContent="center">
                <img src={cloudUpload} alt="" width={25} className="me-2" />
                <p className="fs-0 mb-0 text-700">Upload your {label}</p>
              </Flex>
            </>
          }
        />
      )}
    </Form.Group>
  );
};

export default UploadForm;
