import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import avatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import { useTranslation } from 'react-i18next';
import AppContext, { AuthContext } from '../../../context/Context';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const {
    auth: { user }
  } = useContext(AuthContext);
  const {
    config: { layout }
  } = useContext(AppContext);

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={user?.profile_picture_url ?? avatar} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/user/profile">
            {t('myAccount')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/settings">
            {t('capitalize', { value: t('setting', { count: 2 }) })}
          </Dropdown.Item>
          {layout === 'app' && (
            <Dropdown.Item as={Link} to="/auth/launcher">
              {t('capitalize', { value: t('auth:launcher') })}
            </Dropdown.Item>
          )}
          <Dropdown.Item as={Link} to="/auth/logout" className={'text-danger'}>
            {t('capitalize', { value: t('auth:logout') })}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
