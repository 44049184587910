import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import User from './User';
import { checkIsApp } from '../config';
import OB_Organizations from './OB_Organizations';
import OB_User from './OB_User';
import OB_Microservice from './OB_Microservice';
import OB_Sessions from './OB_Sessions';
import Organisation from './Organisation';
import OB_Extension from './OB_Extension';
import PublicRoutes from './PublicRoutes';
import { store } from '../redux/store';

let appStandartModules = [Organisation, User, PublicRoutes];
let ownersBackendStandartModules = [
  OB_User,
  OB_Organizations,
  OB_Sessions,
  OB_Microservice,
  OB_Extension
];
let organizationModules = [];

export const getModulesRequest = id => {
  const organization = store.getState().organization; // Get current state

  if (
    (axios.defaults.headers.common.Authorization &&
      organization?.organizationId) ||
    (axios.defaults.headers.common.Authorization && id)
  ) {
    let options = {
      url: process.env.REACT_APP_API_URL + '/ms/extension/organization',
      method: 'GET'
    };

    if (id)
      options.url = `${process.env.REACT_APP_API_URL}/ms/extension/organization/${id}`;

    return axios(options)
      .then(async res => {
        if (res && res.data.result) {
          return res.data.result;
        }
      })
      .catch(error => {
        return [];
      });
  }
};

export const getModules = async () => {
  const lsModules = JSON.parse(localStorage.getItem('modules'));
  if (!lsModules) {
    const { modules, pending } = await getModulesRequest();
    let modulesArray = [];
    if (!pending && modules) {
      modules.map(module => {
        return modulesArray.push(module);
      });
      localStorage.setItem('modules', JSON.stringify(modulesArray));
    }
    return modules;
  } else {
    return await getModulesLocalStorage();
  }
};

export const getModulesLocalStorage = async () => {
  let lsModules = JSON.parse(localStorage.getItem('modules'));
  let moduless = [];
  await Promise.all(
    lsModules.map(module => {
      return import(`./${module}`).then(m => {
        moduless.push(m.default);
      });
    })
  );

  return moduless;
};

export const DynamicRoutes = async (layout, addModules = []) => {
  const modules = await Modules(addModules);

  const routes = modules
    .filter(({ routes }) => routes && routes.length > 0)
    .flatMap(({ routes }) => {
      return routes
        .filter(({ layout: routeLayout }) => routeLayout === layout)
        .map(({ path, component }) => (
          <Route key={path} path={path} exact element={component} />
        ));
    });

  return routes;
};

DynamicRoutes.propTypes = {
  layout: PropTypes.string,
  addModules: PropTypes.array
};

export const DynamicPublicRoutes = async (layout, addModules = []) => {
  const modules = await Modules(addModules);

  const routes = modules
    .filter(({ routes }) => routes && routes.length > 0)
    .flatMap(({ routes }) => {
      return routes
        .filter(
          ({ layout: routeLayout, public: routePublic }) =>
            routeLayout === layout && routePublic
        )
        .map(({ path, component }) => (
          <Route key={path} path={path} exact element={component} />
        ));
    });

  return routes;
};

DynamicPublicRoutes.propTypes = {
  layout: PropTypes.string,
  addModules: PropTypes.array
};

export const Modules = async (modules, organizationId) => {
  const isApp = checkIsApp();

  modules = modules ?? [];

  if (isApp) {
    organizationModules = await getModulesRequest(organizationId);
  }

  if (isApp && Array.isArray(organizationModules)) {
    organizationModules.map(organizationModule => {
      if (organizationModule.active) modules.push(organizationModule.name);
    });
  }

  const modulesObjects = isApp ? appStandartModules : [];

  const dynamicModulesPromises = modules.map(async module => {
    if (typeof module === 'object') {
      return module;
    } else if (typeof module === 'string') {
      const m = await import(`./${module}`);
      return m.default;
    }
  });

  const dynamicModules = await Promise.all(dynamicModulesPromises);

  return [...modulesObjects, ...dynamicModules];
};

export default Modules;
