import SimpleFormV2 from '../../../helpers/SimpleForm/SimpleFormV2';
import React, { useEffect, useState } from 'react';
import i18next, { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import avatarImg from '../../../assets/img/team/avatar.png';
import axios from 'axios';
import Avatar from '../../../components/common/Avatar';
import SimpleBadge from '../../../helpers/SimpleBadge';
import IconButton from '../../../components/common/IconButton';
import { connect } from 'react-redux';
import Loader from '../../../components/common/Loader';

const Setting = ({ organization }) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [addAddressModal, setAddAddressModal] = useState(false);

  const [avatarLight, setAvatarLight] = useState(avatarImg);
  const [avatarDark, setAvatarDark] = useState(avatarImg);

  const formOptions = [
    [
      {
        col: { md: 3 },
        name: 'name',
        elementType: 'custom',
        element: (
          <>
            <label>{t('Name')}</label>
            <div>{formData?.name}</div>
          </>
        )
      },
      {
        col: { md: 3 },
        name: 'created_at',
        elementType: 'custom',
        element: (
          <>
            <label>{t('Created at')}</label>
            <div>
              {i18next.t('intlDate', {
                val: new Date(formData?.created_at)
              })}
            </div>
          </>
        )
      },
      {
        col: { md: 'auto' },
        elementType: 'switch',
        name: 'public',
        required: false,
        disabled: true,
        placeholder: 'public',
        label: t('public'),
        checked: formData.public == 1
      }
    ],
    [
      {
        col: { md: 3 },
        name: 'email',
        elementType: 'input',
        type: 'text',
        placeholder: 'E-Mail',
        label: t('E-Mail'),
        value: formData.email
      },
      {
        col: { md: 3 },
        name: 'phonenumber',
        elementType: 'input',
        type: 'text',
        placeholder: t('Phonenumber'),
        label: t('Phonenumber'),
        value: formData.phonenumber
      },
      {
        col: { md: 3 },
        name: 'fax',
        elementType: 'input',
        type: 'text',
        placeholder: t('Fax'),
        label: t('Fax'),
        value: formData.fax
      }
    ],
    [
      {
        col: { md: 4 },
        name: 'logo',
        elementType: 'upload',
        accept: 'image/*',
        disabled: false,
        required: false,
        label: t('light logo'),
        handleChange: files => {
          files.map((file, index) => {
            setAvatarLight(file.base64);
            return axios({
              url: `/ms/organization/upload/organizationlogolight`,
              method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              data: { file: file.file }
            });
          });
        }
      },
      {
        col: { md: 4 },
        name: 'logo',
        elementType: 'custom',
        element: <Avatar src={avatarLight} rounded="0" size="4xl" />
      }
    ],
    [
      {
        col: { md: 4 },
        name: 'logo',
        elementType: 'upload',
        accept: 'image/*',
        disabled: false,
        required: false,
        label: t('dark logo'),
        handleChange: files => {
          files.map((file, index) => {
            setAvatarDark(file.base64);
            return axios({
              url: `/ms/organization/upload/organizationlogodark`,
              method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              data: { file: file.file }
            });
          });
        }
      },
      {
        col: { md: 4 },
        name: 'logo',
        elementType: 'custom',
        element: <Avatar src={avatarDark} rounded="0" size="4xl" />
      }
    ],
    [
      {
        col: { md: 12 },
        name: 'addresses',
        elementType: 'table',
        label: t('Addresses'),
        checkbox: false,
        onAddClicked: () => setAddAddressModal(true),
        onSyncClicked: () => getData(),
        rows: formData.addresses,
        columns: [
          {
            field: 'street',
            headerName: t('Street'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'house_number',
            headerName: t('Housenumber'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'zip',
            headerName: t('zip'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'city',
            headerName: t('City'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'state',
            headerName: t('State'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'country',
            headerName: t('Country'),
            type: 'string',
            flex: 1,
            minWidth: 100
          },
          {
            field: 'is_standard',
            headerName: t('Standart'),
            flex: 0.1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => {
              const { is_standard } = params.row;
              return (
                <SimpleBadge
                  value={is_standard}
                  options={{
                    0: {
                      className: 'danger',
                      icon: 'xmark'
                    },
                    1: {
                      className: 'success',
                      icon: 'check'
                    }
                  }}
                />
              );
            }
          },
          {
            field: ' ',
            headerName: ' ',
            flex: 0.1,
            minWidth: 100,
            type: 'string',
            align: 'center',
            headerAlign: 'center',
            renderCell: params => {
              return (
                <>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-right"
                    transform="shrink-3"
                    className="me-2"
                    onClick={() => {
                      //onRowEditClicked(params.row);
                    }}
                  />
                </>
              );
            }
          }
        ]
      }
    ]
  ];

  const getData = () => {
    setLoading(true);
    axios({
      url: `/ms/organization/${organization.id}`,
      method: 'GET'
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
          res.data.result?.logo_light_url
            ? setAvatarLight(res.data.result?.logo_light_url)
            : null;
          res.data.result?.logo_dark_url
            ? setAvatarDark(res.data.result?.logo_dark_url)
            : null;
        }

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const updateData = changedData => {
    setLoading(true);
    axios({
      url: `/ms/organization/${organization.id}`,
      method: 'PUT',
      data: changedData
    })
      .then(function (res) {
        if (res.data.result) {
          setFormData(res.data.result);
        }

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  const deleteData = () => {
    setDataLoading(true);
    axios({
      url: `/ms/organization/${organization.id}`,
      method: 'DELETE'
    })
      .then(function (res) {
        navigate(-1);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  return loading ? (
    <Loader width={'100px'} />
  ) : (
    <>
      <SimpleFormV2
        options={formOptions}
        handleSubmit={updateData}
        showDelete={true}
        onDeleteClick={deleteData}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    organization: state.organization.organization
  };
};

export default connect(mapStateToProps, null)(Setting);
