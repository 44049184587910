import { Card, Col, Image, Row } from 'react-bootstrap';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../../../../context/Context';

const ImageLeftTextRight = ({ page }) => {
  let {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Row className={'justify-content-center mb-3'}>
      <Col xs={12}>
        <Card
          className=" d-flex "
          style={{ height: '80vh', overflow: 'hidden' }}
        >
          <Card.Body
            className="d-flex p-3 justify-content-center"
            style={{ maxHeight: '80vh' }}
          >
            <Row
              className={'justify-content-center align-items-center mt-2 mb-2'}
            >
              <Col md={6}>
                <div
                  style={{ maxHeight: '80vh' }}
                  className="d-flex  p-3 pt-5 pb-5"
                >
                  {page.img_light && !isDark && (
                    <Image
                      fluid
                      src={page.img_light}
                      className="w-100"
                      style={{ maxHeight: '80vh', objectFit: 'cover' }}
                    />
                  )}
                  {page.img_dark && isDark && (
                    <Image
                      fluid
                      src={page.img_dark}
                      className="w-100"
                      style={{ maxHeight: '80vh', objectFit: 'cover' }}
                    />
                  )}
                </div>
              </Col>
              <Col md={6}>
                <h1>
                  <div
                    className={'text-center border-start'}
                    dangerouslySetInnerHTML={{
                      __html: page.text
                    }}
                  />
                </h1>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

ImageLeftTextRight.propTypes = {
  page: PropTypes.object.isRequired
};

ImageLeftTextRight.defaultTypes = {
  page: {}
};

export default ImageLeftTextRight;
