import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorLayout from '../../layouts/ErrorLayout';
import Error404 from '../../components/errors/Error404';
import Error500 from '../../components/errors/Error500';
import AuthSimpleLayout from '../../layouts/AuthSimpleLayout';
import SimpleLogin from '../../components/authentication/app/simple/Login';
import SimpleForgetPassword from '../../components/authentication/app/simple/ForgetPassword';
import SimplePasswordReset from '../../components/authentication/app/simple/PasswordReset';
import React, { useEffect, useState } from 'react';
import Registration from '../../components/authentication/app/simple/Registration';
import RegistrationMail from '../../components/authentication/app/simple/RegistrationMail';
import RegistrationWizard from '../../components/authentication/app/simple/RegistrationWizard';
import EmptyLayout from '../../layouts/EmptyLayout';
import AuthEmptyLayout from '../../layouts/AuthEmptyLayout';
import Launcher from '../../components/authentication/app/simple/Launcher';
import { DynamicPublicRoutes } from '../../modules';
import PropTypes from 'prop-types';
import MainLoader from '../../components/common/MainLoader';
import CreateOrganization from '../../modules/Organisation/components/auth/CreateOrganization';

const PublicRoutes = ({ onDynamicRoutesLoaded }) => {
  const [emptyDynamicPublicRoutes, setEmptyDynamicRoutes] = useState(false);
  const [dynamicRoutesLoaded, setDynamicRoutesLoaded] = useState(false);

  const fetchRoutes = async () => {
    const emptyDynamicRoutes = await DynamicPublicRoutes('empty');
    setEmptyDynamicRoutes(emptyDynamicRoutes);
    setTimeout(() => setDynamicRoutesLoaded(true), 2000);
    onDynamicRoutesLoaded(true);
  };
  useEffect(() => {
    fetchRoutes();
  }, []);

  return dynamicRoutesLoaded ? (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path={`errors/404`} exact element={<Error404 />} />
        <Route path={`errors/500`} element={<Error500 />} />
      </Route>

      <Route element={<AuthSimpleLayout />}>
        <Route
          path={`/auth/organization/create`}
          exact
          element={<CreateOrganization />}
        />
        <Route path={`/auth/launcher`} exact element={<Launcher />} />
        <Route path={`/auth/login`} element={<SimpleLogin />} />
        <Route path={`/auth/register`} element={<Registration />} />
        <Route
          path={`/auth/registration-mail`}
          element={<RegistrationMail />}
        />
        <Route
          path={`/auth/forgot-password`}
          exact
          element={<SimpleForgetPassword />}
        />
        <Route
          path={`/auth/reset-password/:token`}
          exact
          element={<SimplePasswordReset />}
        />
      </Route>

      {emptyDynamicPublicRoutes && emptyDynamicPublicRoutes.length > 0 && (
        <Route element={<EmptyLayout />}>{emptyDynamicPublicRoutes}</Route>
      )}

      <Route element={<AuthEmptyLayout />}>
        <Route
          path={`/auth/register/:token`}
          element={<RegistrationWizard />}
        />
      </Route>
      {/*
        <Route path="/activate/:activateRoute" element={<Activate />} />
*/}
      <Route path="*" element={<Navigate to="/auth/login" replace />} />
    </Routes>
  ) : (
    <MainLoader />
  );
};

PublicRoutes.propTypes = {
  onDynamicRoutesLoaded: PropTypes.func
};

PublicRoutes.defaultProps = {
  onDynamicRoutesLoaded: () => {}
};

export default PublicRoutes;
