import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { Trans, useTranslation } from 'react-i18next';

const RegistrationMailContent = ({ email, layout, titleTag: TitleTag }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (email) {
    return (
      <>
        <img
          className="d-block mx-auto mb-4"
          src={envelope}
          alt="sent"
          width={100}
        />
        <TitleTag>{t('auth:pleaseCheckYourEMail')}</TitleTag>
        <p>
          <Trans i18nKey={'auth:emailBeenSentToCreateAccount'}>
            <strong>{{ email }}</strong>
          </Trans>
        </p>
        <Alert variant={'info'}>{t('auth:checkSpamFolder')}</Alert>
        <Button
          as={Link}
          color="primary"
          size="sm"
          className="mt-3"
          to={`/auth/login`}
        >
          <FontAwesomeIcon
            icon="chevron-left"
            transform="shrink-4 down-1"
            className="me-1"
          />
          {t('auth:returnToLogin')}
        </Button>
      </>
    );
  } else {
    navigate('/');
    return null;
  }
};

RegistrationMailContent.propTypes = {
  email: PropTypes.string.isRequired,
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

RegistrationMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default RegistrationMailContent;
