import React, { useState } from 'react';
import { Button, CloseButton, Col, Form, Modal, Row } from 'react-bootstrap';
import i18n, { t } from 'i18next';
import Loader from '../../../../components/common/Loader';
import APIResponseViewer from '../../../../helpers/APIResponseViewer';
import axios from 'axios';
import PropTypes from 'prop-types';
import SelectCountry from '../../../../helpers/SelectCountry';
import { getCountry } from '../../../../data/countries';

const AddAddressModal = ({ show, setModalVisible, organizationId }) => {
  const hasLabel = true;
  const [loading, setLoading] = useState(false);
  let [isStandard, setIsStandard] = useState(false);
  let [street, setStreet] = useState(null);
  let [housenumber, setHousenumber] = useState(null);
  let [zip, setZip] = useState(null);
  let [city, setCity] = useState(null);
  let [country, setCountry] = useState(() => {
    let country = getCountry(i18n.language, 'code2');
    return { value: country.name, label: t(country.name) };
  });

  const updateRequest = () => {
    setLoading(true);
    let countryObj = getCountry(country.value, 'name');

    const data = {
      is_standard: isStandard,
      organization_id: organizationId,
      street,
      house_number: housenumber,
      zip,
      city,
      country: countryObj.code2
    };

    axios({
      url: `/ms/organization/address`,
      method: 'POST',
      data: data
    })
      .then(function (res) {
        setLoading(false);
        setModalVisible(!show);
        //onSuccess(true);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('Add address')}
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => setModalVisible(!show)}
        />
      </Modal.Header>
      <Modal.Body className="p-4">
        <Row className="mb-3">
          <Col xs={'auto'}>
            <Form.Check
              type="switch"
              id="is_standard"
              name="is_standard"
              label={t('is Standard?')}
              defaultChecked={isStandard}
              onChange={({ target }) => {
                setIsStandard(!isStandard);
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={8}>
            <Form.Group>
              {hasLabel && <Form.Label>{t('Street')}</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? t('Street') : ''}
                value={street}
                name="street"
                onChange={({ target }) => setStreet(target.value)}
                type="text"
                autoComplete={'street-address'}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group>
              {hasLabel && <Form.Label>{t('Housenumber')}</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? t('Housenumber') : ''}
                value={housenumber}
                name="housenumber"
                onChange={({ target }) => setHousenumber(target.value)}
                type="text"
                autoComplete={'street-address'}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={4}>
            <Form.Group>
              {hasLabel && <Form.Label>{t('ZIP')}</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? t('ZIP') : ''}
                value={zip}
                name="zip"
                onChange={({ target }) => setZip(target.value)}
                type="text"
                pattern={'[0-9]{5}'}
                inputMode={'numeric'}
                autoComplete={'postal-code'}
              />
            </Form.Group>
          </Col>
          <Col xs={8}>
            <Form.Group>
              {hasLabel && <Form.Label>{t('City')}</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? t('City') : ''}
                value={city}
                name="city"
                onChange={({ target }) => setCity(target.value)}
                type="text"
                autoComplete={'address-level2'}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
            <SelectCountry
              onSelected={row => setCountry(row)}
              active={country}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'falcon-default'}
          onClick={() => {
            setModalVisible(!show);
          }}
        >
          {t('close')}
        </Button>
        <Button
          variant={'falcon-success'}
          onClick={updateRequest}
          disabled={loading}
        >
          {t('add')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddAddressModal.propTypes = {
  show: PropTypes.bool,
  setModalVisible: PropTypes.func,
  organizationId: PropTypes.string
};

AddAddressModal.defaultProps = {
  show: false,
  setModalVisible: () => {}
};

export default AddAddressModal;
