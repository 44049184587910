import React from 'react';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/app/LoginForm';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Login = () => {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('capitalize', { value: t('auth:login') })} | kesify</title>
        <meta name="description" content="" />
      </Helmet>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>{t('common:capitalize', { value: t('auth:login') })}</h5>
        <p className="fs--1 text-600 mb-0">
          {t('common:or')}{' '}
          <Link to="/auth/register">{t('auth:createAccount')}</Link>
        </p>
      </Flex>
      <LoginForm hasLabel={true} />
    </HelmetProvider>
  );
};

export default Login;
