import React from 'react';
import Sessions from './components/Sessions';
import Session from './components/Session';

export default {
  name: 'Sessions',
  label: 'Sessions',
  labelDisable: true,
  children: [
    {
      name: 'Sessions',
      active: true,
      icon: 'lock',
      to: '/sessions'
    }
  ],
  routes: [
    {
      path: '/sessions',
      name: 'Organizations',
      component: <Sessions />,
      layout: 'main'
    },
    {
      path: '/session/:id',
      name: 'Session Details',
      component: <Session />,
      layout: 'main'
    }
  ]
};
