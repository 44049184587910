import PropTypes from 'prop-types';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isIterableArray } from './utils';
import { timezones } from '../data/timezones';

const SelectTimezone = ({ active, onSelected }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState({ value: active });

  const optionsFill = () => {
    const options = isIterableArray(timezones)
      ? timezones.map(timezone => ({
          value: timezone.name,
          label: t(timezone.name)
        }))
      : [];
    return options;
  };

  const options = optionsFill();

  useEffect(() => {
    const initialValue = options.find(option => option.value === active);
    if (initialValue) {
      setValue(initialValue);
    }
  }, [active]);

  const handleSelect = value => {
    setValue(value);
    onSelected(value);
  };

  return (
    <Select
      closeMenuOnSelect={true}
      options={options}
      placeholder={t('Timezone')}
      classNamePrefix="react-select"
      value={value}
      onChange={handleSelect}
    />
  );
};

SelectTimezone.propTypes = {
  onSelected: PropTypes.func,
  active: PropTypes.string
};

SelectTimezone.defaultProps = {
  onSelected: () => {}
};

export default SelectTimezone;
