import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CurrencyInput = props => {
  const { value, onChange, label, hasLabel } = props;
  const { t, i18n } = useTranslation();
  const [amount, setAmount] = useState(() =>
    value ? t('intlPriceWithoutSign', { val: value }) : ''
  );

  function handleChange(e) {
    setAmount(e.target.value);
  }

  function handleBlur(e) {
    const simpleNumber = formatSimpleNumber(amount ?? 0);

    const formatted = t('intlPriceWithoutSign', {
      val: amount ? simpleNumber : 0
    });

    onChange(formatted, amount, simpleNumber);
    setAmount(formatted);
  }

  function formatSimpleNumber(str) {
    const onlyDigitsAndDot = str.replace(/[^0-9.]/g, '');
    const onlyDigitsAndComma = str.replace(/[^0-9,]/g, '');

    let formattedNumber = '';

    if (onlyDigitsAndDot.length > onlyDigitsAndComma.length) {
      formattedNumber = onlyDigitsAndDot.replace(/,/g, '');
    } else {
      formattedNumber = onlyDigitsAndComma.replace(/,/g, '.');
    }

    const [integerPart, decimalPart] = formattedNumber.split('.');
    const numberOfIntegerDigits = integerPart.length;
    const numberOfDecimalDigits = decimalPart ? decimalPart.length : 0;

    return {
      number: parseFloat(formattedNumber),
      numberOfIntegerDigits,
      numberOfDecimalDigits
    };
  }

  return (
    <Form.Group>
      {hasLabel && <Form.Label>{t(label)}</Form.Label>}
      <Form.Control
        placeholder={!hasLabel ? t('Amount') : ''}
        value={amount}
        name={props.name ?? 'amount'}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
      />
    </Form.Group>
  );
};

CurrencyInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  hasLabel: PropTypes.bool
};

CurrencyInput.defaultProps = {
  label: 'Amount',
  onChange: () => {},
  hasLabel: false
};

export default CurrencyInput;
