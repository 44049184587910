const initialState = {
  isAuthenticated: false,
  tokenType: 'Bearer',
  token: null,
  email: null,
  user: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setLogin':
      return {
        ...action.payload
      };
    case 'setLogout':
      return {
        ...initialState
      };
    case 'setAuthenticated':
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case 'getAuth':
      return state;
    case 'setToken':
      return {
        ...state,
        token: action.payload
      };
    case 'setTokenType':
      return {
        ...state,
        tokenType: action.payload
      };
    case 'setEmail':
      return {
        ...state,
        email: action.payload
      };
    case 'setUser':
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
