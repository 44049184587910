import { combineReducers } from 'redux';
import authReducer from './authReducer';
import registrationReducer from './registrationReducer';
import settingsReducer from './settingsReducer';
import requestsReducer from './requestsReducer';
import spinnerReducer from './spinnerReducer';
import organizationReducer from '../../modules/OB_Organizations/redux/reducer/organizationReducer';

const allReducers = combineReducers({
  auth: authReducer,
  registration: registrationReducer,
  settings: settingsReducer,
  requests: requestsReducer,
  organization: organizationReducer,
  spinner: spinnerReducer
});

export default allReducers;
