import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  Button,
  Col,
  Modal,
  Row,
  CloseButton,
  InputGroup
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import avatarImg from '../../../assets/img/team/avatar.png';
import { isIterableArray } from '../../../helpers/utils';
import AppContext from '../../../context/Context';
import SelectCountry from '../../../helpers/SelectCountry';

const ModalRoleForm = ({ isOpen, onClose, onCreated, hasLabel }) => {
  const { t } = useTranslation();

  let [modal, setModal] = useState(false);
  let [role, setRole] = useState('');
  let [isDisabled, setIsDisabled] = useState(true);
  const {
    config: { isDark }
  } = useContext(AppContext);

  useEffect(() => {
    setModal(isOpen);
    setIsDisabled(!role);
  }, [role, isOpen]);

  const onCreatedHandle = value => onCreated(value);

  const create = () => {
    axios({
      url: '/acl/org/role',
      method: 'POST',
      data: {
        name: role
      }
    }).then(function (res) {
      if (res.data.response) {
        onCreatedHandle(true);
        handleClose(false);
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    create();
  };

  const handleClose = () => {
    setModal(false);
    onClose(false);
  };

  return (
    <Modal show={modal} onHide={handleClose} backdrop="static" centered={true}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('Create role')}
          </Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col xs={12}>
              <Form.Group>
                {hasLabel && <Form.Label>{t('Role')}</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? t('Role') : ''}
                  value={role}
                  name="role"
                  onChange={({ target }) => setRole(target.value)}
                  type="text"
                  autoComplete={'given-name'}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'falcon-default'} onClick={handleClose}>
            {t('close')}
          </Button>
          <Button
            type="submit"
            variant={'falcon-success'}
            disabled={isDisabled}
          >
            {t('create')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ModalRoleForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  hasLabel: PropTypes.bool
};

ModalRoleForm.defaultProps = {
  hasLabel: false,
  onClose: () => {},
  onCreated: () => {}
};

export default ModalRoleForm;
