import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Suspense fallback={<span>loading</span>}>
      <Provider fallback={<span>loading provider</span>} store={store}>
        <PersistGate
          loading={<span>loading persist</span>}
          persistor={persistor}
        >
          <I18nextProvider i18n={i18n}>
            <Main>
              <App />
            </Main>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  </React.StrictMode>
);
